import React, { useState,useEffect, useRef } from "react";

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner} from "reactstrap"
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import {getPropertyInfoService} from "../machines/listing-machine/services"
import {PropertyType,SaleType} from '../contracts/enums'
import {Property} from '../contracts/response-models/property'

// import PropertyCarousel from "../components/PropertyPreview/PropertyCarousel"
import { Carousel } from 'react-responsive-carousel';
import "../assets/scss/carousel.css"
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import {PropertyDetailsSFHS} from "../components/PropertyPreview/PropertyDetail-SFHS"
import {PropertyDetailsAPT} from "../components/PropertyPreview/PropertyDetail-APT"
import {PropertyDetailsSFHR} from "../components/PropertyPreview/PropertyDetail-SFHR"
import {PropertyDetailsMFHS} from "../components/PropertyPreview/PropertyDetail-MFHS"
import {PropertyDetailsCOM} from "../components/PropertyPreview/PropertyDetail-COM"
import {PropertyDetailsLND} from "../components/PropertyPreview/PropertyDetail-LND"
import {PropertyPriceInfo} from "../components/PropertyPreview/PropertyPriceInfo"
import "../components/PropertyPreview/css/custom-component.css"

import { Aminity } from "../contracts/response-models/aminity";

import {
    Star,
    Truck,
    DollarSign,
    ShoppingCart,
    Heart,
    Facebook,
    Twitter,
    Youtube,
    Instagram,
    Award,
    Clock,
    Shield
  } from "react-feather"
import classnames from "classnames"
import { displayTitle } from "../utils/display-property-title";
import { getZagNumber } from "../utils/zag-number";
import { displayCurrency } from "../utils/display-currency";

interface slideType{
 // id:number;
 // src:String;
 thumbnail:string;
 original:string;
}

export const PropertyPreview = () => {
  const [loading,setLoading] = useState(false);
  
    const [property, setProperty] = useState<Property>();
    let { id } = useParams();

    const [slides,setSlides] = useState<slideType[]>([]);
   
    useEffect(() => {    
        async function getProperty() {
          setLoading(true);
            if(id){
                const propertyinfo = await getPropertyInfoService(id);  
                setProperty(propertyinfo);   
                buildPhotos(propertyinfo?.imageNames);
            }
          setLoading(false); 
        }
        getProperty();
    },[id]);


    const buildPhotos=(imageNames?:String[])=>{

      const images = new Array; 
      imageNames?.forEach((element,index) => { 
      //  temp.push({id:index+1,src:"https://cdn.zaglist.com/propertyimages/m/"+element});  
      images.push({ thumbnail: "https://cdn.zaglist.com/propertyimages/ms/"+element, original: "https://cdn.zaglist.com/propertyimages/m/"+element });
    
      });  

      setSlides(images);
    }

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Preview"  breadCrumbParentLink="/listings" breadCrumbParent="My Listings" breadCrumbActive="Preview" />
           
            <Card className="overflow-hidden app-ecommerce-details">
          <CardBody className="pb-0">
            {loading? (
              <div className="d-flex justify-content-between mt-1">                               
              <Spinner size="lg"  className="primary float-right"/>
            </div>
            ):(
              <>
            <Row className="mb-2 mt-2"> 
            <Col className="mt-1 pl-0 pl-1 align-middle" sm="6" md="6"  >
              {property?.ownershipType!=undefined ?(<>
                {property?.propertyType!=undefined && property?.saleType!=undefined && (
            <h3> {displayTitle(property.propertyType,property.saleType,false,property.ownershipType)}</h3>
            ) }</>):(
<>
{property?.propertyType!=undefined && property?.saleType!=undefined && (
  <h3> {displayTitle(property.propertyType,property.saleType,false,'')}</h3>
  ) }
</>

              )}
              


             </Col>
             <Col className="mt-1 pl-0 text-right" sm="6" md="6" >

                    <Button className="mr-1" color="primary" outline>
                      <Link to={`/listings/add/${property?.id}`}>
                        <Edit size={15} />
                        <span className="align-middle ml-50">Edit Listing</span>
                      </Link>
                    </Button>                  
                  </Col>
                  </Row>
                  <Row className="mb-2 mt-2"> 
              <Col
                className="justify-content-center carousel_container"
                sm="12"
                md="5"  
               
              >

          <ImageGallery items={slides} /> 
                                
              </Col>
              <Col lg="7" md="7" sm="12" className="property_item">
  
               
                <div className="d-flex flex-wrap">
                       
                </div>
                <hr />
                <Row  >
                  
                  <Col lg="6" md="6" sm="6"> &nbsp;
                    </Col>
                      <Col lg="6" md="12" sm="6"  >
                      <Row className="" > 
                      <Col lg="6" md="6" sm="6" className="w-50 odd mob_odd text-right pitem-rspace">
                      ZAG# :
                      </Col>
                      <Col lg="6" md="6" sm="6"  className="w-50 odd mob_odd font-weight-bold pitem-lspace" >
                      {getZagNumber(property?.id)}
                      </Col>  </Row>
                      </Col>
                    </Row>

                <Row className="mb-4  ">
                  
              <Col lg="6" md="12" sm="6">
              <Row className="mt-0 property_item mobile_pad_5 ">
              
              <Col lg="4" md="6" sm="6" className="w-50 text-right mob_even even pitem-rspace">
              Street  :
                  </Col>
                  <Col lg="8" md="6" sm="6"  className="w-50 font-weight-bold mob_even even pitem-lspace" >
                  {property?.street}
                  </Col> 
              
              <Col lg="4" md="6" sm="6" className="w-50 mob_odd text-right odd pitem-rspace">
              Town  : 	
                  </Col>
                  <Col lg="8" md="6" sm="6"  className="w-50 mob_odd font-weight-bold odd pitem-lspace" >
                  {property?.areaTitle},  {property?.stateCD}   {property?.zipCode}
                  </Col> 
                  { property?.propertyType==PropertyType.APT && property?.ownershipType=='c'   &&
              ( 
                  <>
                    <Col lg="4" md="6" sm="6" className="mob_hide text-right even pitem-rspace">
              &nbsp;
                  </Col>
                  <Col lg="8" md="6" sm="6"  className="mob_hide font-weight-bold even pitem-lspace" >
                
                  </Col>
                </>
                  )}

{property?.saleType==SaleType.ForRent && 
                        (
                          <>
                          <Col lg="4" md="6" sm="6" className="mob_hide text-right even pitem-rspace">
                    &nbsp;
                        </Col>
                        <Col lg="8" md="6" sm="6"  className="mob_hide font-weight-bold even pitem-lspace" >
                      
                        </Col>
                        <Col lg="4" md="6" sm="6" className="mob_hide text-right odd pitem-rspace">
                    &nbsp;
                        </Col>
                        <Col lg="8" md="6" sm="6"  className="mob_hide font-weight-bold odd pitem-lspace" >
                      
                        </Col>
                        <Col lg="4" md="6" sm="6" className="mob_hide text-right even pitem-rspace">
                    &nbsp;
                        </Col>
                        <Col lg="8" md="6" sm="6"  className="mob_hide font-weight-bold even pitem-lspace" >
                      
                        </Col>
                      </>
                        )} 
 
                  </Row>

                  
                    
                  </Col>
                  <Col lg="6" md="12" sm="6"> 
                  {property && (
                    <PropertyPriceInfo property={property} setProperty={setProperty}/>
                  ) }
                  

                  </Col>

       
                  </Row>


                <hr />
                <h5>PROPERTY DETAILS</h5>
                <hr />

                {property?.propertyType==PropertyType.APT &&
              (
                <PropertyDetailsAPT property={property} setProperty={setProperty}/>
              )}                     

              {(property?.propertyType==PropertyType.SFH || property?.propertyType==PropertyType.TWH ) &&
              (
                <>
                {property.saleType==SaleType.ForSale && 
                  (
                    <PropertyDetailsSFHS property={property} setProperty={setProperty}/>
                  )
                }
                {property.saleType==SaleType.ForRent && 
                  (
                    <PropertyDetailsSFHR property={property} setProperty={setProperty}/>
                  )
                }
                </>
              )}   

               {property?.propertyType==PropertyType.MFH &&
              (
                    <PropertyDetailsMFHS property={property} setProperty={setProperty}/>
                 
              )}    
               {property?.propertyType==PropertyType.COM &&
              (
                <PropertyDetailsCOM property={property} setProperty={setProperty}/>
              )} 
              {property?.propertyType==PropertyType.LND &&
              (
                <>
                <PropertyDetailsLND property={property} setProperty={setProperty}/>
                 
                </>
              )}     
               

          
              </Col>
            </Row>
            {property?.units !=undefined && property?.units>0 && (
            <Row className="unitContainer">
              
            <Col sm="12" className="unitContainer m-50">
                <h5>UNITS</h5>
            <hr />

            <Row className="odd">
            <Col md="1" sm="6" xs="1">
            <strong> Unit #</strong>
              </Col>
            <Col md="2" sm="6" xs="2"><strong>Bedrooms</strong>
          </Col> 
            <Col md="2" sm="6" xs="2"><strong>Full Baths</strong>
                </Col>
            <Col md="2" sm="6" xs="2">
            <strong> Half Baths</strong>
              </Col>
            <Col md="3" sm="6" xs="3">
            <strong> Conditions</strong>
              </Col>
            <Col md="2" sm="6" xs="2">
            <strong> Rent</strong>
              </Col>

         </Row>
            </Col>
            </Row>
            )}
            <div className="unitContainer">
            {
              property?.unitsDetail!=undefined && (
            property.unitsDetail?.map(function (unitInfo, i) {
 
               if(i%2==0)
               {
                  var row="even";
               }else
               {
                  var row="odd";
               }
               return (
                  <>
                     <Row  className={row}  > 
                     <Col md="1" sm="6" xs="1">{i+1}
          </Col>
                        <Col md="2" sm="6" xs="2">{unitInfo.noOfBedrooms}
          </Col>

                        <Col md="2" sm="6" xs="2">{unitInfo.noOfFullBaths}
                        </Col>
                        <Col md="2" sm="6" xs="2">{unitInfo.noOfHalfBaths}

                        </Col>
                        <Col md="3" sm="6" xs="3">{unitInfo.condition} 
                        </Col>
                        <Col md="2" sm="6" xs="2">
                          {unitInfo.isVacant ?("Vacant"):(
                       <> ${displayCurrency(unitInfo.rent)}</>
                        )}
                        </Col>

                     </Row>
                  </>

               )
            })
            )}
            </div>
          <Row>
            <Col sm="12" className="m-50">
                <h5>PROPERTY DESCRIPTION</h5>
            <hr />
                <p>
                 {property?.propertyDescription}
                </p>
            </Col>
          </Row>
          {property?.saleType==SaleType.ForRent && property?.propertyType==PropertyType.COM &&
              ( 
               
                 <Row>
            <Col sm="12" className="m-50">
                <h5>Rent Includes</h5>
            <hr />
            <Row> 
                {                    
               property?.rentincludes?.map(function(rentInclude, i){
                   return (
                    <Col md="4" sm="6"  >
                   {rentInclude.title} 
                   </Col>
                   )
               })
            }
                  </Row>

                </Col>
                </Row>
               
              )} 
            {property?.propertyType!=PropertyType.LND &&
              ( 
               
                 <Row>
            <Col sm="12" className="m-50">
                <h5>{property?.propertyType==PropertyType.MFH || property?.propertyType==PropertyType.COM ?("UTILITIES"):("AMENITIES")}</h5>
            <hr />
            <Row> 
                {                    
               property?.aminities?.map(function(amenity, i){
                   return (
                    <Col md="4" sm="6"  >
                   {amenity.title} 
                   </Col>
                   )
               })
            }
                  </Row>

                </Col>
                </Row>
               
              )} 
            
          
          
            {property?.propertyType==PropertyType.LND &&
              ( <>
                <Row>
                <Col sm="12" className="m-50">
                    <h5>IMPROVEMENTS</h5>
                <hr />
                <Row>

                  {
               property?.improvements?.map(function(improvement, i){
                   return (
                    <Col md="4" sm="6"  >
                    {improvement.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
                    <Row>
                <Col sm="12" className="m-50">
                    <h5>WATERFRONT</h5>
                <hr />
                <Row>

                  {
               property?.waterfronts?.map(function(waterfront, i){
                   return (
                    <Col md="4" sm="6"  >
                    {waterfront.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
                    <Row>
                    <Col sm="12" className="m-50">
                    <h5>CURRENT ZONING & PERMITTED USE</h5>
                <hr />
                <Row>

                  {
               property?.zones?.map(function(zone, i){
                   return (
                    <Col md="4" sm="6"  >
                    {zone.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
            </>
              )} 
      </>
     )}
          </CardBody>
      
        </Card>
        </React.Fragment>
    )
}
export default PropertyPreview;