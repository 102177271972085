import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Spinner
} from "reactstrap"
 
  
import { PaymentInfo } from "../../contracts/request-models/payment-info";
import { ValidationMessage } from "../@vuexy/validation-message";

interface paymentProps {
    payInfo: PaymentInfo;
    setPayInfo:any;
}


export const BillingInfo = ({ payInfo,setPayInfo }: paymentProps) => { 
  return (
    <>
     <FormGroup><h4>Billing Information</h4></FormGroup>
        
        <FormGroup>
          <Label for="billingName"><strong>Name on the Card</strong>  </Label> 
          
          <Input 
                      type="text"
                      id="billingName"
                      placeholder="Name on the Card"
                      value={payInfo.name}
                      onChange={(e)=> {
                        setPayInfo({ ...payInfo, name: e.target.value })
                      }}
                    />
                
                  <ValidationMessage isInvalid={(payInfo.name == undefined)  || (payInfo.name == '') ? true : false} errorMessage="Please enter Name on the card" />
            


        </FormGroup>
        <FormGroup>
          <Label for="billingAddress"><strong>Billing Address</strong></Label>        
              <Input
                      type="text"
                      id="billingAddress"
                      placeholder="Billing Address"
                      value={payInfo.address}
                      onChange={(e)=> {
                        setPayInfo({ ...payInfo, address: e.target.value })
                      }}
                    />
                  <ValidationMessage isInvalid={(payInfo.address == undefined)  || (payInfo.address == '') ? true : false} errorMessage="Please enter Billing Address" />
            


        </FormGroup>
        <FormGroup style={{width:'50%',float:'left'}}>
          <Label for="billingCity"><strong>City </strong> </Label>         <Input
                   
                      type="text"
                      id="billingCity"
                      placeholder="Billing City"
                      value={payInfo.city}
                      onChange={(e)=> {
                        setPayInfo({ ...payInfo, city: e.target.value })
                      }}
                    />
                   
                  <ValidationMessage isInvalid={(payInfo.city == undefined) || (payInfo.city == '') ? true : false} errorMessage="Please enter Billing City" />
            



        </FormGroup>
      
        <FormGroup style={{width:'45%',float:'left'}}>
          <Label for="billingCountry"><strong>Country</strong> </Label>     
          <Input
                   style={{marginLeft:'5px'}} 
                   type="text"
                   id="billingCity"
                   placeholder="Billing City"
                   value="US"
                   readOnly
                 />
        </FormGroup>
        <FormGroup style={{clear:'both'}}></FormGroup>
     
      
    </>
  )
}
