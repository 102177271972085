import React, { useEffect,useState } from "react";
import {
    Label,
    Row,
    Card,
    CardBody,
    Col,   
    Input,  
    Spinner
  } from "reactstrap"


import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'
import { flatten } from "xstate/lib/utils";
import { Redirect } from "react-router-dom";
import RLDD from "react-list-drag-and-drop/lib/RLDD";

interface PropertyPhotoStepProps {
  property:Property,
  setProperty:any;  
  IsPropertySaved:boolean;  
  loading?:boolean;
  NewSavedPropertyId:number;
}

interface fileUpload{
  file:any;
  url?:string;
  uploaded?:boolean;
 
}
interface imageInfo {
  id:number;
  fileName:string;
  fullUrl:string;
}

export const PhotoStep = ({ property, setProperty,IsPropertySaved,loading,NewSavedPropertyId}:PropertyPhotoStepProps) => {   
  
  const [isUploading,setIsUploading] = useState(false);
  const [imageUrlInfo,setImageUrlInfo]= useState<imageInfo[]>();
 
  useEffect(() => {    
    async function setimageUrl() {
      var imgArr = new Array<imageInfo>()
      property.imageNames?.map((imageInfo, index) => {
        var itemid = index+1;
        imgArr?.push({
          id:itemid,
          fileName: imageInfo.toString(),
          fullUrl: "https://cdn.zaglist.com/propertyimages/m/"+imageInfo.toString(), 
      });
      })
      if(imageUrlInfo?.length==0 || imageUrlInfo?.length == undefined)
      {
        setImageUrlInfo(imgArr);
      }
     

    }
    setimageUrl();
},[property.imageNames]);
//console.log("Image Length");
 //console.log(imageUrlInfo?.length);
  const handlePhotoUploadChange = (e:any) =>{
    
    setIsUploading(true);
    const filesUpload = e.target.files;  
    const formData = new FormData()
    for(var i=0;i<filesUpload.length;i++)
    {
      formData.append('files', filesUpload[i])
    }
    uploadFiles(formData);
  }

  //TODO: Move this function in API
  const uploadFiles=(formData:any)=>{
    fetch('https://api.zaglist.com/api/upload', {  
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
     
      var filearray = [];
      var imageinfoarray = [];
      for(var i=0;i<data.length;i++)
      {
        filearray.push(data[i].fileName);
        if(imageUrlInfo!=undefined)
        {
          var itemid = imageUrlInfo?.length+i+1;
        }else
        {
          var itemid = i+1;
        }
       
        imageinfoarray.push({'id':itemid,'fileName':data[i].fileName,'fullUrl':data[i].fullUrl});
      }     

      var emptyArr = new Array<string>()
      var arrPhotos = property.imageNames == undefined ? emptyArr : property.imageNames;
      arrPhotos = arrPhotos.concat(filearray);

      var emptyimageInfo = new Array<imageInfo>()
      var imagesInfo = imageUrlInfo == undefined ? emptyimageInfo : imageUrlInfo;
      imagesInfo = imagesInfo.concat(imageinfoarray);
      setImageUrlInfo(imagesInfo);
      setProperty({...property,imageNames:arrPhotos});   
      //setProperty({...property,imageNames:property.imageNames?.concat(filearray)});   
      setIsUploading(false); 
    })
    .catch(error => {
      console.error(error)
    }) 
  }
  
  const handlePhotoDeleteOld =(e:any,index:number) =>{
    e.preventDefault();
   // const index = property.imageNames?.indexOf(e.target.value);
    let images = property.imageNames;
    if (index!=undefined && index > -1) {  
      images?.splice(index, 1);      
    }
    setProperty({...property,imageNames: images}); 
    
    const imageinfoTemp = imageUrlInfo; 
    if (index!=undefined && index > -1) {  
        imageinfoTemp?.splice(index, 1);
    }  
    setImageUrlInfo(imageinfoTemp);  
    
  }
  const handlePhotoDelete =(e:any,id:number) =>{
    e.preventDefault();
   // const index = property.imageNames?.indexOf(e.target.value);
    let imagesInfo = imageUrlInfo;
    let updateimagesInfo = new Array;

    imagesInfo?.map((object, i) => {
      if(object.id!=id)
      { 
        updateimagesInfo?.push({
          id: object.id,
          fileName: object.fileName.toString(),
          fullUrl: object.fullUrl
      });
      }
    }) 
    setImageUrlInfo(updateimagesInfo);  
    let images = new Array;
    updateimagesInfo?.map((object, i) => { 
      images?.push(object.fileName); 
    }) 
    setProperty({...property,imageNames: images}); 
    
  }
    if(property.id!=undefined)
    {
      if(IsPropertySaved)
        return (<Redirect to="/listings" />);
    }else
    {
      if(NewSavedPropertyId!=undefined)
      {
        if(IsPropertySaved)
        return (<Redirect to={`/listings/publish/${NewSavedPropertyId}`} />);
      }
      
    }
    const handleDragDropChange =(sortedList:any) =>{ 
      setImageUrlInfo(sortedList);
      let images = new Array; 
      sortedList?.map((object:any, i:number) => { 
        images?.push(object.fileName); 
      }) 
      setProperty({...property,imageNames: images}); 
  }

    return (  
        
        <Row>
        <Col md="12" sm="12">
        {loading ? (   
            <h3 className="mb-2 mt-2 text-center">Saving Property...  </h3>
          
        ) : (      <> 
    <h3 className="mb-2 mt-2 text-center">PHOTOS</h3>
    <div className="d-inline mr-1" ><i>Note: Max 50 photographs. To change the order, click on the image and drag it to a desired position.</i></div> 
          <div className="form-label-group mt-2"> 
            <Input
              multiple
              type="file"
              className={classnames("React",{
                invalid: (property.imageNames == undefined || property.imageNames.length==0)
              })}
              name="propertyphoto[]"
              id="propertyphoto"             
              placeholder="Upload Photos"
              onChange={handlePhotoUploadChange}
            />    
            {isUploading && ("Uploading...")}  
         
          </div>
         
          <div className="row" id="images_container">
              {imageUrlInfo!=undefined && (
          <RLDD
  items={imageUrlInfo}
  layout="horizontal"
  itemRenderer={(item:imageInfo,index) => {
    return ( 
      <Col md="3" sm="3" lg="3" style={{cursor:'move'}} >
                <Card>
                           
                <CardBody className="photo-edit-container"> 
                <div className="PropertyDeleteIcon" onClick={(e)=> { handlePhotoDelete(e,item.id)}} >x</div>  
                <img className="img-responsive" height="100"  src={item.fullUrl.toString()}/>   
                </CardBody>
                </Card>
              </Col>
    );
  }}
  onChange={handleDragDropChange}

  
/>
)}
 
          </div>
          </>
          )}   
        </Col>
      </Row>   
    )
}