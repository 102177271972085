import React,{useState,useEffect} from "react";
 
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import {Property} from '../../../contracts/response-models/property'
import { PropertyHeadInfo } from "./../property-head-info-modal";

 
import { ToastContainer, toast } from "react-toastify";

import { adminFeaturedListingService, getUserInfoService } from '../../../machines/listing-machine/services'
import Select from "react-select"
 
import { User } from "../../../contracts/response-models/user";
 
import { ValidationMessage } from "../../@vuexy/validation-message";
import { PaymentInfo } from "../../../contracts/request-models/payment-info";
 
 

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
  setReadyState:any;
}


export const AdminFeaturedListingForm = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
 

  const [user, setUser] = useState<User>({ userId: 0 }); 
  const [buttonText, setButtonText] = useState('Save');  
  const[paymentError,setPaymentError]=useState('');
  const[selectedDays,setSelectedDays]=useState('');
  const [paymentloading, setPaymentLoading] = useState(false);
  const [payInfo, setPayInfo] = useState<PaymentInfo>({
    name:"",
    address:"",
    city:"",
   
  });
  const dayOptions = [
    { index: 0, value: "30", label: "30 Days" },
    { index: 1, value: "60", label: "60 Days" },
    { index: 2, value: "90", label: "90 Days" },   
  ]

 
  
  const [loading, setLoading] = useState(false);
  const [featuredListing, setFeaturedListing] = useState(
    { endDate:''}
  );

  useEffect(() => {
    async function setUserInfo() {
      setLoading(true);      
      const userinfo = await getUserInfoService();
      setUser(userinfo);
      setLoading(false);
    
    }

    setUserInfo();
  }, [user.userName]);

 
  
  
  const handleExpirationDayChange = async (val: any) => {
    setLoading(true);  
    let days = val.value;
    setSelectedDays(days);
   
   setLoading(false);
  }

  const saveFeaturedListing = async (e: any) => {
    e.preventDefault()
    if(paymentloading)
      return;   
     
     var isValidated = true;
     if(selectedDays=="" || selectedDays==undefined)
     {
      setSelectedDays('');
      isValidated = false;
      return;
     }
     
        if(isValidated==true && property?.id!=undefined && selectedDays!='')
        {
            setPaymentLoading(true); 
            setLoading(true);

            const addFeaturedResponse = adminFeaturedListingService(property?.id,parseInt(selectedDays));

            toast.success("Listing set to featured successfully", {
            position: toast.POSITION.TOP_RIGHT
            })

            setLoading(false);
            toggle();
            setReadyState(true); 
        } 
        
  }
     

    
        return (     
         
            <>         

          
                <PropertyHeadInfo property={property}    />

            
 
              <FormGroup>
                <Label for="address">Days</Label>
                
                    <Select
                      className="React text-center"
                      classNamePrefix="select"                      
                      name="title"
                      onChange={handleExpirationDayChange}
                      options={dayOptions}
                    />
                    <ValidationMessage isInvalid={(selectedDays == undefined) || (selectedDays != '') ? false : true} errorMessage="Please select days for featured listing" />


              </FormGroup>
              {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
          
        
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveFeaturedListing}                        
                    >
                      {buttonText}
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>

        )}
                     
         </>
    )
}
    