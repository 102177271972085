import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsMFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
            <Row className="odd"> 
            <Col lg="6" md="12" sm="6">
               <Row  >
                  <Col lg="6" md="6" sm="6" className="w-50 mob_odd  text-right">
                  Parking Spaces  :
              </Col>
                  <Col lg="6" md="6" sm="6" className="w-50 mob_odd font-weight-bold" >
                     {property?.parkingSpaces}
                  </Col>
               </Row>  </Col>
            <Col lg="6" md="12" sm="6">
               <Row  >
                  <Col lg="6" md="6" sm="6" className="w-50 mob_even  text-right">
                    Heating Source :
              </Col>
                  <Col lg="6" md="6" sm="6" className="w-50 mob_even font-weight-bold" >
                     {property?.heatingSource} 
                  </Col>
               </Row>
            </Col>

         </Row>
         <Row className="even">

            
            <Col lg="6" md="12" sm="6">
               <Row  >
                  <Col lg="6" md="6" sm="6" className="w-50 mob_odd  text-right">
                  Building Age  :    
              </Col>
                  <Col lg="6" md="6" sm="6" className="w-50 mob_odd font-weight-bold" >
                     {property?.yearBuilt}
                  </Col>
               </Row>  </Col>
            <Col lg="6" md="12" sm="6">
               <Row  >
                  <Col lg="6" md="6" sm="6" className="w-50 mob_even  text-right">
                  Cooling Type : 
              </Col>
                  <Col lg="6" md="6" sm="6" className="w-50 mob_even font-weight-bold" >
                     {property?.coolingType}
                  </Col>
               </Row>
            </Col>

         </Row>
 
         </>
    )
}
    