import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Col,
    Input,
    CustomInput,
    Form,
    Button,
    Spinner
} from "reactstrap"
import Select from "react-select"

import { ValidationMessage } from "../components/@vuexy/validation-message"
import { sendSupportMessage } from '../machines/listing-machine/services'

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
import { ActionResponse } from "../contracts/response-models/action-response";

export interface SupportInfo {
    subject?: string;
    message?: string;
}
var dataOptions = [
    { value: undefined, label: "Select One" },
    { value: "Questions about listings", label: "Questions about listings" },
    { value: "Questions about featured listings", label: "Questions about featured listings" },
    { value: "Questions about featured profile", label: "Questions about featured profile" },
    { value: "Questions about payment", label: "Questions about payment" },
    { value: "Report an error", label: "Report an error" },
    { value: "Other", label: "Other" },
]

export const Support = () => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState<ActionResponse>();
    const [supportinfo, setSupportInfo] = useState<SupportInfo>({
        subject: '',
        message: ''
    });

    const [validationMessage, setValidationMessage] = useState<string>();

    const handleSubjectChange = (option: any) => {
        setSupportInfo({ ...supportinfo, subject: option.value });
    }
    const handleMessageChange = (e: any) => {
        setSupportInfo({ ...supportinfo, message: e.target.value });
    }

     
    const submitSupport = async (e: any) => {
        e.preventDefault()
        var isValidated = true;
        if (supportinfo?.subject == undefined || supportinfo.subject == "") {
            setSupportInfo({ ...supportinfo, subject: undefined });
            isValidated = false
        }
        if (supportinfo?.message == undefined || supportinfo.message == "") {
            setSupportInfo({ ...supportinfo, message: undefined });
            isValidated = false
        }

        if (isValidated == true && supportinfo.subject!=undefined && supportinfo.message!=undefined) {
            setLoading(true);
            const response = await sendSupportMessage(supportinfo.subject, supportinfo.message);
            setResponseMessage(response);
            if(response?.success==true)
            {
                /*
                toast.success("Support message submitted successfully, we will contact you within 24 hours", {
                    position: toast.POSITION.TOP_RIGHT
                }) */

                setSupportInfo({ ...supportinfo, 
                    subject: undefined,
                    message: undefined });
                    setLoading(false);
                  //  window.location.href="/";
            }
            
            setLoading(false);
           
        }


    };

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Support" breadCrumbActive="Support" />
            <Card>
                <CardBody>
                    <ToastContainer />

                    {!loading ? (
                        <Row className="mt-1">
                            {responseMessage?.success==true ?(<>
                                <Col className="mx-auto" md="9" sm="12">
                                      <div className="alert alert-success">Support message submitted successfully, we will contact you within 24 hours</div>
                                   </Col>
                                    </>):(
                                        <>
                            <Col className="mx-auto" md="7" sm="12">
                                <h3 className="mb-2 text-left">Contact Support</h3>
                                <Form>
                                    
                                    <FormGroup row>
                                        {responseMessage?.error!="" &&(
                                            <>{responseMessage?.error}</>
)}
                                        <Col md="3">
                                            <span>Subject</span>
                                        </Col>
                                        <Col md="9">
                                            <Select

                                                classNamePrefix="select"

                                                options={dataOptions}
                                                onChange={handleSubjectChange}
                                            />

                                            <ValidationMessage isInvalid={(supportinfo?.subject == undefined) ? true : false} errorMessage="Please select your subject" />

                                        </Col>

                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md="3">
                                            <span>Message</span>
                                        </Col>
                                        <Col md="9">
                                            <Input
                                                type="textarea"
                                                name="text"
                                                id="messageText"
                                                rows="3"
                                                value={supportinfo.message}
                                                placeholder="Description"
                                                onChange={handleMessageChange}
                                            >{supportinfo.message}</Input>
                                            <ValidationMessage isInvalid={(supportinfo?.message == undefined) ? true : false} errorMessage="Please type your message" />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row><Col md="12" className="mb-2 ml-5"></Col>
                                        <Col md={{ size: 8, offset: 4 }}>

                                            <Button
                                                color="primary"
                                                type="submit"
                                                className="mr-1 mb-1"
                                                onClick={submitSupport}
                                            >
                                                Submit
                </Button>
                                          
                                        </Col>
                                    </FormGroup>
                                  
                                </Form>
                            </Col>  </>
                                    ) }
                        </Row>
                    ) : (
                            <div className="d-flex justify-content-between mt-1">
                                <Spinner size="lg" className="primary float-right" />
                            </div>
                        )}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
export default Support;