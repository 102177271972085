import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyLotSize = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleLotWChange = (e:any) =>{    
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {      
          setProperty({...property,lotSizeW:e.target.value});
        }else
        {
          if(property.lotSizeW==null || property.lotSizeW==undefined)
          {
            setProperty({...property,lotSizeW:""});
          }
          
        }
      }
    
      const handleLotDChange = (e:any) =>{    
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {      
          setProperty({...property,lotSizeD:e.target.value});
        }else
        {
          if(property.lotSizeD==null || property.lotSizeD==undefined)
          {
            setProperty({...property,lotSizeD:""});
          }
          
        }
      }

        return (     
            <>         
                  <Col md="8" sm="12"> 
                <div className="d-inline mr-1">Lot Size</div>
                <Row>
                <Col md="3" sm="6"> <Input type="text" value={property.lotSizeW} onChange={handleLotWChange} className="d-inline" style={{width:60+'px'}} placeholder="feet"/>  wide</Col>                
            
                <Col md="3" sm="6"> x <Input type="text" value={property.lotSizeD} onChange={handleLotDChange} className="d-inline" style={{width:60+'px'}} placeholder="feet"/> deep</Col>                
               
                </Row>                
              </Col>       
         </>
    )
}
    