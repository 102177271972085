export const setDatePickerFormatUpdated = (inputDateFormat: any) => {
  var outputDateFormat;
  var mydate = new Date(inputDateFormat);
    var dd = String(mydate.getDate()).padStart(2, '0');
    var mm = String(mydate.getMonth() + 1).padStart(2, '0'); 
    var yyyy = mydate.getFullYear();
    outputDateFormat =  yyyy + '-' + mm + '-' + dd ;  
      
  return outputDateFormat;
};

export const setDatePickerFormat = (inputDateFormat: any) => {  
  var outputDateFormat;
  var mydate = new Date(inputDateFormat);
    var dd = String(mydate.getDate()).padStart(2, '0');
    var mm = String(mydate.getMonth() + 1).padStart(2, '0'); 
    var yyyy = mydate.getFullYear();
    outputDateFormat =  yyyy + '-' + mm + '-' + dd ;    
  return outputDateFormat;
};

export const setDatePickerFormat2 = (inputDateFormat: any) => {
  var outputDateFormat;
  var mydate = new Date(inputDateFormat);
    var dd = String(mydate.getDate()).padStart(2, '0');
    var mm = String(mydate.getMonth() + 1).padStart(2, '0'); 
    var yyyy = mydate.getFullYear();
    outputDateFormat =    mm + '/' + dd +'/'+ yyyy  ;    
  return outputDateFormat;
};

export const getDayDiff = (startDate: any,endDate:any) => {
  var start = new Date(startDate);
  var end= new Date(endDate);
  var Difference_In_Time = end.getTime() - start.getTime(); 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days.toFixed(0);
}
export const getDayDiffFromToday = (endDate:any) => {
  var start = new Date();
  var end= new Date(endDate);
  var Difference_In_Time = end.getTime() - start.getTime(); 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days.toFixed(0);
}

export const getDate = (day: any) => {
  var outputDateFormat;
  var today = new Date()
  var defaultExpiryDate = new Date().setDate(today.getDate()+day); 
  var mydate = new Date(defaultExpiryDate);
    var dd = String(mydate.getDate()).padStart(2, '0');
    var mm = String(mydate.getMonth() + 1).padStart(2, '0'); 
    var yyyy = mydate.getFullYear();
    //outputDateFormat =    mm + '/' + dd +'/'+ yyyy  ;  
    outputDateFormat =      yyyy +'-'+mm+'-'+dd  ;  
  return outputDateFormat;
}
export const getTodayDate = ()=> {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 
  var yyyy = today.getFullYear();
  var TodayFormat =  yyyy + '-' + mm + '-' + dd ;
  return TodayFormat;
}
