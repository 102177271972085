import React, { useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import { Property } from '../../../../contracts/response-models/property'
import { SelectConditionInput } from '../../SelectInput/SelectConditionInput'
import CurrencyInput from 'react-currency-input-field';
import { UnitDetails } from "../../../../contracts/response-models/unit-detail";
import { displayCurrency } from "../../../../utils/display-currency";
import NumberFormat from "react-number-format";

interface PropertyDetailsStepProps {
  property: Property,
  setProperty: any;
}


export const PropertyUnitDetails = ({ property, setProperty }: PropertyDetailsStepProps) => {

  React.useEffect(() => {
    var unitInfo = new Array;
    if (property.unitsDetail != undefined && property.unitsDetail?.length > 0) {
      if (property.units != undefined && property.units >= property.unitsDetail?.length) {
        unitInfo = property.unitsDetail;
      } else {
        property.unitsDetail?.map((object, i) => {
          if (property.units != undefined && i + 1 <= property.units) {
            unitInfo?.push({
              noOfBedrooms: object.noOfBedrooms,
              noOfFullBaths: object.noOfFullBaths,
              noOfHalfBaths: object.noOfHalfBaths,
              conditionId: object.conditionId,
              rent: object.rent,
              isVacant: object.isVacant,
            });
          }


        })
      }

    }
    Array(property.units).fill(0, 0, property.units).map((object, i) => {
      if (property.unitsDetail != undefined) {
        if (i + 1 > property.unitsDetail?.length) {
          unitInfo?.push({
            noOfBedrooms: 0,
            noOfFullBaths: 0,
            noOfHalfBaths: 0,
            conditionId: 0,
            rent: undefined,
            isVacant: false,
          });
        }
      } else {
        unitInfo?.push({
          noOfBedrooms: 0,
          noOfFullBaths: 0,
          noOfHalfBaths: 0,
          conditionId: 0,
          rent: undefined,
          isVacant: false,
        });
      }
    }
    )
    setProperty({ ...property, unitsDetail: unitInfo });

  }, [property.units]);
  const handleUnitDetailsChange = (value: any, index: any, field: string) => {
    var newArray;

    if (property.unitsDetail != undefined) {
      newArray = [...property.unitsDetail];
      if (field == 'bedrooms') {

        if (value.length>2) {
          value = property.unitsDetail[index].noOfBedrooms;
        } else if (value == '') {
          value = 0;
        }
        newArray[index] = {
          ...newArray[index],
          noOfBedrooms: value
        };
      } else if (field == 'fullBaths') {
        if (value.length>2) {
          value = property.unitsDetail[index].noOfFullBaths;
        } else if (value == '') {
          value = 0;
        }
        newArray[index] = {
          ...newArray[index],
          noOfFullBaths: value
        };
      } else if (field == 'halfBaths') {
        if (value.length>2) {
          value = property.unitsDetail[index].noOfHalfBaths;
        } else if (value == '') {
          value = 0;
        }
        newArray[index] = {
          ...newArray[index],
          noOfHalfBaths: value
        };

      } else if (field == 'condition') {
        newArray[index] = {
          ...newArray[index],
          conditionId: value
        };
      } else if (field == 'rent') {

        if (value != '' && value > 0) {
          newArray[index] = {
            ...newArray[index],
            rent: value
          };
        }
        if (value == '') {
          newArray[index] = {
            ...newArray[index],
            rent: undefined
          };
        }

      } else if (field == 'isVacant') {
        if (value == true) {
          newArray[index] = {
            ...newArray[index],
            rent: 0,
            isVacant: value
          };
        } else {
          newArray[index] = {
            ...newArray[index],
            rent: undefined,
            isVacant: value
          };
        }

      }
    }

    setProperty({ ...property, unitsDetail: newArray });

  }

  return (
    <>


      {property.unitsDetail?.map(function (unitInfo, i) {
        if (i % 2 == 0) {
          var rowClass = "odd";
        } else {
          var rowClass = "even";
        }
        return (
          <>
            <Row ><Col md="12" sm="12" className={rowClass}><strong>Unit {i + 1}</strong></Col></Row>
            <Row className={rowClass}>
              <Col md="2" sm="12">
                <div className="d-inline mr-1">Bedrooms</div>
                
                  <NumberFormat value={unitInfo.noOfBedrooms}
                    onChange={(e: any) => { handleUnitDetailsChange(e.target.value, i, 'bedrooms') }}
                    allowNegative={false}
                    thousandSeparator={false}
                    decimalScale={0}
                    className={classnames("mr-1 form-control", {
                      invalid: (unitInfo.noOfBedrooms == undefined)
                    })}
                  />
                 

              </Col>

              <Col md="2" sm="12">
                <div className="d-inline mr-1">Full Baths</div>
                
                <NumberFormat value={unitInfo.noOfFullBaths}
                    onChange={(e: any) => { handleUnitDetailsChange(e.target.value, i, 'fullBaths') }}
                    allowNegative={false}
                    thousandSeparator={false}
                    decimalScale={0}
                    className={classnames("mr-1 form-control", {
                      invalid: (unitInfo.noOfFullBaths == undefined)
                    })}
                  />
                
              </Col>

              <Col md="2" sm="12">
                <div className="d-inline mr-1">Half Baths</div>
                 
                <NumberFormat value={unitInfo.noOfHalfBaths}
                    onChange={(e: any) => { handleUnitDetailsChange(e.target.value, i, 'halfBaths') }}
                    allowNegative={false}
                    thousandSeparator={false}
                    className={classnames("form-control", {
                      invalid: (unitInfo.noOfHalfBaths == undefined)
                    })}
                  />
                 
              </Col>
              <Col md="2" sm="12">
                <div className="d-inline mr-1">Condition</div>
                <SelectConditionInput
                  defaultValue={unitInfo.conditionId}
                  name="condition"
                  className={classnames("React", {
                    invalid: (unitInfo.conditionId == undefined)
                  })}
                  onChange={(option: any) => { handleUnitDetailsChange(option.value, i, 'condition') }}
                />
              </Col>
              <Col md="2" sm="12">
                <div className="d-inline mr-1">Rent</div>
                {unitInfo.isVacant == true ? (
                  <>&nbsp;<CurrencyInput
                    placeholder="Rent"
                    value={0}
                    className={classnames("form-control", {
                      invalid: (unitInfo.rent == undefined || unitInfo.rent.toString() == "")
                    })}
                    disabled
                  /></>
                ) : (
                    <Input
                      placeholder="Rent"
                      value={(unitInfo.rent == undefined ? "" : displayCurrency(unitInfo.rent))}
                      className={classnames("form-control", {
                        invalid: (unitInfo.rent == undefined || unitInfo.rent.toString() == "")
                      })}
                      disabled={unitInfo.isVacant}
                      onChange={
                        (e) => {
                          var price = Number(e.target.value?.replace(/,/g, ''));
                          if (isNaN(price) !== false) {
                            //handleUnitDetailsChange(0, i, 'rent')
                          } else {
                            handleUnitDetailsChange(price, i, 'rent')
                          }

                        }
                      }
                    />)}

              </Col>
              <Col md="2" sm="12">
                <div className="d-inline mr-1">Is Vacant</div>
                <FormGroup>
                  <div className="unitInputContainer">
                    <CustomInput type="switch" onChange={(e: any) => {
                      handleUnitDetailsChange(e.target.checked, i, 'isVacant')
                    }} id={"chk_" + i} checked={unitInfo.isVacant == undefined ? false : unitInfo.isVacant} name="customSwitch[]" inline >

                    </CustomInput></div>
                </FormGroup>
              </Col>
              <Col md="12" sm="12">
                <hr /></Col>
            </Row>
          </>
        );
      })}


    </>
  )

}
