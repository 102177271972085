import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectGarageInput} from '../../SelectInput/SelectGarageInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyGarage = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleGarageChange = (option:any) =>{    
        setProperty({...property,garageId:option.value});
      }    

        return (     
            <>         
            <Col md="3" sm="12"> 
            <div className="d-inline mr-1">Garage</div>
            <SelectGarageInput                               
              defaultValue={property.garageId}
              name="garage" 
              className={classnames("React",{
                invalid: (property.garageId == undefined)
              })}
              onChange={handleGarageChange}                 
            />                 
          </Col> 
         </>
    )
}
    