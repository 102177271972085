import { title } from "process";
import { PropertyType, SaleType } from "../contracts/enums";

export function displayTitle(propertyType:any,saleType:SaleType,displaySeperator:boolean,ownerShip:string) {
    try {
        var title="";

         if(propertyType==PropertyType.SFH)
         {
            title="House";   
         }else if(propertyType==PropertyType.TWH)
         {
            title="Townhouse";   
         }else if(propertyType==PropertyType.APT)
         {
            if(saleType==SaleType.ForSale)
            {
               if(ownerShip=='c')
               {
                  title="Condo"; 
               }else if(ownerShip=='p')
               {
                  title="Co-op"; 
               }else
               {
                  title="Apartment";
               }
            }else
            {
               title="Apartment"; 
            }
            
             
         }else if(propertyType==PropertyType.LND)
         {
            title="Land";   
         }else if(propertyType==PropertyType.MFH)
         {
            title="Multi Family Home";   
         }else if(propertyType==PropertyType.COM)
         {
            title="Commercial";   
         } 

         if(saleType==SaleType.ForRent)
         {
            title = title +" For Rent";  
         }else if(saleType==SaleType.ForSale)
         {
            title = title +" For Sale";   
         } 
         if(displaySeperator)
         {
            title = " - " + title;
         }
        
      if(propertyType!==PropertyType.NONE)   
      return title;
    } catch (e) {
      console.log(e)
    }
  }; 