import React, { useState, useRef, useEffect } from "react";
import { TrendingUp, Twitch, Users } from "react-feather";
import { Link } from "react-router-dom";
import {Button, Row, Col, Spinner} from "reactstrap"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { PropertyType } from "../contracts/enums/property-type";
import { DashboardSummary } from "../contracts/response-models/dashboard-summary";

import {getDashboardSummaryService} from "../machines/listing-machine/services"
import { displayCurrency } from "../utils/display-currency";
 
export const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary>();

  useEffect(() => {
    async function loadDashboardSummary() {
    setLoading(true);
    var response = await getDashboardSummaryService();
    setDashboardSummary(response);
    setLoading(false);
  }
  loadDashboardSummary();

  }, []);

    return (
        <React.Fragment>     

                   
     <Row>
      <Col lg={4} md={12}>
        <Link to={`/listings/add?type=${PropertyType.SFH}`} >
        <Button className="mr-1 mb-1 btn-block bg-gradient-success"   color="primary" size="lg">
          Add Single Family
        </Button></Link>
      </Col>
      <Col lg={4} md={12}>
      <Link to={`/listings/add?type=${PropertyType.APT}`} ><button className="mr-1 mb-1 btn-block  btn-lg bg-gradient-info  "   color="info" >
          Add Apartment
        </button></Link>
      </Col>
      <Col lg={4} md={12}>
      <Link to={`/listings/add?type=${PropertyType.TWH}`} ><Button   className="mr-1 mb-1 btn-block bg-gradient-warning"   color="primary" size="lg">
         Add Townhouse
        </Button></Link>
      </Col>
      <Col lg={4} md={12}>
      <Link to={`/listings/add?type=${PropertyType.MFH}`} ><Button className="mr-1 mb-1 btn-block bg-gradient-warning"   color="primary"  size="lg">
        Add Multi family
        </Button></Link>
      </Col>
      <Col lg={4} md={12}>
      <Link to={`/listings/add?type=${PropertyType.COM}`} ><button className="mr-1 mb-1 btn-block  btn-lg bg-gradient-info  "   color="primary"  >
         Add Commercial
        </button></Link>
      </Col>
      <Col lg={4} md={12}>
      <Link to={`/listings/add?type=${PropertyType.LND}`} ><Button   className="mr-1 mb-1 btn-block bg-gradient-success"   color="primary" size="lg">
         Add Land
        </Button></Link>
      </Col>
    </Row>

    <Row>
    <Col lg={12} md={12} className="mt-2">
   
        <h4 className="card-title">Statistics</h4> 
   
      </Col>

      {loading ?( <div className="d-flex justify-content-between mt-1">                               
                <Spinner size="lg"  className="primary float-right"/>
              </div>):(<>
      <Col lg={4} md={12}>
      <div className="card-statistics card">
    <div className="card-header">
        <h4 className="card-title">Profile Views</h4>
        <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
    </div>
    <div className="statistics-body card-body">
        <div className="row">
            <div className="mb-2 mb-xl-0 col-sm-6 col-xl-3">
                <div className="media">
                    <div className="avatar mr-2 bg-light-primary">
                        <span className="avatar-content">
                        <Users size="24" />
                        </span>
                    </div>
                    <div className="my-auto media-body">
                        <h4 className="font-weight-bolder mb-0">{displayCurrency(dashboardSummary?.profileViews)}</h4>
                        <p className="font-small-3 mb-0 card-text">Views</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>

      </Col>
      <Col lg={4} md={12}>
      <div className="card-statistics card">
    <div className="card-header">
        <h4 className="card-title">Listing Views</h4>
        <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
    </div>
    <div className="statistics-body card-body">
        <div className="row">
            <div className="mb-2 mb-xl-0 col-sm-6 col-xl-3">
                <div className="media">
                    <div className="avatar mr-2 bg-light-primary">
                        <span className="avatar-content">
                          <TrendingUp size="24" />
                          
                        </span>
                    </div>
                    <div className="my-auto media-body">
                        <h4 className="font-weight-bolder mb-0">{displayCurrency(dashboardSummary?.listingViews)}</h4>
                        <p className="font-small-3 mb-0 card-text">Views</p>
                    </div>
                </div>
            </div>
      
        </div>
    </div>
</div>

      </Col>
      <Col lg={4} md={12}>
      <div className="card-statistics card">
    <div className="card-header">
        <h4 className="card-title">Inquiries</h4>
        <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
    </div>
    <div className="statistics-body card-body">
        <div className="row">
            <div className="mb-2 mb-xl-0 col-sm-6 col-xl-3">
                <div className="media">
                    <div className="avatar mr-2 bg-light-primary">
                        <span className="avatar-content">
                        <Twitch size="24" />
                          
                        </span>
                    </div>
                    <div className="my-auto media-body">
                        <h4 className="font-weight-bolder mb-0">{displayCurrency(dashboardSummary?.inquiries)}</h4>
                        <p className="font-small-3 mb-0 card-text"></p>
                    </div>
                </div>
            </div>
      
        </div>
    </div>
</div>

      </Col>
      </>)}
      </Row>  
      </React.Fragment>
    )
}

export default DashboardPage;