import React,{useEffect, useState} from "react";

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { Link,Redirect } from "react-router-dom"

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"
import { ToastContainer,toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
 
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"

import { listPostsService } from "../machines/listing-machine/services"
import { Post } from "../contracts/response-models/post";

import { DeletePostService } from "../machines/listing-machine/services"
import { PagedResult } from "../contracts/response-models/paged-result";


export const MyPosts = () => {
    const [postsResult, setPostResult] = React.useState<PagedResult<Post>>();
    const [posts, setPosts] = React.useState<Post[]>([]);
    const [loading,setLoading] = useState(false);
  
    const [readyState, setReadyState] = useState(true); 
    const [paged, setPaged] = useState(1);
    const [perpagearecord,setPagePerRecord] = useState(10); 
    const [sortedColumn,setSortedColumn]=useState('id');
    const [sortDirection,setSortDirection]=useState('Dsc');
    const [dataMessage,setDataMessage]=useState('Waiting...');
    const [defaultSortAsc, setDefaultSortAsc] = useState(false);
    useEffect(() => {    
        async function loadPosts() {
          if (readyState == true) {
            setLoading(true); 
            const postResult = await listPostsService(paged,perpagearecord,sortedColumn,sortDirection);    
            setPostResult(postResult)
            setPosts(postResult.data);
            setPaged(postResult.pageIndex);
            settotalrows(postResult.totalRecords);
            setLoading(false);
            setReadyState(false);
            }
        }
        
        loadPosts();

    },[readyState,paged,perpagearecord,sortedColumn,sortDirection]);

    const [showDeleteConfirm,setshowDeleteConfirm] = useState(false); 
    const [showDeleteMessage,setshowDeleteMessage] = useState(false); 
    const [totalrows, settotalrows] = useState(posts.length);
 
    
    const [idToDelete,setIdToDelete] = useState(0);      
    
    const handleRemove=(id:number)=>{
      setIdToDelete(id);
      setshowDeleteConfirm(true);
    }

    const ChangePagination = (page:any,totalRows:any) => { setPaged(page);  setReadyState(true);  }
    const ChangeRowsPerPage = (rowPerPage:number) => { setPagePerRecord(rowPerPage);  setReadyState(true); }
  

    const sortResult = (column: any, sortDirection: any) => {     
      setSortedColumn(column.selector);
      if(sortDirection=='desc')
      {
        setSortDirection('dsc');
        setDefaultSortAsc(false);
      }else
      {
        setSortDirection('asc');
        setDefaultSortAsc(true);
      }
     
      setReadyState(true);
  
    };

    const handleConfirmDelete = async (confirmRespons:boolean) =>{     
      if(!confirmRespons){
        setshowDeleteConfirm(confirmRespons);
        return;
      }
      if(idToDelete>0){       
        setshowDeleteConfirm(false);

        const rsp = await DeletePostService(idToDelete)
        toast.success("Post Deleted Successfully", {              
          position: toast.POSITION.TOP_RIGHT
        })
        setReadyState(true);
          
      }         
    }
    const handleDeleteMessage= (deleteMessage:boolean) => {
      setshowDeleteMessage(deleteMessage);
    }

    const columns = [
         
          {
            name: "TITLE",
            selector: "title",
            sortable: true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0">       
              <Link to={`/posts/preview/${row.id}`} className="cursor-pointer text-center">
                               {row.title}
               </Link>
             </p>
            )
          },
          
        {
          name: "PUBLISHED",
          selector: "DateAdded",
          sortable: true,
          center:true,
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
            {row.dateAddedFormated}           
            </p>
          )
        },
        
          {
            name: "STATUS",
            selector: "status",
            sortable: true,
            center:true,
            cell: (row:any) => (
                <p   className="text-truncate  mb-0">
                {row.status==1 && ("Active")} 
                {(row.status==undefined || row.status==0) && ("Pending")}               
                   
                </p>
              )
          },
        {
          name: "ACTIONS",
          selector: "",
          sortable: false,
          center:true,
          cell: (row:any) => (  
            <>
            <UncontrolledButtonDropdown>
                           <DropdownToggle color="primary" size="sm" caret>
                             Action
                             <ChevronDown size={15} />
                           </DropdownToggle>
                           <DropdownMenu >
                             
                             <Link to={`/posts/add/${row.id}`} className="cursor-pointer">
                             <DropdownItem  tag="li" >Edit
                             </DropdownItem></Link>
                             <Link to={`/posts/`} className="cursor-pointer"  ><DropdownItem  onClick={() => handleRemove(row.id)} tag="li"  >
              Delete</DropdownItem>
             </Link>
                           </DropdownMenu>
                         </UncontrolledButtonDropdown>
            <div className="data-list-action d-none">  
            <Link to={`/posts/preview/${row.id}`} className="cursor-pointer">
                    <Eye className="cursor-pointer mr-1" size={20} />
                  </Link>   
                  <Link to={`/posts/add/${row.id}`} className="cursor-pointer">
                    <Edit className="cursor-pointer mr-1" size={20} />
                  </Link>
                  <Link to={`/posts/`}    className="cursor-pointer"  >
                    <Trash 
                      className="cursor-pointer"
                      size={20}
                      onClick={() => handleRemove(row.id)}        
                    />
                  </Link>
            </div> 
            </>             
          )
        },
        
      ];
    return (
        <React.Fragment>
        <Breadcrumbs breadCrumbTitle="Blog"   breadCrumbActive="My Posts" />
        <ToastContainer />
      
        <Card className="mt-2">
                    <CardBody> 
                    {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
                    <DataTable
                      title="Posts"
                      columns={columns}
                      noHeader={true}
                      className="ListingTable"
                      data={posts}
                      defaultSortField={sortedColumn}
                defaultSortAsc={defaultSortAsc}
                pagination={true}
                onChangePage={ChangePagination}
                paginationDefaultPage={paged}
                onChangeRowsPerPage={ChangeRowsPerPage}
                paginationTotalRows={totalrows}
                paginationPerPage={perpagearecord}
                paginationServer={true}
                selectableRows={false}
                responsive={true}
                sortServer={true}
                onSort={sortResult}
                striped={true}               
                    />    )} 
                    </CardBody>

        </Card>  
        <SweetAlert title="Are you sure?" 
          warning
          show={showDeleteConfirm} 
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => handleConfirmDelete(true)}          
          onCancel={() => handleConfirmDelete(false)}        
        >
          You won't be able to revert this! 
        </SweetAlert>
        <SweetAlert success title="Deleted!" 
          confirmBtnBsStyle="success"
          show={showDeleteMessage}
          onConfirm={() => handleDeleteMessage(false)}     
        >
            <p className="sweet-alert-text">Your post deleted.</p>
        </SweetAlert>
        </React.Fragment>
    )
}