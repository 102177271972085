import React from "react";
import Select from "react-select"
import { PropertyType } from "../../../contracts/enums/property-type";




export const SelectLeaseTermInput = (props:any) => {  
    var residentialsOptions = [
        { value: undefined, label: "Select One"},
        { value: 3, label: "Short Term"},
        { value: 1, label: "1 Year"},
        { value: 2, label: "1+ Years"}
    ]
    var commercialOptions = [
        { value: undefined, label: "Select One"},
        { value: 3, label: "Short Term"},
        { value: 1, label: "1 Year"},
        { value: 2, label: "2 Years"},
        { value: 4, label: "3 Years"},
        { value: 5, label: "4 Years"},
        { value: 6, label: "5+ Years"}, 
    ]
    if(props.property.propertyType==PropertyType.COM)
    {
        var dataOptions = commercialOptions;
    }else
    {
        var dataOptions = residentialsOptions;
    }
    
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectLeaseTermInput;