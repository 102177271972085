import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,   
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import Radio from "../../../../components/@vuexy/radio/RadioVuexy"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyParking = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handlehasParking = (e:any) =>{       
        setProperty({...property,hasParking:e.target.value});
      }
            return (     
            <> 

            <Col md="3" sm="12">              
                <Row   >
                  <Col md="6" sm="6" >
                 Parking </Col>
                  <Col md="6" sm="6" className={classnames(" ",{
                    invalid: (property.hasParking == undefined)
                  })}>      
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="Yes"
                      color="primary"
                      defaultChecked={property.hasParking!=undefined && property.hasParking==true}
                      name="hasParking"
                      value={true}
                      onChange={handlehasParking}
                    />
                    </div>                    
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="No"
                      color="primary"                     
                      defaultChecked={property.hasParking!=undefined && property.hasParking==false}
                      name="hasParking"
                      value={false}
                      onChange={handlehasParking}
                    />
                    </div>                    
                  </Col>                  
                </Row>
               </Col>       
              
         </>
    )
}
    