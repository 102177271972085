import React, { useState } from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import { setDatePickerFormat } from "../../../../utils/date-helper";
import { ValidationMessage } from "../../validation-message";
import { useEffect } from "react";

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAvailableDate = ({ property, setProperty}:PricingStepProps) => {   
    
  const [availableDate,setaAvailableDate] = useState<any>(setDatePickerFormat(property.availableDate));

  useEffect(() => {
    async function setDate() {
       if(property.availableDate!='' || property.availableDate!=undefined) 
       { 
        setaAvailableDate(setDatePickerFormat(property.availableDate));
       } 
    } 
    setDate();
  }, [property.availableDate]);

    const handleAvailableDateChange = (e:any) =>{
       
      if(e.target.value===false)
        {
          setProperty({...property,availableDate:undefined}); 
          setaAvailableDate(undefined)        
        }else{          
          setProperty({...property,availableDate:e.target.value});  
          setaAvailableDate(e.target.value)          
        }
       
      }
    

        return (     
            <>    
                <div className="form-label-group mt-2"> 
        <div className="d-inline mr-1">Available Date</div>
                <Input           
                   placeholder="Available Date"     
                   type="date"
                  value={(property.availableDate == undefined ? "" : availableDate)}
                  className={classnames("",{
                    invalid: (property.availableDate == undefined || property.availableDate.toString()=="")
                  })}
                  mobile                   
                  onChange={handleAvailableDateChange}
                />
                   <ValidationMessage isInvalid={(property.availableDate==undefined) || (property.availableDate.toString() !='') ? false : true} errorMessage="Available date must be after the listing date"/>
        
              </div>

                 
         </>
    )
}
    