import React,{useState,useEffect} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
    Card,
    CardBody,
   
} from "reactstrap"

 
import { Inquiry } from "../../../contracts/response-models/inquiry";
import { setDatePickerFormat2 } from "../../../utils/date-helper";

interface PropertyIdProps {  
    inquiry?:Inquiry,
    toggle:any; 
    readyState:any;
  setReadyState:any; 
}


export const InquiryModal = ({ inquiry,toggle,readyState,setReadyState}:PropertyIdProps) => {  

  const [loading, setLoading] = useState(false);
   
        return (     
          <>   
          <ModalHeader toggle={toggle}  >
          Inquiry Information
          </ModalHeader>
          <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <>
            <Card className="invoice-page">
                <CardBody>
                <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">First Name :</Col> 
                      <Col lg="8" md="6" sm="12">{inquiry?.firstName}</Col> 
                      </Row>
                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Last Name :</Col> 
                      <Col lg="8" md="6" sm="12">{inquiry?.lastName}</Col> 
                      </Row>
                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Email :</Col> 
                      <Col lg="8" md="6" sm="12">{inquiry?.email}</Col> 
                      </Row>
                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Cell :</Col> 
                      <Col lg="8" md="6" sm="12">{inquiry?.cell}</Col> 
                      </Row>
                      {inquiry?.propertyAddress!=undefined && (
                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Property :</Col> 
                      <Col lg="8" md="6" sm="12">  {inquiry.propertyAddress.street}, {inquiry.propertyAddress.areaTitle}, {inquiry.propertyAddress.stateCD} &nbsp; 
            {inquiry.propertyAddress.zipCode}</Col> 
                      </Row>)}

                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Message :</Col> 
                      <Col lg="8" md="6" sm="12">{inquiry?.message}</Col> 
                      </Row>

                      <Row className=" mt-1">
                      <Col lg="4" md="6" sm="12">Date :</Col> 
                      <Col lg="8" md="6" sm="12">{setDatePickerFormat2(inquiry?.dateAdded)}</Col> 
                      </Row>
                    
                      </CardBody></Card>
         
            </>
          )}
      </ModalBody>           
         </>
    )
}
    