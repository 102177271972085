import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

 
import SortableList, { SortableItem } from "react-easy-sort";
 import arrayMove from "array-move";
import { Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";
import classnames from "classnames";
import { PropertyPhoto } from "../contracts/response-models/property-photo";
import { getPropertyPhotosService, patchPhotosService } from "../machines/listing-machine/services";

interface imageInfo {
  id:number;
  fileName:string;
  fullUrl:string;
}
  
export const PropertyImages = () => { 
  const [loading,setLoading] = useState(false);
  
  const [propertyPhoto, setPropertyPhoto] = useState<PropertyPhoto[]>();
  let { id } = useParams(); 
  const [isUploading,setIsUploading] = useState(false);
  const [isReady,setIsReady] = useState(false);
  const [imageUrlInfo,setImageUrlInfo]= useState<imageInfo[]>([]);

  useEffect(() => {    
    async function getPropertyPhoto() {
      setLoading(true);
        if(id){
            const propertyphotos = await getPropertyPhotosService(id);  
            setPropertyPhoto(propertyphotos);    
       
        }
      setLoading(false); 
    }
    getPropertyPhoto();
},[id]);



useEffect(() => {    
    async function setimageUrl() {
      var imgArr = new Array<imageInfo>()
      propertyPhoto?.map((imageInfo, index) => {
        var itemid = index+1;
        var imagestringArray = imageInfo.image.split("/");
        var lengthofArray = imagestringArray.length; 
        imgArr?.push({
          id:itemid,
          fileName: imagestringArray[lengthofArray-1].toString(),
          fullUrl: imageInfo.image.toString(), 
      });
      })
      if(imageUrlInfo?.length==0 || imageUrlInfo?.length == undefined)
      {
        setImageUrlInfo(imgArr);
      }
     

    }
    setimageUrl();
},[propertyPhoto]);

useEffect(() => { 

  console.log("sorting changed.")
  handlePhotoSave(imageUrlInfo);

},[imageUrlInfo]);

  const handlePhotoUploadChange = (e:any) =>{
    
    setIsUploading(true);
    const filesUpload = e.target.files;  
    const formData = new FormData()
    for(var i=0;i<filesUpload.length;i++)
    {
      formData.append('files', filesUpload[i])
    }
    uploadFiles(formData);
  }

  //TODO: Move this function in API
  
  const uploadFiles=(formData:any)=>{
    fetch('https://api.zaglist.com/api/upload', {  
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
     
      var filearray = [];
      var imageinfoarray = [];
      for(var i=0;i<data.length;i++)
      {
        filearray.push({'image':data[i].fileName,'tags':''});
        if(imageUrlInfo!=undefined)
        {
          var itemid = imageUrlInfo?.length+i+1;
        }else
        {
          var itemid = i+1;
        }
       
        imageinfoarray.push({'id':itemid,'fileName':data[i].fileName,'fullUrl':data[i].fullUrl});
      }     

      var emptyArr = new Array<string>()
      var arrPhotos = propertyPhoto == undefined ? emptyArr : propertyPhoto;
    //   arrPhotos = arrPhotos.concat(filearray);

      var emptyimageInfo = new Array<imageInfo>()
      var imagesInfo = imageUrlInfo == undefined ? emptyimageInfo : imageUrlInfo;
      imagesInfo = imagesInfo.concat(imageinfoarray);
      setImageUrlInfo(imagesInfo);  
      setIsUploading(false); 
    })
    .catch(error => {
      console.error(error)
    }) 
  } 

  const handlePhotoSave =async (imagesArray:imageInfo[]) =>{
    //e.preventDefault();   
   
    let images = new Array; 
    imagesArray?.map((object, i) => { 
      images?.push(object.fileName);  
    })     
     
    console.log("images.length:"+images.length)
    if(images.length>0 && images.length<=50)
    { 
      console.log("handlePhotoSave");
        // setLoading(true);
      if(images!=undefined)
      { 
        await patchPhotosService(id,images); 
      }        
      //  setLoading(false);
    } 
     
}
const handlePhotoDelete =(e:any,id:number) =>{
  e.preventDefault(); 
  let imagesInfo = imageUrlInfo;
  let updateimagesInfo = new Array;

  imagesInfo?.map((object, i) => {
    if(object.id!=id)
    { 
      updateimagesInfo?.push({
        id: object.id,
        fileName: object.fileName.toString(),
        fullUrl: object.fullUrl
    });
    }
  }) 
  setImageUrlInfo(updateimagesInfo);  
  let images = new Array;
  updateimagesInfo?.map((object, i) => {  
    images?.push({'image':object.fileName,'tags':''}); 
  })  
 if(propertyPhoto!=undefined)
      setPropertyPhoto(images);   
     
}

      const onSortEnd = (oldIndex: number, newIndex: number) => { 
         setImageUrlInfo((array) => arrayMove(array, oldIndex, newIndex)); 
      };
      return (
<React.Fragment>
            <Breadcrumbs breadCrumbTitle="Photos"  breadCrumbParentLink="/listings" breadCrumbParent="My Listings" breadCrumbActive="Photos" />
           
            <Card className="overflow-hidden app-ecommerce-details">
          <CardBody className="pb-0">
          {loading? (
              <div className="d-flex justify-content-between mt-1">                               
              <Spinner size="lg"  className="primary float-right"/>
            </div>
            ):(
              <>
            <Row> 
            <Col className="mt-1 pl-0 pl-1 align-middle" sm="12" md="12"  > 
            <h3 className="mb-1 mt-1">PHOTOS</h3>
    <div className="d-inline mr-1 d-none" ><i className="d-none">Note: Max 24 photographs. </i></div>  
             </Col> 
                  </Row>
                  <div className="form-label-group mt-0 d-none"> 
            <Input
              multiple
              type="file"
              className={classnames("React",{
                invalid: (propertyPhoto == undefined || propertyPhoto.length==0 || propertyPhoto.length>24)
              })}
              name="propertyphoto[]"
              id="propertyphoto"             
              placeholder="Upload Photos"
              onChange={handlePhotoUploadChange}
            />   
           <i> To change the order, click on the image and drag it to a desired position.</i> 
            {isUploading && ("Uploading...")}  
         
          </div>
         
        <SortableList
          onSortEnd={onSortEnd}
          draggedItemClassName="imageSorted"
        >
          {imageUrlInfo?.map((imageurlinfo,index) => (
            <>
            {index<24 && (
            
            <SortableItem key={imageurlinfo.fileName}><div className="imageitem photo-edit-container-vertical"> 
             <div className="PropertyDeleteIcon" onClick={(e)=> { handlePhotoDelete(e,imageurlinfo.id)
                }} >x</div> 
        <span className="imageCounter"> {index+1}.  </span>    <img
                  
                  src={imageurlinfo.fullUrl} 
                />
                
              </div>
            </SortableItem>
            )}
                </>
          ))}
          
        </SortableList>
        
        </>
     )}
        </CardBody>
      
      </Card>
      </React.Fragment>
      );
}
export default PropertyImages;