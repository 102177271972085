import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import { history } from "../history" 
import loginImg from "../assets/img/pages/login.png"
import "../assets/scss/pages/authentication.scss"
import { listStatesService, registerUserService, resendVerficationCodeService, verifyEmailCodeService } from '../machines/listing-machine/services'
import { ValidationMessage } from "../components/@vuexy/validation-message";
import { registerUserInfo } from "../contracts/response-models/register-user";
import PhoneInput from "react-phone-input-2";
import "../components/Register/css/custom-register.css"
import Select from "react-select"
import { frontEndBaseUrl } from "../configs/global";
import { ActionResponse } from "../contracts/response-models/action-response";
import { RegisterResponse } from "../contracts/response-models/register-response";

import { VerifyEmailCodeRequest } from "../contracts/request-models/verify-email-code-request";
import { frontUrl } from "../utils/front-url";
import { UserCell } from "../components/Users/user-cell";
import Timer from "../utils/timer";


export const RegisterPage = () => {

  const [loading, setLoading] = useState(false);
  const [codeEmailloading, setcodeEmailloading] = useState(false);
  const [resendActivate, setResendActivate] = useState(false);
  
  const [user, setUser] = useState<registerUserInfo>();
  const [confirmCodeInfo, setConfirmCodeInfo] = useState<VerifyEmailCodeRequest>();
  const [regiserResponse, setRegisterResposne] = useState<RegisterResponse>();
  const [verifyCodeResponse, setVerifyCodeResponse] = useState<ActionResponse>();
  const [validationMessage, setValidationMessage] = useState<string>();
  const defaultOption = { label: "Select", value: "" }
  
  const [selectedState, setSelectedState] = React.useState(defaultOption);
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);
 
  const [smsTimer, setSmsTimer] = useState(30);

 /*
  useEffect(() => {
    setInterval(() => {
      var timer = smsTimer-1;
     setSmsTimer(timer); 
     
  }, 1000)
  }, [smsTimer]); */




  useEffect(() => {
    async function loadStats() { 
      const states = await listStatesService();
      const stateOptions = states.map((state) => ({ label: state.stateTitle, value: state.stateCD }))
      setStateItems(stateOptions);

    }
    loadStats();
  }, []);
  const passwordValidate = (passwordValue: string) => {

    var isValidated = true;

    if (passwordValue != undefined && passwordValue != "") {
      if (passwordValue.length < 8) {
        setValidationMessage("Password must have minimum 8 characters");
        isValidated = false;
      } else if (!passwordValue.match(/[A-Z]+/)) {
        setValidationMessage("Password must have a capital (uppercase) letter");
        isValidated = false;
      } else if (!passwordValue.match(/[a-z]+/)) {
        setValidationMessage("Password must have a lowercase letter");
        isValidated = false;
      } else if (!passwordValue.match(/[0-9]+/)) {
        setValidationMessage("Password must have a number");
        isValidated = false;
      } else if (!passwordValue.match(/[!@#$%\^&*(){}[\]<>?/|\-_]+/)) {
        setValidationMessage("Password must have a special character");
        isValidated = false;
      } else {
        setValidationMessage("");
        isValidated = true;
      }

    }
    return isValidated;
  }
  let handleRegister = async (e: any) => {
    e.preventDefault()
    var isValidated = true;
    if (user?.terms != true) {
      setUser({ ...user, terms: false });
      isValidated = false
    }
    /*
    if (user?.confirmPassword == undefined || user.confirmPassword == "") {
      setUser({ ...user, confirmPassword: '' });
      isValidated = false
    } */
    if((user?.cellno == undefined) || (user.cellno == '') || (user?.cellno.length < 12))
    { 
      setUser({ ...user, cellno: '' });
      isValidated = false;
    }
    if (user?.password == undefined || user.password == "") {
      setUser({ ...user, password: '' });
      setValidationMessage("Please type password");
      isValidated = false
    }
    if (user?.password != undefined && !passwordValidate(user?.password)) {
      isValidated = false;
    }
    /*
    if (user?.password != undefined && user.confirmPassword != undefined && user.password != user.confirmPassword) {
      setValidationMessage("Please type same password again");
      isValidated = false;
    }*/

    if (user?.userName == undefined || user.userName == "") {
      setUser({ ...user, userName: '' });
      isValidated = false
    }
    if (user?.firstName == undefined || user.firstName == "") {
      setUser({ ...user, firstName: '' });
      isValidated = false
    }


    if (isValidated == true && user != undefined) {
      setLoading(true);
      var response = await registerUserService(user);
      if (response.success == true) {
        if (user?.userName != undefined && user.userName != "") {
          setConfirmCodeInfo({
            ...confirmCodeInfo,
            code: undefined,
            userName: user?.userName,
          })
         setResendActivate(false);
         startCounter(e);
        }
      }
      setRegisterResposne(response)
      
      setLoading(false);
    }
  }




  let ConfirmVerificationCode = async (e: any) => {
    setVerifyCodeResponse(undefined); 
    if(confirmCodeInfo?.code!=undefined && confirmCodeInfo?.code!="" && confirmCodeInfo?.userName!=undefined)
    {
      setcodeEmailloading(true);
      var response = await verifyEmailCodeService(confirmCodeInfo?.code,confirmCodeInfo?.userName);
      setVerifyCodeResponse(response);
      setConfirmCodeInfo({ ...confirmCodeInfo, code: undefined });
      setcodeEmailloading(false);
      
    }
    
  };

  let ResendVerificationCode = async (e: any) => { 
    setVerifyCodeResponse(undefined); 
    setConfirmCodeInfo({ ...confirmCodeInfo, code: undefined });
    setcodeEmailloading(true); 
    if(user?.userName!=undefined && user?.cellno!=undefined && user?.cellno!="" && user?.cellno.length == 12)
    {
    
      if(regiserResponse?.signature!=undefined)
      {  
        var response = await resendVerficationCodeService(user?.userName,user?.cellno,regiserResponse?.signature);
        setRegisterResposne({...regiserResponse,
          success:response.success,
          error:response.error,
        });
        setResendActivate(false);
        startCounter(e);
      }
     
      
    }
    setcodeEmailloading(false);
  };
  
  
  let startCounter = async (e: any) => { 
    var timeLeft = 30; 
    var timerContainer =  document.getElementById('timerContainer'); 
    var timerId = setInterval(() => { 
      if (timeLeft == 0) { 
        clearTimeout(timerId);
        //@ts-ignore   
        timerContainer.innerHTML =''; 
        setResendActivate(true);
      } else {
        //@ts-ignore    
        timerContainer.innerHTML = "Resend Code after : "+timeLeft+" seconds";
        timeLeft--;
      }
    }
      , 1000);

  };
 


  return (
    <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100">
             
                <Row className="m-0 justify-content-center">
                  <Col
                    sm="8"
                    xl="7"
                    lg="10"
                    md="8"
                    className="d-flex justify-content-center"
                  >
                    <Card className="bg-authentication rounded-0 mb-0 w-100">
                      <Row className="m-0">
                        <Col
                          lg="6"
                          className="d-lg-block d-none text-center align-self-center px-1 py-0"
                        >
                          <a href={frontUrl} ><img className="loginImage" src={loginImg} alt="Zaglist.com" /></a>
                        </Col>
                        <Col lg="6" md="12" className="p-0">
                          <Card className="rounded-0 mb-0 p-2">

                          {regiserResponse?.success == true ?(<>
                            <CardHeader className="pb-1 pt-50">
                              <CardTitle>
                                <h4>MADE FOR REAL ESTATE AGENTS</h4> 
                              </CardTitle>
                            </CardHeader>
                            <p className="px-2 auth-title mb-0">
                            Please enter verification code, that we have sent to your cell  ({user?.cellno}) to complete your registration. </p>
                            </>):(
                              <>
                            <CardHeader className="pb-1 pt-50">
                              <CardTitle>
                                <h4>MADE FOR REAL ESTATE AGENTS</h4>
                                <h4 className="mb-0">Create Account</h4> 
                              </CardTitle>
                            </CardHeader>
                            <p className="px-2 auth-title mb-0"> 
                            </p>
                            </>)}

                            <CardBody className="pt-1 pb-50">
                              {regiserResponse?.success == false && (<>
                                <div className="auth-title mb-2 alert alert-danger">
                                  <strong>Error: </strong> {regiserResponse.error}</div></>)}
                              {loading ? (
                                <div className="d-flex justify-content-between mt-1">
                                  <Spinner size="lg" className="primary float-right" />
                                </div>) : (
                                  <Form action="/" onSubmit={handleRegister}>
                                    {regiserResponse?.success == true ? (<>
                                      
                                      {codeEmailloading ? (
                                <div className="d-flex justify-content-between mt-1">
                                  <Spinner size="lg" className="primary float-right" />
                                </div>) : (
                                  <>
                                      {verifyCodeResponse?.success == false && (<>
                                <div className="auth-title mb-2 alert alert-danger">
                                  <strong>Error: </strong> {verifyCodeResponse.error}</div></>)}

                                  {verifyCodeResponse?.success == true ? (<>
                                <div className="auth-title mb-2 alert alert-success">
                                  <strong>Thank you for registration.</strong></div>
                                  <Link to="/login">Login Now</Link>
                                  </>):(<>
                                    <FormGroup  className="form-label-group1 show_label">
                                     
                                     <UserCell user={user} setUser={setUser} /> 
                                     
                                  
                                     <ValidationMessage isInvalid={(user?.cellno != undefined) && (user?.cellno.length != 12) ?  true: false } errorMessage="Please enter valid cell no" />
                  
                                   </FormGroup> 
                                      <FormGroup className="form-label-group">
                                        <div className="verifyCodeContainer">
                                        <Input
                                          type="text"
                                          placeholder="Verification Code"
                                          value={confirmCodeInfo?.code}
                                          onChange={(e) => { setConfirmCodeInfo({ ...confirmCodeInfo, code: e.target.value }) }}
                                        /><a href="#"
                                        
                                        onClick={(e)=> {
                                          e.preventDefault();
                                          if(resendActivate)
                                          {
                                              var validate=true;
                                              if(user?.cellno !=undefined && (user?.cellno == '' || user?.cellno.length != 12)  )
                                              {  
                                              validate=false;
                                              }
                                              if(validate==true)
                                              {
                                              ResendVerificationCode(e);
                                              }
                                          }
                                           
                                           }}>Resend</a></div>
                                            <div id="timerContainer"></div> 
                                        <Label>Verification Code</Label>
                                        <ValidationMessage isInvalid={(confirmCodeInfo?.code == '') ? true : false} errorMessage="Please enter verification code" />

                                      </FormGroup>
                                      <div className="d-flex justify-content-between">

                                        <Button Ripple color="primary" onClick={(e)=> {
                                         e.preventDefault();
                                         var validate=true;
                                         if(confirmCodeInfo?.code==undefined || confirmCodeInfo?.code=='' )
                                         {
                                          setConfirmCodeInfo({ ...confirmCodeInfo, code: "" });
                                          validate=false;
                                         }
                                         if(validate==true)
                                         {
                                          ConfirmVerificationCode(e);
                                         }
                                         
                                          
                                          }} type="button">
                                          Confirm Code
                        </Button>
&nbsp;
                   
                                      </div>
                                      </>)}
                                      </>)}
                                    </>
                                    ) : (<>
                                      <FormGroup className="form-label-group">
                                        <Input
                                          type="text"
                                          placeholder="First Name"
                                          value={user?.firstName}
                                          onChange={(e) => { setUser({ ...user, firstName: e.target.value }) }}
                                        />
                                        <Label>First Name</Label>
                                        <ValidationMessage isInvalid={(user?.firstName == undefined) || (user.firstName != '') ? false : true} errorMessage="Please enter first name" />

                                      </FormGroup>
                                      <FormGroup className="form-label-group">
                                        <Input
                                          type="text"
                                          placeholder="Last Name"
                                          value={user?.lastName}
                                          onChange={(e) => { setUser({ ...user, lastName: e.target.value }) }}
                                        />
                                        <Label>Last Name</Label>
                                      </FormGroup>
                                      <FormGroup className="form-label-group">
                                        <Input
                                          type="email"
                                          placeholder="Email"
                                          value={user?.userName}
                                          onChange={(e) => { setUser({ ...user, userName: e.target.value }) }}
                                        />
                                        <Label>Email</Label>
                                        <ValidationMessage isInvalid={(user?.userName == undefined) || (user.userName != '') ? false : true} errorMessage="Please enter email id" />

                                      </FormGroup>
                                      <FormGroup className="form-label-group">
                                        <Input
                                          type="password"
                                          placeholder="Password"
                                          value={user?.password}
                                          onChange={(e) => { setUser({ ...user, password: e.target.value }) }}
                                        />
                                        <Label>Password</Label>
                                        {(validationMessage != undefined && validationMessage != '') && (
                                          <>
                                            <div className="invalid-feedback d-block">{validationMessage}</div>
                                          </>
                                        )}
                                      </FormGroup>
                                      <FormGroup  className="form-label-group1 show_label">
                                     
                                        <UserCell user={user} setUser={setUser} /> 
                                        
                                     
                                        <ValidationMessage isInvalid={(user?.cellno != undefined) && (user?.cellno.length != 12) ?  true: false } errorMessage="Please enter valid cell no" />
                     
                                      </FormGroup> 


                                      <FormGroup>
                                        <Checkbox
                                          className="termsCheck"
                                          color="primary"
                                          icon={<Check className="vx-icon" size={16} />}
                                          label=""
                                          checked={user?.terms}
                                          onChange={(e: any) => { setUser({ ...user, terms: e.target.checked }) }}
                                        /> <p className="acceptTerms">I accept the <a target="_blank" href={frontEndBaseUrl() + 'terms-of-use'}>Terms & Conditions</a></p>
                                        <ValidationMessage isInvalid={(user?.terms == undefined) || (user.terms != false) ? false : true} errorMessage="Please accepts terms & condition" />

                                      </FormGroup>
                                      <div className="d-flex justify-content-between">

                                        <Button Ripple color="primary" type="submit">
                                          Register
                        </Button>
                                      </div>
                                      <div className="mt-2"  >
                                        Already have account? <Link to="/login">Login Now</Link>
                                      </div>
                                      <div className="mt-0 mob-display"  >
                       Continue on <a href={frontUrl}>Zaglist.com</a>
                    </div> </>)}
                                  </Form>

                                )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage;