import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { displayFormat } from "../../utils/display-currency";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsLND = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
            <Row className="odd">
                 
                 <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Offered Acres  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.availavleAcres!=null && ( <>
              {displayFormat(property?.availavleAcres.toFixed(2))}  &nbsp;
              </>
              )}
              {property?.lotSizeD!=null && property?.lotSizeW !=null &&(
                 <>
             ({displayFormat(property?.lotSizeW)} x {displayFormat(property?.lotSizeD)})
              </>
              )}

              
              </Col>
              </Row> </Col>
              
              <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Total Acres  :
              </Col>
              <Col lg="6"  md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.totalAcres!=null && ( <>
              {displayFormat(property?.totalAcres.toFixed(2))}
              </>  )}
              </Col>
              </Row>  </Col>
          

                 </Row>
                 <Row className="even">
                 
                 <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Topography  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.topography}
              </Col>
              </Row> </Col>
              
              <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Smallest Acres   :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.smallestAcres!=null && ( <>
              {displayFormat(property?.smallestAcres.toFixed(2))}
              </>  )}
              </Col>
              </Row>  </Col>
          

                 </Row>

                 <Row className="odd">
                 
                 <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Wetlands  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.wetLand}
              </Col>
              </Row> </Col>
              
              <Col lg="6" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              # of Lots :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.noofLots}
              </Col>
              </Row>  </Col>
          

                 </Row>

         
         </>
    )
}
    