import React, { useState,useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import {
    Row,    
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Col,
    Input,  
    Media,  
    Table,
    CustomInput,
    Form,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody
  } from "reactstrap"
  import userImg from "../assets/img/portrait/small/avatar-s-18.jpg"
  import "../assets/scss/pages/users.scss"
  import {User} from '../contracts/response-models/user'
  import {getUserProfileInfoService} from "../machines/listing-machine/services"
import { UserFPModal } from "../components/Users/user-fp-modal";
import { useModal } from "../components/MyListings/useModal";
import { ActionModalTypes } from "./MyListings";
import { ToastContainer } from "react-toastify";
import { ProfileSection } from "../contracts/response-models/profile-section";
import { AgentProfile } from "../contracts/response-models/agent-profile";
import { getDayDiff, getDayDiffFromToday, setDatePickerFormat2 } from "../utils/date-helper";

export const ProfilePreview = () => {

  const [loading,setLoading] = useState(false);

  const [user, setUser] = useState<User>({userId:0});
  var baseImagePath = "https://api.zaglist.com/photo/agent/250/250/";
  const [profileImage, setProfileImage] = useState<string>(baseImagePath+user.userPhotoFileName);

  const { isModalOpened, toggleModal } = useModal();
const [openModal, setOpenModal] = useState<ActionModalTypes>();
const [readyState, setReadyState] = useState(true);

const [userProfileInfo, setUserProfileInfo] = useState<AgentProfile>();

  useEffect(() => {    
    async function getUserInfo() {      
      setLoading(true);  
      const userProfileinfo = await getUserProfileInfoService(); 
    //  userProfileinfo.featuredStartDate = "2020-12-11T14:10:35.788Z";
     // userProfileinfo.featuredEndDate = "2020-12-31T14:10:35.788Z";
      setUserProfileInfo(userProfileinfo)
      setUser(userProfileinfo.user);
      if(userProfileinfo.user.userPhoto!=undefined)
      {
        setProfileImage(baseImagePath+userProfileinfo.user.userPhotoFileName);
      }
      
      setLoading(false);
    }
    getUserInfo();
},[]);

const openFeaturedUserModal = () => {
   
  toggleModal();    
}

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="My Profile" breadCrumbActive="My Profile" />
            <ToastContainer />

            <Card>    

            <Modal
              id="actionModal"
                  isOpen={isModalOpened}
                  toggle={toggleModal}
                  className="modal-dialog-centered"
                >
                <UserFPModal user={user} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
   
          </Modal>  
        <CardHeader>
                <CardTitle>Agent Information</CardTitle>
              </CardHeader>
              <CardBody>
              {loading ? (
                <div className="d-flex justify-content-between mt-1">                               
                <Spinner size="lg"  className="primary float-right"/>
              </div>
              ): (

              
              <Row className="mx-0" col="12">
                  <Col className="pl-0" sm="12">
                    <Media className="d-sm-flex d-block">
                      <Media className="mt-md-1 mt-0" left>
                        {user.userPhotoFileName!='' && user.userPhotoFileName!=null && (
                        <Media
                          className="rounded mr-2"
                          object
                          src={profileImage}
                          alt={user.firstName}
                          title={user.firstName} 
                          style={{maxWidth:'100%'}} 
                        />)}
                      </Media>
                      <Media body>
                        <Row className="ml-5">
                          <Col sm="12"  md="12" lg="12">
                            <div className="users-page-view-table">
                              <div className="d-flex user-info font-weight-bold">                                
                                <div><h3>{user.firstName} {user.lastName}</h3></div> 
                              </div>
                              <div className="d-flex user-info">                               
                              <div> {user.title} </div>
                              </div>
                              <div className="d-flex user-info">
                                <div className="user-info-title">
                               {user.cellno} Cell<br/>
                               {user.userName} E-mail
                                </div>
                                 
                             
                              </div>
                              <div className="d-flex user-info">
                                <div className="user-info-title ">
                                {user.companyName} <br/>
                               {user.streetAddress}<br/>
                                {user.city}, {user.stateCD} {user.zip}<br/>
                               {user.tel} Office
                                </div>
                             
                              </div>
                              <div className="d-flex user-info font-weight-bold">                                
                                <div><h3>About Me</h3></div>   
                                                
                              </div>
                              <div className="d-flex user-info">                               
                              <div> {userProfileInfo?.profileSections != undefined && (
                        userProfileInfo.profileSections.map(function (profileInfo, i) {
                          return (<>
                          <h6>{profileInfo.title}</h6> 
                          {profileInfo.section!=undefined && (
                            <div dangerouslySetInnerHTML={{ __html: profileInfo.section.replace(/(?:\r\n|\r|\n)/g, '<br>')}} />  
                          )}
                           </>)
                        }))}
                          </div>        
                              </div>
                              {user.website!="" && (
                              <div className="d-flex user-info">                               
                              <div>Website : {user.website}</div>        
                              </div>
                              )}

                            </div>
                          </Col>
                          <Col md="12" lg="5">
                    
                          </Col>
                        </Row>
                      </Media>
                    </Media>
                  </Col>
                  <Col className="mt-1 pl-0" sm="2">
                    <Button className="mr-1" color="primary" outline>
                      <Link to="/edit-profile">
                        <Edit size={15} />
                        <span className="align-middle ml-50">Edit</span>
                      </Link> 
                    </Button>                  
                  </Col>
                  <Col className="mt-1 pl-0" sm="10">

                    {!user.isFeatured ?(
                    <Button type="button" className="btn btn-primary" color="primary" onClick={openFeaturedUserModal} >
                                            
                     Make Featured 
                      
                    </Button>    
                    ):(

                      <p className="featuredBox">Your profile has been featured<br/>
                      Start Date : {setDatePickerFormat2(userProfileInfo?.featuredStartDate)}<br/>
                      End Date : {setDatePickerFormat2(userProfileInfo?.featuredEndDate)}<br/>
                      Days : {getDayDiffFromToday(userProfileInfo?.featuredEndDate)} days remaining
                      </p>

                    )}              
                  </Col>
                </Row>
                )}
              </CardBody>
           
           
            </Card>
        </React.Fragment>
    )
}
export default ProfilePreview;