import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../contracts/enums'
import { displayFormat } from "../../utils/display-currency";
interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
            <Row className="odd">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className="w-50 mob_even  text-right pitem-rspace">
              # of Rooms :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.noOfRooms}
              </Col>
              </Row> </Col>
              
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Basement  :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.basement}
              </Col>
              </Row>  </Col>
              <Col  lg="4" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Lot Size  :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.lotSizeD!=null && property?.lotSizeW !=null ? (
                 <>
              {displayFormat(property?.lotSizeD)} x {displayFormat(property?.lotSizeW)}
              </>
              ):("-") }
              </Col>
              </Row> 
              </Col>

                 </Row>
                 <Row className="even">
                 
                 <Col  lg="3" md="12" sm="6">
              <Row  >
              <Col  lg="8" md="6" sm="6"   className="w-50 mob_odd  text-right pitem-rspace">
              Bedrooms   :
              </Col>
              <Col  lg="4" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.noOfBedrooms}
              </Col>
              </Row> </Col>
              <Col  lg="5" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Style  :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.style}
              </Col>
              </Row>  </Col>
              <Col  lg="4" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Heating Source :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.heatingSource}
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="odd">
                 
                 <Col  lg="3" md="12" sm="6">
              <Row  >
              <Col  lg="8" md="6" sm="6"   className="w-50 mob_even  text-right pitem-rspace">
              Full Baths :
              </Col>
              <Col  lg="4" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.noOfFullBaths}
              </Col>
              </Row> </Col>
              <Col  lg="5" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_odd text-right">
              Exterior Finish :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.exteriorFinish}
              </Col>
              </Row>  </Col>
              <Col  lg="4" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Cooling Type :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.coolingType}
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="even">
                 
                 <Col  lg="3" md="12" sm="6">
              <Row  >
              <Col  lg="8" md="6" sm="6"   className="w-50 mob_odd  text-right pitem-rspace">
              Half Baths :
              </Col>
              <Col  lg="4" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row> </Col>
              <Col  lg="5" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Year Built :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.yearBuilt}, {property.condition}
              </Col>
              </Row>  </Col>
              <Col  lg="4" md="12" sm="6">
              <Row  >
              <Col  lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Garage  :
              </Col>
              <Col  lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.garage}
              </Col>
              </Row> 
              </Col>

                 </Row>
 
         </>
    )
}
    