import {client} from "../../client";
import {User} from "../../../contracts/response-models/user"
import { PagedResult } from "../../../contracts/response-models/paged-result";
import { AgentSearchRequest } from "../../../contracts/response-models/agent-search-request";

function listUsers(paged:number,perpagearecord:number,sortedColumn:string,sortDirection:string,request:AgentSearchRequest){

    return client<PagedResult<User>>(`/users/allusers/${perpagearecord}/${paged}/${sortedColumn}/${sortDirection}`, request,{
        method: "POST",
    });   
}

export { listUsers }