import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectWetlandsInput} from '../../SelectInput/SelectWetlandsInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyWetlands = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleWetlandsChange = (option:any) =>{    
        setProperty({...property,wetLandId:option.value});
      }
    

        return (     
            <>  
           
           <Col md="3" sm="12"> 
            <div className="d-inline mr-1">Wetlands</div>
            <SelectWetlandsInput                               
              defaultValue={property.wetLandId}
              name="heatingSource"                
              className={classnames("React",{
                invalid: (property.wetLandId == undefined)
              })}
              onChange={handleWetlandsChange}
            /> 
          </Col>
         </>
    )
}
    