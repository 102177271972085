export function displayCurrency(amount: any, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
       var currencyOutput = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return currencyOutput;
    } catch (e) {
      console.log(e)
    }
  };
  export function displayFormat(amount: any, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
       var currencyOutput = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return currencyOutput;
    } catch (e) {
      console.log(e)
    }
  };

  export function setDecimalValue(e: any) {
    try {
      var finalval='';
      var start =e.target.selectionStart; 
      // if(start==2){ start=3;}
       let val = e.target.value;
      
       val = val.replace(/([^0-9.]+)/, "");
      val = val.replace(/^(0|\.)/, ""); 
       e.target.value = val;
       finalval = val;
       
       e.target.setSelectionRange(start, start); 
      return finalval;
    } catch (e) {
      console.log(e)
    }
  };

  