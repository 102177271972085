import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "..//InputStyles"
import classnames from "classnames";
import Checkbox from "../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../contracts/response-models/property'

import {listAmenitiesService} from "../../../machines/listing-machine/services"
import { Aminity } from "../../../contracts/response-models/aminity";
import { PropertyType } from "../../../contracts/enums";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAmenities = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const [amenities, setAmenities] = React.useState<Aminity[]>([]);

    useEffect(() => {    
        async function loadAmenities() {
            const amenities = await listAmenitiesService(property.propertyType,property.saleType);    
            setAmenities(amenities);              
        }
        loadAmenities();
    },[property.id]);

    const  handleAmentiesChange = (e:any) => {  
       
       var emptyArr = new Array<Aminity>()
       var arrAmenities = property.aminities == undefined ? emptyArr : property.aminities;

       const selectedAminity = amenities.find(element => element.id== parseInt(e.target.value)) ;
       if(e.target.checked && selectedAminity){  
         
            arrAmenities.push(selectedAminity);
            setProperty({...property,aminities:arrAmenities});
        }
        else{        
            const found = arrAmenities.find(element => element.id==selectedAminity?.id);          
            if(found)     {
                const foundIndex=arrAmenities.indexOf(found,0);
                arrAmenities.splice(foundIndex,1);
                setProperty({...property,aminities:arrAmenities});              
            }         
        }       
      }
      
      const SetDefaultChecked=(am:Aminity)=>{        
        var arrAmenities =  property.aminities;  
       // console.dir("amenity section inner new  pid :"+property?.id+" & selected : "+ (property.aminities)); 
        if(arrAmenities!=undefined){    
            const found = property.aminities?.find(element => element.id==am.id);                   
            return found!=undefined;      
        } 
       
        return false;
      }
        return (     
            <>        
           <Row className="mt-2"> 
           <Col md="12" sm="12">
        <h4 className="mb-3 mt-2 text-center">{property.propertyType==PropertyType.COM || property.propertyType==PropertyType.MFH ?("UTILITIES"):("AMENITIES")}</h4>
               </Col>
           {amenities.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleAmentiesChange} icon={<Check className="vx-icon" size={16} />} label={object.title} defaultChecked={SetDefaultChecked(object)}/>
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    