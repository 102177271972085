export const getAgentNumber = (agentId: any) => {
    var AgentNumber;
    if (agentId < 10) {
        AgentNumber = "000" + agentId
    } else if (agentId >= 10 && agentId < 100) {
        AgentNumber = "00" + agentId
    } else if (agentId >= 100 && agentId < 1000) {
        AgentNumber = "0" + agentId
    }   else {
        AgentNumber = agentId;
    }
    var AgentFinalNumber = "A-"+AgentNumber;
    return AgentFinalNumber;
};