import React,{useEffect, useState} from "react";

import Breadcrumbs from "../../components/@vuexy/breadCrumbs/BreadCrumb"
import { Link,Redirect } from "react-router-dom"

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"
import { ToastContainer,toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/plugins/extensions/toastr.scss";
 
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"

import { listPostsService, listUsersService } from "../../machines/listing-machine/admin/services"
import { Post } from "../../contracts/response-models/post";

import { DeletePostService, PatchUserStatusService } from "../../machines/listing-machine/services"
import { PagedResult } from "../../contracts/response-models/paged-result";
import { User } from "../../contracts/response-models/user";
import { getAgentNumber } from "../../utils/agent-number";
import { displayCurrency } from "../../utils/display-currency";
import { UserStatus } from "../../contracts/response-models/userstatus";


export const Agents = () => {
  const [postResult, setPostResult] = React.useState<PagedResult<Post>>();
    const [users, setUsers] = React.useState<User[]>([]);
    const [loading,setLoading] = useState(false);
  
    const [readyState, setReadyState] = useState(true); 
    const [paged,setPaged] = useState(1); 
    const [query,setQuery] = useState('');
    const [perpagearecord,setPagePerRecord] = useState(10); 
    const [sortedColumn,setSortedColumn]=useState('userId');
    const [sortDirection,setSortDirection]=useState('Dsc');
    const [dataMessage,setDataMessage]=useState('Waiting...');
    const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  
    useEffect(() => {    
        async function loadAgents() {
          if (readyState == true) {
            setLoading(true); 
            const postsResult = await listUsersService(paged,perpagearecord,sortedColumn,sortDirection,query);    
            setPostResult(postsResult)
            setUsers(postsResult.data);
            setPaged(postsResult.pageIndex);
            settotalrows(postsResult.totalRecords);
            setLoading(false);
            setReadyState(false);
          }
        }
        
        loadAgents();

    },[readyState,paged,perpagearecord,sortedColumn,sortDirection]);

    const [showDeleteConfirm,setshowDeleteConfirm] = useState(false); 
    const [showDeleteMessage,setshowDeleteMessage] = useState(false); 
    const [totalrows,settotalrows]=useState(50);
    
    const [idToDelete,setIdToDelete] = useState(0);      
    
    const handleRemove=(id:number)=>{
      setIdToDelete(id);
      setshowDeleteConfirm(true);
    }
    const ChangePagination = (page:any,totalRows:any) => { setPaged(page);  setReadyState(true);}
    const ChangeRowsPerPage = (rowPerPage:number) => { setPagePerRecord(rowPerPage);  setReadyState(true); } 
 
    const sortResult = (column: any, sortDirection: any) => {     
      setSortedColumn(column.selector);
      if(sortDirection=='desc')
      {
        setSortDirection('dsc');
        setDefaultSortAsc(false);
      }else
      {
        setSortDirection('asc');
        setDefaultSortAsc(true);
      }
     
      setReadyState(true);
  
    };

    const handleConfirmDelete = async (confirmRespons:boolean) =>{     
      if(!confirmRespons){
        setshowDeleteConfirm(confirmRespons);
        return;
      }
      if(idToDelete>0){       
        setshowDeleteConfirm(false);

        const rsp = await setUserStatus('Deleted',idToDelete)
       
        setReadyState(true);
          
      }         
    }
    const handleDeleteMessage= (deleteMessage:boolean) => {
      setshowDeleteMessage(deleteMessage);
    }
    const setUserStatus = async (status:string,userid:number) =>{
      setLoading(true);
      const rsp = await PatchUserStatusService(status,userid)
      if(status=="Deleted")
      {
        toast.success("User Deleted Successfully", {              
          position: toast.POSITION.TOP_RIGHT
        })
      }else{
        toast.success("User Status Updated Successfully", {              
          position: toast.POSITION.TOP_RIGHT
        })
      }
      setReadyState(true);
      setLoading(false);
         
    }
    

    const columns = [
         
          {
            name: "User#",
            selector: "userId",
            sortable: true,
            center:true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0">
       
       <Link to={`/agents/${row.userId}`} className="cursor-pointer text-center">
                               {getAgentNumber(row.userId)}
               </Link>
             </p>
            )
          },
          
        {
          name: "First Name",
          selector: "firstName",
          sortable: true,
         
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
            {row.firstName} 
                       
               
            </p>
          )
        },{
          name: "Last Name",
          selector: "lastName",
          sortable: true,
          
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
            {row.lastName} 
                       
               
            </p>
          )
        },
        {
          name: "E-mail ID",
          selector: "userName",
          sortable: true,
          cell: (row:any) => (
            
            <p   className=" mb-0">
            {row.userName} 
                       
               
            </p>
          )
        },
        {
          name: "Registered",
          selector: "DateAdded",
          sortable: true,
          center:true,
          cell: (row:any) => (
            
            <p   className="mb-0">
            {row.dateAdded} 
                       
               
            </p>
          )
        }
        , 
    {
      name: "VIEWS",
      selector: "views",
      sortable: true,
      right:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {displayCurrency(row?.views)}
        </p>
      )
    }
        ,
        
          {
            name: "STATUS",
            selector: "status",
            sortable: true,
            center:true,
            cell: (row:any) => (
                <p   className="text-truncate  mb-0">
                {row.status==1 && ("Active")} 
                {(row.status==undefined || row.status==0) && ("Pending")}   
                {(row.status==2) && ("Suspended")}  
                {(row.status==3) && ("Deleted")}    
                {(row.status==4) && ("InComplete")}  
                         
                   
                </p>
              )
          },
        {
          name: "ACTIONS",
          selector: "",
          sortable: false,
          center:true,
          cell: (row:any) => (  
            <>
            <UncontrolledButtonDropdown>
            {row.status!=3 && (<> <DropdownToggle color="primary" size="sm" caret>
                             Action
                             <ChevronDown size={15} />
                           </DropdownToggle>
                           <DropdownMenu >
                            
                             <Link to={`/agents/edit/${row.userId}`} className="cursor-pointer">
                             <DropdownItem  tag="li" >Edit
                             </DropdownItem></Link>
                             {row.status==2 && ( 
                             <Link to={`/agents/`} className="cursor-pointer"  ><DropdownItem  onClick={() => setUserStatus('Active',row.userId)} tag="li"  >
              Active</DropdownItem>
             </Link>)}
                             {row.status==1 && ( 
                             <Link to={`/agents/`} className="cursor-pointer"  ><DropdownItem  onClick={() => setUserStatus('Suspended',row.userId)} tag="li"  >
              Suspend</DropdownItem>
             </Link>)}
            
             <Link to={`/agents/`} className="cursor-pointer"  ><DropdownItem  onClick={() => handleRemove(row.userId)} tag="li"  >
              Delete</DropdownItem>
             </Link>  
                           </DropdownMenu></>)}
                         </UncontrolledButtonDropdown>
       
            </>             
          )
        },
        
      ];
    return (
        <React.Fragment>
        <Breadcrumbs breadCrumbTitle="Users"   breadCrumbActive="Agents" />
        <ToastContainer />
      
        <Card className="mt-2">
                    <CardBody> 
                    {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
            <>
             <FormGroup row>
             <Col lg="6" md="6" sm="6">
               </Col>
                <Col lg="4" md="4" sm="6">

             <Input
                                                type="text"
                                                name="searchAgent"
                                                value={query}
                                                onChange={(e)=> { setQuery(e.target.value)   }}
                                                onBlur={(e)=> {  setReadyState(true) }}
                                                id="searchAgent"
                                                placeholder="State, Town, Zip Code or Last Name"
                                            />
             </Col>
            <Col  lg="1" md="1" sm="6"> <Button
                        color="primary"
                        className="fr"
                        type="button" 
                        onClick={(e)=> {  setReadyState(true) }}
                      >
                        Search
                </Button></Col>
            </FormGroup>
                    <DataTable
                      title="Agents"
                      className="ListingTable"
                      columns={columns} 
                      data={users}
                      noHeader={true}
                      defaultSortField={sortedColumn}
                defaultSortAsc={defaultSortAsc}
                pagination={true}
                onChangePage={ChangePagination}
                paginationDefaultPage={paged}
                onChangeRowsPerPage={ChangeRowsPerPage}
                paginationTotalRows={totalrows}
                paginationPerPage={perpagearecord}
                paginationServer={true}
                selectableRows={false}
                responsive={true}
                sortServer={true}
                onSort={sortResult}
                striped={true}              
                    /> </>   )} 
                    </CardBody>

        </Card>  
        <SweetAlert title="Are you sure?" 
          warning
          show={showDeleteConfirm} 
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => handleConfirmDelete(true)}          
          onCancel={() => handleConfirmDelete(false)}        
        >
          You won't be able to revert this! 
        </SweetAlert>
        <SweetAlert success title="Deleted!" 
          confirmBtnBsStyle="success"
          show={showDeleteMessage}
          onConfirm={() => handleDeleteMessage(false)}     
        >
            <p className="sweet-alert-text">Your post deleted.</p>
        </SweetAlert>
        </React.Fragment>
    )
}