import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap" 
import { ValidationMessage } from "../../components/@vuexy/validation-message"

import Breadcrumbs from "../../components/@vuexy/breadCrumbs/BreadCrumb"
 
import { listStatesService, listCountyService, listAreaService, getLatlngByAddress, getUserProfileInfoService, editUserProfileService, changePasswordService, getPaymentPlanService, patchPlanService } from "../../machines/listing-machine/services"
 

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/plugins/extensions/toastr.scss";
  
import { PaymentSettingInfo } from "../../contracts/response-models/payment-setting";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import { PaymentPlan } from "../../contracts/response-models/payment-plan";
import { Edit, Lock, Save } from "react-feather";

  
export const PaymentSetting = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, seRefresh] = useState(true);
  const [paymentSetting, setPaymentSetting] = useState<PaymentSettingInfo>();
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan[]>();
  const [updatedPrice, setUpdatedPrice] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>();

  useEffect(() => {
    async function loadPaymentSetting() {
        if(refresh==true)
        {
            setLoading(true); 
            var paymentPlanInfo = await getPaymentPlanService(); 
            if(paymentPlanInfo!=undefined)
            {
                setPaymentPlan(paymentPlanInfo);
            }
            seRefresh(false);
            setLoading(false);
        }
    }
    loadPaymentSetting();
  }, [refresh]);
  


  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Payment Setting"   breadCrumbActive="Payment Setting" />
   <Card>
        <CardBody>
          <ToastContainer />

          {!loading ? (
            <Row className="mt-1">
              <Col className="mx-auto" md="7" sm="12">
          <h3 className="mb-2 text-left">Payment Setting</h3>
                <Form>

                {paymentPlan != undefined && (
                          paymentPlan?.map(function (planInfo, i) {
                            return (<>     
                <FormGroup row>
                    <Col md="5">
                      <span className="payment_label">{planInfo.planName}   </span>
                    </Col>
                    <Col md="4">

                     <div className="payment_label" id={'plan_price_'+planInfo.id} >${planInfo?.price}</div>     
                                
                    <NumberFormat value={updatedPrice!=undefined ?updatedPrice:planInfo?.price}
          onChange={(e)=> {  
            setUpdatedPrice(e.target.value)
           }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="form-control d-none"
          id={'plan_price_edit_'+planInfo.id}
        />   
             
                   <ValidationMessage isInvalid={(updatedPrice == undefined) || (updatedPrice != null) ? false : true} errorMessage="Please enter price" />
                       </Col>
                       <Col md="3">
                       <span className="payment_label">  <a href="#" id={'item_edit_'+planInfo.id} onClick={(e)=> { e.preventDefault();

                        var element = document.getElementById("plan_price_"+planInfo.id);
                        if(element!=null)
                            element.classList.add("d-none");
                        
                        var editelement = document.getElementById("item_edit_"+planInfo.id);
                        if(editelement!=null)
                            editelement.classList.add("d-none");

                        var saveelement = document.getElementById("item_save_"+planInfo.id);
                        if(saveelement!=null)
                        saveelement.classList.remove("d-none");

                        var fieldelement = document.getElementById("plan_price_edit_"+planInfo.id);
                        if(fieldelement!=null)
                        fieldelement.classList.remove("d-none");


                    }}><Edit size="20" /></a>
                    
                     <a href="#" className="d-none" 
                    id={'item_save_'+planInfo.id}
                    onClick={async (e)=>  { e.preventDefault(); 
                        if(updatedPrice!=undefined && updatedPrice!='')
                        {
                            setLoading(true);
                            var response =  await patchPlanService(planInfo.id,updatedPrice) 
                            if(response)
                            {
                                seRefresh(true);
                            }
                            setLoading(false);
                        }
                    }}
                    
                    ><Save size="20" /></a>
                    
                    </span>
                       </Col>
                  </FormGroup> </>)}))}
                 
 
                </Form>
              </Col>
            </Row>
          ) : (
              <div className="d-flex justify-content-between mt-1">
                <Spinner size="lg" className="primary float-right" />
              </div>
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default PaymentSetting;

 
