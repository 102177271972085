export const getZagNumber = (zagId: any) => {
    var ZagNumber;
    if (zagId < 10) {
        ZagNumber = "000000" + zagId
    } else if (zagId >= 10 && zagId < 100) {
        ZagNumber = "00000" + zagId
    } else if (zagId >= 100 && zagId < 1000) {
        ZagNumber = "0000" + zagId
    } else if (zagId >= 1000 && zagId < 10000) {
        ZagNumber = "000" + zagId
    } else if (zagId >= 10000 && zagId < 100000) {
        ZagNumber = "00" + zagId
    } else if (zagId >= 100000 && zagId < 1000000) {
        ZagNumber = "0" + zagId
    }  else {
        ZagNumber = zagId;
    }
    return ZagNumber;
};