import React from "react";
import {
    Label,
    Row,
    Col,
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import { Property } from '../../../../contracts/response-models/property'
import NumberFormat from "react-number-format";

interface PropertyDetailsStepProps {
    property: Property,
    setProperty: any;
}
export const PropertyRooms = ({ property, setProperty }: PropertyDetailsStepProps) => {

    const handleRoomChange = (e: any) => {
        var value = e.target.value;
        if (value.length>2) {
            value = property.noOfRooms;
        } else if (e.target.value == '') {
            value = undefined;
        }
        setProperty({ ...property, noOfRooms: value });
    }

    return (
        <>
            <Col md="3" sm="12">
                <div className="d-inline mr-1"># of Rooms</div>
               
                <NumberFormat value={property.noOfRooms}
                    onChange={handleRoomChange}
                    allowNegative={false}
                    thousandSeparator={false}
                    decimalScale={0}
                    className={classnames("mr-1 form-control", {
                        invalid: (property.noOfRooms == undefined)
                    })}
                />
            </Col>
        </>
    )
}
