import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select One" },
    { value: 1, label: "New" },
    { value: 2, label: "To Be Built" },
    { value: 12, label: "2020's" },
    { value: 11, label: "2010's" },
    { value: 3, label: "2000's" },
    { value: 4, label: "1990's" },
    { value: 5, label: "1980's" },
    { value: 6, label: "1970's" },
    { value: 7, label: "1960's" },
    { value: 8, label: "1950's" },
    { value: 9, label: "1940's" },
    { value: 10, label: "Pre War" }
]

export const SelectYearBuiltInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}           
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectYearBuiltInput;