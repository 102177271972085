import {client} from "../client";
 import {Property} from "../../contracts/response-models/property"
import {PagedResult} from "../../contracts/response-models/paged-result"

import { PropertyType, SaleType } from "../../contracts/enums";

function listProperty(paged:number,perpagearecord:number,sortedColumn:string,sortDirection:string){
    return client<PagedResult<Property>>(`/listings/mylistings/${perpagearecord}/${paged}/${sortedColumn}/${sortDirection}`);   
}

export { listProperty }