import {client} from "../client";
import {ActionResponse} from "../../contracts/response-models/action-response"
import { ResendVerificationCodeRequest } from "../../contracts/request-models/resend-verification-code-request";

function resendVerificationCode(request: ResendVerificationCodeRequest){ 
    return client<ActionResponse>(`/users/resend-code`, request, {
        method: "PATCH",
    });
} 

export { resendVerificationCode }