import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import NumberFormat from "react-number-format";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAvailableSqft = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleAvailableSqftChange = (e:any) =>{
      var value = e.target.value;
      if (e.target.value == '') {
        value = undefined;
      }
        setProperty({...property,availableSqft:value});
      }
    

        return (     
            <>         
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Available (Sqft)</div>
                 
                <NumberFormat value={property.availableSqft}
          onChange={handleAvailableSqftChange}
          allowNegative={false}
          thousandSeparator={true}
          decimalScale={0}
          className={classnames("mr-1 form-control", {
            invalid: (property.availableSqft == undefined)
          })}
        />

            </Col>    
         </>
    )
}
    