import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { displayFormat } from "../../utils/display-currency";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsCOM = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
                 <Row className="even">
                 
                 <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="4" md="6" sm="6"   className="w-50 mob_even  text-right pitem-rspace">
              Style    :
              </Col>
              <Col lg="8" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.style}
              </Col>
              </Row> </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right pitem-rspace">
              Parking Spaces   :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property.parkingSpaces} 
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right pitem-rspace">
              Available Sqft :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {displayFormat(property?.availableSqft)}
              </Col>
              </Row> 
              </Col>

                 </Row>

         </>
    )
}
    