import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { displayFormat } from "../../utils/display-currency";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsAPT = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <div className="property_item">   
            
            <Row className="odd">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className="w-50 mob_even   text-right pitem-rspace">
              Bedrooms  :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.noOfBedrooms}
              </Col>
              </Row> </Col>
              
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd text-right pitem-rspace">
              Parking   :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {(property.hasParking!=undefined && property.hasParking==true) ? "Yes":"No" }
              
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right pitem-rspace">
              Building Type  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.buildingType} 
              </Col>
              </Row> 
              </Col>

                 </Row>
                 <Row className="even">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className="w-50 mob_odd text-right pitem-rspace">
              Full Baths   :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.noOfFullBaths}
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even text-right pitem-rspace ">
              Apt Condition  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.condition}
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row>
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right pitem-rspace">
              Security  :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace " > 
                 
              {(property.isSecurityDeposit!=undefined && property.isSecurityDeposit==true) ? "Yes":"No" }
             
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="odd">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className="w-50  mob_even text-right pitem-rspace">
              Half Baths :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_even font-weight-bold pitem-lspace" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right  pitem-rspace">
              Building Age :
              </Col>
              <Col lg="6" sm="6"  className="w-50 mob_odd font-weight-bold  pitem-lspace" > 
              {property?.yearBuilt}, {property.condition}
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_even  text-right">
              Pets Allowed? :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {(property.isPetsAllowed!=undefined && property.isPetsAllowed==true) ? "Yes":"No" }
             
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="even">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className=" text-right">
               
              </Col>
              <Col lg="4" md="6" sm="6"  className="font-weight-bold" > 
             
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50 mob_odd  text-right">
              Complex Name :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold" > 
              {property?.complexName}
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="w-50  mob_even text-right">
              Size   :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {displayFormat(property?.approxSize)}
              </Col>
              </Row> 
              </Col>

                 </Row>
         </div>
    )
}
    