import {client} from "../client";
import {ActionResponse} from "../../contracts/response-models/action-response"
import { VerifyEmailCodeRequest } from "../../contracts/request-models/verify-email-code-request";

function verifyEmailCode(request: VerifyEmailCodeRequest){ 
    return client<ActionResponse>(`/users/verify-email-code`, request, {
        method: "PATCH",
    });
} 

export { verifyEmailCode }