import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import {
    Label,
    Row,
    Col,
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,

} from "reactstrap"


import { User } from '../../contracts/response-models/user'

interface UserProps {
    user?: User,
    setUser: any,
}

interface CellInfo {
    cell1: any,
    cell2: any,
    cell3: any,
}
export const UserCell = ({ user, setUser }: UserProps) => {
    const [cellInfo, setCellInfo] = useState<CellInfo>({ cell1: '', cell2: '', cell3: '' });

    useEffect(() => {
        async function setCell() {
            if (user?.cellno != undefined) {
                var cellArray = user.cellno.split("-");
                setCellInfo({
                    ...cellInfo,
                    cell1: cellArray[0],
                    cell2: cellArray[1],
                    cell3: cellArray[2],
                })
            }
        }
        setCell();
    }, [user?.cellno]);
    let setCellNo = async (cell1: string, cell2: string, cell3: string) => {
        var cell1Value, cell2Value, cell3Value;
        if (cell1 != undefined) {
            cell1Value = cell1.toString();
        }
        if (cell2 != undefined) {
            cell2Value = cell2.toString();
        }
        if (cell3 != undefined) {
            cell3Value = cell3.toString();
        }

        var CellNo = cell1Value + "-" + cell2Value + "-" + cell3Value;
        setUser({
            ...user,
            cellno: CellNo

        });
    }
    return (
        <>
            <Label style={{ display: 'inline-grid' }}>Cell</Label>
            <div className="cellContainer">

                <Input
                    type="text"
                    maxLength={3}
                    value={cellInfo?.cell1}
                   
                    onChange={(e: any) => {

                        if (isNaN(e.target.value) !== true) {
                            setCellInfo({ ...cellInfo, cell1: e.target.value })
                            setCellNo(e.target.value.toString(), cellInfo.cell2, cellInfo.cell3);

                        }
                        var length = e.target.value.toString().length;
                        if (length == 3 || length > 3) {
                            document.getElementById('cell2')?.focus();
                        }


                    }}
                    allowNegative={false}
                    thousandSeparator={false}
                    className="form-control cellWidth1"
                    id="cell1"
                />
                <span>-</span>
                <Input
                    type="text"
                    maxLength={3} 
                    value={cellInfo?.cell2}
                    
                    onChange={(e: any) => {
                        if (isNaN(e.target.value) !== true) {
                        setCellInfo({ ...cellInfo, cell2: e.target.value })
                        setCellNo(cellInfo.cell1, e.target.value.toString(), cellInfo.cell3);
                        }
                        var length = e.target.value.toString().length;
                        if (length == 3 || length > 3) {
                            document.getElementById('cell3')?.focus();
                        }

                    }}
                    allowNegative={false}
                    thousandSeparator={false}
                    className="form-control cellWidth1"
                    id="cell2"
                />
                <span>-</span>

                <Input
                    type="text"
                    maxLength={4} 
                    value={cellInfo?.cell3}
                    onChange={(e: any) => {
                        if (isNaN(e.target.value) !== true) {
                        setCellInfo({ ...cellInfo, cell3: e.target.value })
                        setCellNo(cellInfo.cell1, cellInfo.cell2, e.target.value.toString());
                        }
                    }}
                    allowNegative={false}
                    thousandSeparator={false}
                    className="form-control cellWidth2"
                    id="cell3"
                />
            </div>
        </>
    )
}
