import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom"
import { useParams } from "react-router";
import { Edit, Trash, Lock, Check, Mail, Phone } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import {getInvoiceInfoService} from "../machines/listing-machine/services"
import { Invoice } from '../contracts/response-models/invoice'

import {User} from '../contracts/response-models/user'
import {getUserInfoService} from "../machines/listing-machine/services"

import logo from "../assets/img/logo/zaglist-logo.png"
import "./custom.css";

import {
    Row,    
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Col,
    Input,  
    Media,  
    Table,
    CustomInput,
    Form,
    Button,
    Spinner
  } from "reactstrap"
  
  import "../assets/scss/pages/users.scss"
import { getInvoiceNumber } from "../utils/invoice-number";
import { setDatePickerFormat2 } from "../utils/date-helper";
import { PaymentType } from "../contracts/enums/payment-type";
export const InvoiceDetail = () => {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<Invoice>();
  const [user, setUser] = useState<User>({userId:0});
  const paymentOptions = [
    { index: 1, value: "Listing"},
    { index: 2, value: "Featured Listing" },
    { index: 3, value: "Featured Agent" }, 
  ]
  useEffect(() => {
    async function loadInvoiceInfo() {
    setLoading(true);
    if (id > 0) {
      const invoiceinfo = await getInvoiceInfoService(id);  
      setInvoice(invoiceinfo);  

    }
    const userinfo = await getUserInfoService();  
    setUser(userinfo);
    setLoading(false);
  }
  loadInvoiceInfo();

  }, []);
  let { id } = useParams();

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Invoice Detail" breadCrumbParentLink="/invoices"  breadCrumbParent="Invoices" breadCrumbActive="Invoice Detail" />
            <Card>             
       
        <CardHeader>
                <CardTitle>Invoice Detail</CardTitle>
              </CardHeader>
              <CardBody>
              {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
            <Row className="invoice-detail-page">
      
            <Col className="invoice-wrapper" sm="12">
              <Card className="invoice-page">
                <CardBody>
                  <Row>
                    <Col md="6" sm="12" className="pt-1">
                      <Media className="pt-1">
                        <img src={logo} alt="logo" />
                      </Media>
                     
                    </Col>
                    <Col md="6" sm="12" className="text-right">
                      <h1>Invoice</h1>
                      <div className="invoice-details mt-1">
                        <h6>INVOICE NO.</h6>
                        <p>{getInvoiceNumber(invoice?.id)}</p>
                        <h6 >INVOICE DATE</h6>
                        <p>{setDatePickerFormat2(invoice?.invoiceDate)}</p>
                        <h5>Zaglist.com</h5>
                        <p> 
                          support@zaglist.com
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row  >
                    <Col md="6" sm="12">
                      <h5></h5>
                      <div className="recipient-info my-2">
                        <p>{invoice?.firstName} {invoice?.lastName}</p>
                        <p> {user?.companyName} <br/>
                               {invoice?.streetAddress}<br/>
                                {invoice?.city}, {invoice?.stateCD} {invoice?.zip}<br/>
                               </p>
                        
                      </div>
                      <div className="recipient-contact pb-2">
                        <p>
                         
                          {user.userName}
                        </p>
                        <p> 
                         {user.cellno}
                        </p>
                      </div>
                    </Col>
                    <Col md="6" sm="12" className="text-right">
                    
                    </Col>
                  </Row>
                  <div className="invoice-items-table pt-1">
                    <Row>
                      <Col sm="12">
                        <Table responsive borderless>
                          <thead>
                            <tr>
                              <th>Payment Ref</th>
                              {invoice?.propertyAddress!=null && (
                              <th>Property Address</th>
                              )}
                              <th>Payment Type</th>
                              <th className="text-center" >Days</th>
                               
                              <th className="text-right" >Amount</th>
                            </tr>
                          </thead>
                          <tbody>
            <tr>
              <td>{invoice?.paymentReferece}</td>
              {invoice?.propertyAddress!=null && (
              <td>
                  {invoice?.propertyAddress!=undefined && (
                    <>
                      {invoice?.propertyAddress.street}, {invoice.propertyAddress.areaTitle}, {invoice.propertyAddress.stateCD} {invoice.propertyAddress.zipCode}
          </>)}
               </td>
              )}
              <td>
                  {invoice?.paymentType!=undefined && invoice?.paymentType==PaymentType.FeaturedAgentPayment && ("Featured Agent")}
                  {invoice?.paymentType!=undefined && invoice?.paymentType==PaymentType.FeaturedListingPayment && ("Featured Listing")}
                  {invoice?.paymentType!=undefined && invoice?.paymentType==PaymentType.ListingPayment && ("Listing")}
                  {invoice?.paymentType!=undefined && invoice?.paymentType==PaymentType.RenewListingPayment && ("Renewal Listing")}
                  {invoice?.paymentType!=undefined && invoice?.paymentType==PaymentType.ExtendListingPayment && ("Extend Listing")}
                  </td>
                  
              <td className="text-center">{invoice?.days}</td>
              
              <td className="text-right">${invoice?.amount.toFixed(2)}</td>
            </tr>
            
                        
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  <div className="invoice-total-table">
                    <Row>
                    <Col sm="12">
                        <Table responsive borderless>
                          <tbody>
                            
                            <tr>
                              <td></td>
                               
                              <td  className="text-right" ><b>TOTAL : ${invoice?.amount.toFixed(2)}</b></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
               
                </CardBody>
              </Card>
            </Col>
          </Row>
                
                )}
              </CardBody>
           
           
            </Card>
        </React.Fragment>
    )
}
export default InvoiceDetail;