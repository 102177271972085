import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import { setDecimalValue } from "../../../../utils/display-currency";
import NumberFormat from 'react-number-format';

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAcres = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleAcresChange = (e:any) => {     
        setProperty({...property,availavleAcres:e.target.value}); 
      }
      
        return (     
            <> 
                         <Col md="3" sm="12">
                <div className="d-inline mr-1">Offered Acres<br/></div>  
                <NumberFormat value={property.availavleAcres}  
                onChange={handleAcresChange}
                thousandSeparator={true}
                decimalScale={2}
                className={classnames("mr-1 form-control",{
                    invalid: (property.availavleAcres == undefined)
                    })}
                  />

      
              
            </Col> 
          
        
         </>
    )
}
    