import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import Checkbox from "../../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../../contracts/response-models/property'

import {listAmenitiesService} from "../../../../machines/listing-machine/services"
import { Aminity } from "../../../../contracts/response-models/aminity";
import { RentIncludes } from "../../../../contracts/response-models/rent-includes";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
 
export const PropertyRentFeatures = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    var rentOptions:RentIncludes[] = [ 
        { id: 1, title: "Heating"},
        { id: 2, title: "Cooling"},
        { id: 3, title: "Electricity"},
        { id: 4, title: "Comman Area"},
        { id: 5, title: "Tax"},
        { id: 6, title: "Janitor"},
        { id: 7, title: "Garbage Disposal"}
    ]   
    const  handleRentIncludesChange = (e:any) => {  
       
        var emptyArr = new Array<RentIncludes>()
        var arrRentIncludes = property.rentincludes == undefined ? emptyArr : property.rentincludes;
 
        const selectedRentIncludes = rentOptions.find(element => element.id== parseInt(e.target.value)) ;
        if(e.target.checked && selectedRentIncludes){  
          
            arrRentIncludes.push(selectedRentIncludes);
             setProperty({...property,rentincludes:arrRentIncludes});
         }
         else{     
                
             const found = arrRentIncludes.find(element => element.id==selectedRentIncludes?.id);          
             if(found)     {
                 const foundIndex=arrRentIncludes.indexOf(found,0);
                 arrRentIncludes.splice(foundIndex,1);
                 setProperty({...property,rentincludes:arrRentIncludes});              
             }          
         }
      }
      const SetDefaultChecked=(am:RentIncludes)=>{        
        var arrRentIncludes =  property.rentincludes;  
       if(arrRentIncludes!=undefined){    
            const found = property.rentincludes?.find(element => element.id==am.id);                   
            return found!=undefined;      
        } 
       
        return false;
      } 
        return (     
            <>        
           <Row className="mt-2">     
           <Col md="12" sm="12">
        <h4 className="mb-3 mt-2 text-center">RENT INCLUDES</h4>
               </Col>
           {rentOptions.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleRentIncludesChange} icon={<Check className="vx-icon" size={16} />} label={object.title} defaultChecked={SetDefaultChecked(object)} />
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    