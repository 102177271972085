import React, { useState, useRef, useEffect } from "react";

import { Button, Row, Col, Spinner } from "reactstrap"

import DashboardCard from "../../components/dashboard/admin/dashboard-card";
import { Revenue } from "../../contracts/response-models/revenue";
import { SiteSummary } from "../../contracts/response-models/site-summary";
import { getRevenueService, getSiteSummaryService } from "../../machines/listing-machine/services";

import { displayCurrency } from "../../utils/display-currency";

export const AdminDashboardPage = () => {

  const [loading, setLoading] = useState(false);
  const [siteSummary, setSiteSummary] = useState<SiteSummary>();
  const [revenue, setRevenue] = useState<Revenue[]>([
    {
      year: 2020,
      amount: 12341,
    },
    {
      year: 2019,
      amount: 543,
    },
    {
      year: 2018,
      amount: 23541,
    }

  ]);

  useEffect(() => {
    async function loadDashboardSummary() {
      setLoading(true);
      var response = await getSiteSummaryService();
      setSiteSummary(response);

       var revenue = await getRevenueService();
       setRevenue(revenue);

      setLoading(false);
    }
    loadDashboardSummary();

  }, []);

  return (
    <React.Fragment>

      <Row>
        <Col lg={12} md={12} className="mt-2">

          <h4 className="card-title">Dashboard</h4>

        </Col>

        {loading ? (<div className="d-flex justify-content-between mt-1">
          <Spinner size="lg" className="primary float-right" />
        </div>) : (<>
          <Col lg={12} md={12} sm={12}>
            <div className="card-statistics card">
              <div className="card-header">
                <h4 className="card-title">Listings for Sale</h4>
                <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
              </div>
              <div className="statistics-body card-body">
                <Row>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleSFH != undefined && (
                      <DashboardCard title="SFH"   listingCount={siteSummary?.forSaleSFH} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleSFH != undefined && (
                      <DashboardCard title="Condo" listingCount={siteSummary?.forSaleCondo} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleCoop != undefined && (
                      <DashboardCard title="Co-op" listingCount={siteSummary?.forSaleCoop} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleApartment != undefined && (
                      <DashboardCard title="Apartment" listingCount={siteSummary?.forSaleApartment} />
                    )}
                  </Col>

                </Row>

                <Row>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forRentTownhome != undefined && (
                      <DashboardCard title="Townhome" listingCount={siteSummary?.forRentTownhome} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleMultiFamilty != undefined && (
                      <DashboardCard title="Multi Family" listingCount={siteSummary?.forSaleMultiFamilty} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleLand != undefined && (
                      <DashboardCard title="Land" listingCount={siteSummary?.forSaleLand} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forSaleCommercial != undefined && (
                      <DashboardCard title="Commercial" listingCount={siteSummary?.forSaleCommercial} />
                    )}
                  </Col>

                </Row>
              </div>
            </div>

          </Col>

          <Col lg={12} md={12} sm={12}>
            <div className="card-statistics card">
              <div className="card-header">
                <h4 className="card-title">Listings for Rent</h4>
                <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
              </div>
              <div className="statistics-body card-body">
                <Row>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forRentSFH != undefined && (
                      <DashboardCard title="SFH" listingCount={siteSummary?.forRentSFH} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forRentApartment != undefined && (
                      <DashboardCard title="Apartment" listingCount={siteSummary?.forRentApartment} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forRentTownhome != undefined && (
                      <DashboardCard title="Townhome" listingCount={siteSummary?.forRentTownhome} />
                    )}
                  </Col>
                  <Col lg={3} md={6} sm={6} className="mb-2">
                    {siteSummary?.forRentCommercial != undefined && (
                      <DashboardCard title="Commercial" listingCount={siteSummary?.forRentCommercial} />
                    )}
                  </Col>
                </Row>


              </div>
            </div>

          </Col>

          <Col lg={12} md={12} sm={12}>
            <div className="card-statistics card">
              <div className="card-header">
                <h4 className="card-title">Agents</h4>
                <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
              </div>
              <div className="statistics-body card-body">
                <Row>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.totalAgents != undefined && (
                      <DashboardCard title="Total Registered Agents" listingCount={siteSummary?.totalAgents} />
                    )}
                  </Col>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.proAgents != undefined && (
                      <DashboardCard title="Pro" listingCount={siteSummary?.proAgents} />
                    )}
                  </Col>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.notProAgents != undefined && (
                      <DashboardCard title="Not Pro" listingCount={siteSummary?.notProAgents} />
                    )}
                  </Col>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.activeAgents != undefined && (
                      <DashboardCard title="Active" listingCount={siteSummary?.activeAgents} />
                    )}
                  </Col>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.suspendedAgents != undefined && (
                      <DashboardCard title="Suspended" listingCount={siteSummary?.suspendedAgents} />
                    )}
                  </Col>
                  <Col lg={4} md={6} sm={6} className="mb-2">
                    {siteSummary?.cancelledAgents != undefined && (
                      <DashboardCard title="Cancelled" listingCount={siteSummary?.cancelledAgents} />
                    )}
                  </Col>
                </Row>


              </div>
            </div>

          </Col>

          <Col lg={6} md={12} sm={12}>
            <div className="card-statistics card">
              <div className="card-header">
                <h4 className="card-title">Blog</h4>
                <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
              </div>
              <div className="statistics-body card-body">
                <Row>
                  <Col lg={6} md={6} sm={6} className="mb-2">
                    {siteSummary?.activePosts != undefined && (
                      <DashboardCard title="Active" listingCount={siteSummary?.activePosts} />
                    )}
                  </Col>
                  <Col lg={6} md={6} sm={6} className="mb-2">
                    {siteSummary?.suspendedPosts != undefined && (
                      <DashboardCard title="Suspended" listingCount={siteSummary?.suspendedPosts} />
                    )}
                  </Col>

                </Row>


              </div>
            </div>

          </Col>

          <Col lg={6} md={12} sm={12}>
            <div className="card-statistics card">
              <div className="card-header">
                <h4 className="card-title">Revenue</h4>
                <p className="card-text font-small-2 mr-25 mb-0 card-text"></p>
              </div>
              <div className="statistics-body card-body text-center revenue-container">
               
                    <Row>
                      <Col lg={2} md={12} sm={12} className="w-50    revenue-border revenue-top-border"  >
                     <b> Year</b>
                 </Col>
                      <Col lg={4} md={12} sm={12} className="w-50 revenue-border revenue-top-border"  >
                      <b>  Amout</b>
                      </Col>
                    </Row>
                
                  {
                    revenue?.map(function (revenueInfo, i) {
                      return (
                       
                          <Row>
                            <Col lg={2} md={12} sm={12} className="w-50    revenue-border"  >
                              {revenueInfo.year} 
                     </Col>
                            <Col lg={4} md={12} sm={12} className="w-50 text-right revenue-border"  >
                              ${displayCurrency(revenueInfo.amount)}
                            </Col>
                          </Row>
                        
                      )
                    })
                  }
                    

              </div>
            </div>

          </Col>

        </>)}
      </Row>
    </React.Fragment>
  )
}

export default AdminDashboardPage;