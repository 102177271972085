import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,

} from "reactstrap"

import { Property } from '../../contracts/response-models/property'
import { OpenHouse } from '../../contracts/response-models/openhouse'

import {setDatePickerFormat} from '../../utils/date-helper'
import { saveOpenHouseService,DeleteOpenHouseService } from '../../machines/listing-machine/services'

import Select from "react-select"
import { ValidationMessage } from "../@vuexy/validation-message";
import { ToastContainer, toast } from "react-toastify";

import {PropertyHeadInfo} from './property-head-info-modal';
import { Redirect } from "react-router-dom";


interface PropertyIdProps {
  property?: Property,
  toggle: any;
  readyState:any;
  setReadyState:any;
}


export const PropertyOHModal = ({ property, toggle,readyState,setReadyState }: PropertyIdProps) => {
  const [isSavedSuccess, setSavedSuccess] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [openhouse, setOpenHouse] = useState<OpenHouse>(
    (property?.propertyOH != undefined ? property?.propertyOH : {
      ohStatus:0,      
    })
    );

    const [currentOpenhouse, setCurrentOpenHouse] = useState<OpenHouse>(
      (property?.propertyOH != undefined ? property?.propertyOH : {
        ohStatus:0,      
      })
      );


  const timeOptions = [
    { index: 0, value: "8:00 AM", label: "8:00 AM" },
    { index: 1, value: "8:30 AM", label: "8:30 AM" },
    { index: 2, value: "9:00 AM", label: "9:00 AM" },
    { index: 3, value: "9:30 AM", label: "9:30 AM" },
    { index: 4, value: "10:00 AM", label: "10:00 AM" },
    { index: 5, value: "10:30 AM", label: "10:30 AM" },
    { index: 6, value: "11:00 AM", label: "11:00 AM" },
    { index: 7, value: "11:30 AM", label: "11:30 AM" },
    { index: 8, value: "12:00 PM", label: "12:00 PM" },
    { index: 9, value: "12:30 PM", label: "12:30 PM" },
    { index: 10, value: "1:00 PM", label: "1:00 PM" },
    { index: 11, value: "1:30 PM", label: "1:30 PM" },
    { index: 12, value: "2:00 PM", label: "2:00 PM" },
    { index: 13, value: "2:30 PM", label: "2:30 PM" },
    { index: 14, value: "3:00 PM", label: "3:00 PM" },
    { index: 15, value: "3:30 PM", label: "3:30 PM" },
    { index: 16, value: "4:00 PM", label: "4:00 PM" },
    { index: 17, value: "4:30 PM", label: "4:30 PM" },
    { index: 18, value: "5:00 PM", label: "5:00 PM" },
    { index: 19, value: "5:30 PM", label: "5:30 PM" },
    { index: 20, value: "6:00 PM", label: "6:00 PM" },
    { index: 21, value: "6:30 PM", label: "6:30 PM" },
    { index: 22, value: "7:00 PM", label: "7:00 PM" },
    { index: 23, value: "7:30 PM", label: "7:30 PM" },
    { index: 24, value: "8:00 PM", label: "8:00 PM" },
  ]
  const defaultOption = { label: "Select", value: "" }
  const [selectedStartTime, setSelectedStartTime] = React.useState(defaultOption);  
  const [selectedEndTime, setSelectedEndTime] = React.useState(defaultOption);

  const [indexStartTime, setIndexStartTime] = React.useState(0);
  const [indexEndTime, setIndexEndTime] = React.useState(0);

  useEffect(() => {
    async function loadTime() {
      setOpenHouse({ ...openhouse, propertyId: property?.id });

      const foundStartTime = openhouse.startTime != undefined && openhouse.startTime != "" ? timeOptions?.filter(option => option.value == openhouse.startTime)[0] : defaultOption;
      setSelectedStartTime(foundStartTime);

      const foundEndTime = openhouse.endTime != undefined && openhouse.endTime != "" ? timeOptions?.filter(option => option.value == openhouse.endTime)[0] : defaultOption;
      setSelectedEndTime(foundEndTime);

    }
    loadTime();
  }, []);

  const handleOhNoteChange = (e: any) => {
    setOpenHouse({ ...openhouse, ohNote: e.target.value });
  }
  const handleOhDateChange = (e: any) => {
    setOpenHouse({ ...openhouse, ohDate: e.target.value });
  }

  const handleStartTimeChange = (val: any) => {
    
    setSelectedStartTime(timeOptions?.filter(option => option.value == val.value)[0]);
    setOpenHouse({ ...openhouse, startTime: val.value });
    setIndexStartTime(val.index);
  }
  const handleEndTimeChange = (val: any) => {
    setSelectedEndTime(timeOptions?.filter(option => option.value == val.value)[0]);
    setOpenHouse({ ...openhouse, endTime: val.value });
    setIndexEndTime(val.index);
  }

  const saveOpenHouse = async (e: any) => {
    e.preventDefault()
    var isValidated = true;
    if (openhouse.ohDate == undefined || openhouse.ohDate == "") {
      setOpenHouse({ ...openhouse, ohDate: '' });
      isValidated = false
    }
    if (openhouse.startTime == undefined || openhouse.startTime == "") {
      setOpenHouse({ ...openhouse, startTime: '' });
      isValidated = false
    }
    if (openhouse.endTime == undefined || openhouse.endTime == "") {
      setOpenHouse({ ...openhouse, endTime: '' });
      isValidated = false
    }
    if(indexStartTime>indexEndTime)
    {
      isValidated = false
    }
    

    if (isValidated == true) {
      setLoading(true);

      const response = await saveOpenHouseService(openhouse, openhouse?.propertyId);
      toast.success("Schedule Open House Saved Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })

      setLoading(false);
      toggle();
      setReadyState(true); 
    }
  }

  const deleteOpenHouse = async (e: any) => {
    setLoading(true);
    const response = await DeleteOpenHouseService(openhouse?.propertyId);
    toast.success("Open House Removed Successfully", {
      position: toast.POSITION.TOP_RIGHT
    })
    toggle();
    setReadyState(true); 
    setLoading(false);
    
  }

  return (
    <>
      <ModalHeader toggle={toggle}  >
        Schedule Open House
          </ModalHeader>

      <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <> 
            

          <PropertyHeadInfo property={property}    />

          {currentOpenhouse.ohStatus!=0 &&(<>
              <Row className="mb-1 mt-1"> 
              <Col className="text-right align-middle" sm="12" md="4" lg="4"  ><p>Active Open House</p></Col>
              <Col className="text-center align-middle" sm="12" md="2" lg="2"  >{currentOpenhouse.ohDate}</Col>
              <Col className="text-center align-middle" sm="12" md="4" lg="4"  >{currentOpenhouse.startTime} - {currentOpenhouse.endTime}</Col>
              <Col className="align-middle" sm="12" md="2" lg="2"  ><span className="openHouseDeleteIcon"   onClick={(e)=> { deleteOpenHouse(e);}} title="Remove Open House" >x</span> </Col>
              </Row>
            </>)}
              <FormGroup>
                <Label for="address">Write a note here</Label>
                <Input
                  type="text"
                  id="note"
                  placeholder="note"
                  value={openhouse.ohNote}
                  onChange={handleOhNoteChange}
                />

              </FormGroup>

              <FormGroup>
                <Label for="password">Set Date and Time </Label>
                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">
                    <Input
                      type="date"
                      id="date"
                      placeholder="Date"
                      value={setDatePickerFormat(openhouse.ohDate)}
                      onChange={handleOhDateChange}
                    />
                    <ValidationMessage isInvalid={(openhouse.ohDate == undefined) || (openhouse.ohDate != '') ? false : true} errorMessage="Please select date" />

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Select
                      className="React text-center"
                      classNamePrefix="select"
                      value={selectedStartTime}
                      name="title"
                      onChange={handleStartTimeChange}
                      options={timeOptions}
                    />
                    <ValidationMessage isInvalid={(openhouse.startTime == undefined) || (openhouse.startTime != '') ? false : true} errorMessage="Please select start time" />

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Select
                      className="React text-center"
                      classNamePrefix="select"
                      value={selectedEndTime}
                      name="title"
                      onChange={handleEndTimeChange}
                      options={timeOptions}
                    />
                    <ValidationMessage isInvalid={(openhouse.endTime == undefined) || (openhouse.endTime != '') ? false : true} errorMessage="Please select end time" />

                  </Col> </Row>
                  <Row className="mb-1 mt-1" >
                  <Col md="12" sm="12" className="text-center">
                  <ValidationMessage isInvalid={(indexStartTime < indexEndTime) ? false : true} errorMessage="Schedule end time should be greater than start time." />
                  </Col>  </Row>
               

              </FormGroup>
              <FormGroup>

                <Row className="mb-2 mt-0" >
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveOpenHouse}

                    >
                      Save
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
            </>
          )}
      </ModalBody>

    </>
  )
}
