import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import { setDecimalValue } from "../../../../utils/display-currency";
import NumberFormat from 'react-number-format';


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertySmallestAcres = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleSmallestAcresChange = (e:any) => {  
        
        setProperty({...property,smallestAcres:e.target.value});
      }
      
      
        return (     
            <>
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Smallest Acres<br/></div>    
                <NumberFormat value={property.smallestAcres}  
                onChange={handleSmallestAcresChange}
                thousandSeparator={true}
                decimalScale={2}
                className={classnames("mr-1 form-control",{
                  //  invalid: (property.smallestAcres == undefined)
                    })}
                  />                  
                
            </Col> 

       
         </>
    )
}
    