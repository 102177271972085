import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHR = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
            <Row className="odd">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className="  w-50 mob_even  text-right pitem-rspace">
              Bedrooms :  
              </Col>
              <Col lg="4" md="6" sm="6"  className="font-weight-bold  w-50 mob_even pitem-lspace" > 
              {property?.noOfBedrooms}
              </Col>
              </Row> </Col>
              
              <Col lg="5" md="12" sm="6">
                 
              <Row    >
              <Col lg="6" md="6" sm="6"   className=" w-50 mob_odd   text-right">
              Basement  :
              </Col>
              <Col lg="6" md="6" sm="6"  className=" w-50 mob_odd font-weight-bold" > 
              {property?.basement}
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className=" w-50  mob_even  text-right">
              Pets Allowed?   :
              </Col>
              <Col lg="6" md="6" sm="6"  className=" w-50 mob_even font-weight-bold" > 
              {(property.isPetsAllowed!=undefined && property.isPetsAllowed==true) ? "Yes":"No" }
              
              </Col>
              </Row> 
              </Col>

                 </Row>
                 <Row className="even">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className=" w-50 mob_odd  text-right pitem-rspace">
              Full Baths   :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.noOfFullBaths}
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className=" w-50  mob_even  text-right">
              Heating Source  :
              </Col>
              <Col lg="6" md="6" sm="6"  className=" w-50 mob_even font-weight-bold" > 
              {property?.heatingSource}
              </Col>
              </Row>  </Col>
              <Col  md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className=" w-50    text-right">
              
              </Col>
              <Col md="6" sm="6"  className=" w-50 font-weight-bold" > 
             
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="odd">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className=" w-50  mob_odd text-right pitem-rspace">
              Half Baths  :
              </Col>
              <Col lg="4" md="6" sm="6"  className="w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className=" w-50 mob_even  text-right">
              Cooling Type :
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold" > 
              {property?.coolingType}
              </Col>
              </Row>  </Col>
              <Col lg="4" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className=" w-50  text-right">
              
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 font-weight-bold" > 
              </Col>
              </Row> 
              </Col>

                 </Row>

                 <Row className="even">
                 
                 <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="8" md="6" sm="6"   className=" w-50 mob_odd  text-right pitem-rspace">
              Garage  :
              </Col>
              <Col lg="4" md="6" sm="6"  className=" w-50 mob_odd font-weight-bold pitem-lspace" > 
              {property?.garage}
              </Col>
              </Row> </Col>
              <Col lg="5" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className=" mob_even  w-50  text-right">
              Year Built :
              </Col>
              <Col lg="6" md="6" sm="6"  className="mob_even w-50 font-weight-bold" > 
              {property?.yearBuilt}, {property.condition}
              </Col>
              </Row>  </Col>
              <Col lg="3" md="12" sm="6">
              <Row  >
              <Col lg="6" md="6" sm="6"   className="  w-50 text-right">
             
              </Col>
              <Col lg="6" md="6" sm="6"  className="w-50 font-weight-bold" > 
              
              </Col>
              </Row> 
              </Col>

                 </Row>
         </>
    )
}