import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import { setDecimalValue } from "../../../../utils/display-currency";
import NumberFormat from 'react-number-format';

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyTotalAcres = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleTotalAcresChange = (e:any) => {  
        setProperty({...property,totalAcres:e.target.value});
      }
      
       
        return (     
            <>

                        <Col md="3" sm="12">
                <div className="d-inline mr-1"> Total Acres<br/></div> 
                <NumberFormat value={property.totalAcres}  
                onChange={handleTotalAcresChange}
                thousandSeparator={true}
                decimalScale={2}
                className={classnames("mr-1 form-control",{
                  //  invalid: (property.totalAcres == undefined)
                    })}
                  />             
            </Col> 
         </>
    )
}
    