import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom"
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,   
    Button, 
    Label,
    Input,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Alert,
    Spinner
} from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import loginImg from "../assets/img/pages/login.png"
import { history } from "../history"
import "../assets/scss/pages/authentication.scss"
import { verifyEmailService } from "../machines/listing-machine/services";
import { ActionResponse } from "../contracts/response-models/action-response";
  
export const VerifyEmail = () => {  
    let { id } = useParams();

    const [loading,setLoading] = useState(false);
    const [verifyResponse,setVerifyResponse] = useState<ActionResponse>();
  
        useEffect(() => {    
            async function checkUserVerification() {
              setLoading(true);
                if(id){
                    const response = await verifyEmailService(id);  
                    setVerifyResponse(response);   
                   
                }
              setLoading(false); 
            }
            checkUserVerification();
        },[id]);
      

     

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

                    <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication login-card rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
                <img src={loginImg} alt="loginImg" className="loginImage" />
              </Col>
              <Col lg="6" md="12"   className="d-lg-block d-none text-center align-self-center px-1 py-0">
                <Card style={{background:'none'}}  className="verifyEmailBox" >
               
                
                  <CardBody className="mt-5"  >
                  {loading? (
              <div className="d-flex justify-content-between mt-1">                               
              <Spinner size="lg"  className="primary float-right"/>
            </div>
            ):(
              <>
                {verifyResponse?.success==false ?(verifyResponse?.error):(
                    < >
                  <h4 className="mb-1">Thank you</h4>
                  <p className="px-2 auth-title">
                  Your Account has been successfully activated, please <Link to={`/login`} className="cursor-pointer">login</Link> your account to continue.
                  </p></>
                  )}
                  </> 
                  )}
                  
                </CardBody>          
                 
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

                    </main>
                    </div>
                </div>
                </div>
            </div>
        </div>    
    );
}

export default VerifyEmail;