import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import NumberFormat from "react-number-format";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyLots = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleLotsChange = (e:any) => { 
        var value = e.target.value; 
        if(e.target.value>1000)
        {
            value = 1000;
        }      
        setProperty({...property,noofLots:value});
      }
      

        return (     
            <>
                                          <Col md="3" sm="12">
                <div className="d-inline mr-1">  # of Lots<br/></div>  
                <NumberFormat value={property.noofLots}  
                onChange={handleLotsChange}
                allowNegative={false}
                thousandSeparator={false} 
                decimalScale={0}
                className={classnames("mr-1 form-control",{
                  //  invalid: (property.noofLots == undefined)
                    })}
                  />               
                 
            </Col> 

          
         </>
    )
}
    