import React from "react";
import {
    Label,
    Row,
    Col,
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import { Property } from '../../../../contracts/response-models/property'
import NumberFormat from "react-number-format";

interface PropertyDetailsStepProps {
    property: Property,
    setProperty: any;
}
export const PropertyApproxSize = ({ property, setProperty }: PropertyDetailsStepProps) => {

    const handleapproxSizeChange = (e: any) => {
        var value = e.target.value;
        if (e.target.value > 999999) {
            value = 999999;
        } else if (e.target.value == '') {
            value = undefined;
        }
        setProperty({ ...property, approxSize: value });
    }


    return (
        <>
            <Col md="3" sm="12">
                <div className="d-inline mr-1">Approx Size (Sqft)</div>
                 
                <NumberFormat value={property.approxSize}
                    onChange={handleapproxSizeChange}
                    allowNegative={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    className={classnames("mr-1 form-control", {
                       // invalid: (property.approxSize == undefined)
                    })}
                />
            </Col>
        </>
    )
}
