import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap"
 
import { ValidationMessage } from "../components/@vuexy/validation-message"

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
 
import { cancelAccountService } from '../machines/listing-machine/services'

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
 
import "../components/Profile/css/edit-profile.css"
import SweetAlert from "react-bootstrap-sweetalert";

export interface PasswordInfo {
  currentPassword?:string;
  password?: string;
  confirmPassword?: string;
  isvalidate?:boolean;
}


export const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);   
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  
  const [reason, setReason] = useState<string>(); 

  const deleteAccount = async (e: any) => {
    e.preventDefault() 
    var isValidated = true; 

    if (reason == undefined || reason == "") {
        setReason('');
      isValidated = false
    }
   
    if (isValidated == true && reason!=undefined) { 
      setshowDeleteConfirm(true); 
    }

  }

  const handleConfirmDelete = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowDeleteConfirm(confirmRespons);
      return;
    } 
      setshowDeleteConfirm(false);
      setLoading(true);
      if(reason!=undefined)
      {
         
         
        const response = await cancelAccountService(reason); 
        if(response.success==true)
        { 
        toast.success("Account Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT
        })  
          localStorage.removeItem('__zaglist_token__'); 
          window.location.href="/login"; 
        }  
        
      } 
     
      setLoading(false);
      

  
  }
 

  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Delete My Account"   breadCrumbActive="Delete My Account" />
   <Card>
        <CardBody>
          <ToastContainer />

          {!loading ? (
            <Row className="mt-1">
              <Col   md="8" sm="12">
          <h3 className="mb-2 text-left">Delete My Account</h3>
          <p>You are about to delete your account. You will no longer be able to access the account and all data including any listing information will be lost. Do you want to proceed?</p>
              <Form>
                <FormGroup row>
                    <Col md="12">
                      <span>Please let us know the reason.</span>
                    </Col>
                    <Col md="12">

                    <textarea
                                name="reason"
                                className="form-control"
                                value={reason} 
                                onChange={(e) => {
                                  setReason( e.target.value)

                                }}
                              ></textarea>
                   <ValidationMessage isInvalid={(reason == undefined) || (reason != '') ? false : true} errorMessage="Please type reason to delete account" />
                       </Col>
                  </FormGroup> 
                  

                  <FormGroup row><Col md="12" className="mb-2 ml-5"></Col>
                    <Col md={{ size: 8, offset: 4 }}>

                      <Button
                        color="primary"
                        type="submit"
                        className="mr-1 mb-1"
                        onClick={deleteAccount}
                      >
                        Delete Account
                </Button>
                     
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          ) : (
              <div className="d-flex justify-content-between mt-1">
                <Spinner size="lg" className="primary float-right" />
              </div>
            )}
        </CardBody>
      </Card>
      <SweetAlert title="Delete the Account?"
        warning
        show={showDeleteConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="No"
        onConfirm={() => handleConfirmDelete(true)}
        onCancel={() => handleConfirmDelete(false)}
      >
        You won't be able to revert this!
        </SweetAlert>
    </React.Fragment>
  )
}
export default DeleteAccount;