import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../../../contracts/enums'
import { displayCurrency } from "../../../../utils/display-currency";
interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyMaintenance = ({ property, setProperty}:PricingStepProps) => {   
    
    const handleMaintenanceChange = (e:any) =>{
      var price = Number(e.target.value?.replace(/,/g, ''));     
      if(isNaN(price)!==false)
        {
         // setProperty({...property,maintenance:undefined});         
        }else{          
          setProperty({...property,maintenance:e.target.value});         
        }
       
      }
        return (     
            <>
            <div className="form-label-group mt-2">
            <div className="d-inline mr-1">Maintenance</div>
          <Input           
             placeholder="Maintenance Charges"        
            value={(property.maintenance == undefined ? "" : displayCurrency(property.maintenance))}
            className={classnames("form-control",{
              invalid: (property.maintenance == undefined  || property.maintenance.toString()=="")
            })}
            mobile                   
            onChange={handleMaintenanceChange}
          />   
         </div>
          </>
    )
}
    