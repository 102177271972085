import React, { useState, useRef } from "react";
import { Users, TrendingUp, Twitch, BarChart2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Row, Col, Spinner } from "reactstrap"
import { displayCurrency } from "../../../utils/display-currency";

interface DashboardProps {
    title: string;
    listingCount: number;
    icon?:string;
}
export const DashboardCard = ({ title, listingCount,icon }: DashboardProps) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className="media">
            <div className="avatar mr-2 bg-light-primary">
                <span className="avatar-content">
                    {icon=="BarChart2" &&(
                    <BarChart2 size="24" />
                    )}
                    {icon==undefined &&(
                    <TrendingUp size="24" />
                    )}
                </span>
            </div>
            <div className="my-auto media-body">
                <h4 className="font-weight-bolder mb-0">{displayCurrency( listingCount)}</h4>
                <p className="font-small-3 mb-0 card-text">{title}</p>
            </div>
        </div>
    )
}

export default DashboardCard;