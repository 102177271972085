import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap" 
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import NumberFormat from "react-number-format";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyFullBath = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleFullBathsChange = (e:any) =>{
      var value = e.target.value;
      if (value.length>2) {
        value = property.noOfFullBaths;
        } else if (e.target.value == '') {
            value = undefined;
        }
        setProperty({...property,noOfFullBaths:value});
      } 
        return (     
            <>         
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Full Baths</div>
                 
                 <NumberFormat value={property.noOfFullBaths}
                    onChange={handleFullBathsChange}
                    allowNegative={false}
                    thousandSeparator={false}
                    decimalScale={0}
                    className={classnames("mr-1 form-control", {
                        invalid: (property.noOfFullBaths == undefined)
                    })}
                />
            </Col> 
         </>
    )
}
    