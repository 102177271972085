import React,{useState,useEffect} from "react";
 
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import {Property} from '../../contracts/response-models/property'
import { PropertyHeadInfo } from "./property-head-info-modal";

 
import { ToastContainer, toast } from "react-toastify";

import { saveExtendListingService, getUserInfoService, getStripeClientSecretFeaturedervice, createInvoiceService } from '../../machines/listing-machine/services'

import { StripePromise } from "../../utils/loadStripe";

 
import { StripePayment } from "../Payment/StripePayment";
import { User } from "../../contracts/response-models/user";
import { StripeSecret } from "../../contracts/response-models/stripe-client-secret";
import { PaymentType } from "../../contracts/enums/payment-type";
import Select from "react-select"
import { ValidationMessage } from "../@vuexy/validation-message";
import { PaymentInfo } from "../../contracts/request-models/payment-info";
import { BillingInfo } from "../Payment/billing-info";
 

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
  setReadyState:any;
}


export const StripeFeaturedListingForm = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
  const stripePromise = StripePromise();

  const stripe = useStripe();
  const elements = useElements(); 

  const [user, setUser] = useState<User>({ userId: 0 }); 
  const [buttonText, setButtonText] = useState('Save');  
  const[paymentError,setPaymentError]=useState('');
  const[selectedDays,setSelectedDays]=useState('');
  const [paymentloading, setPaymentLoading] = useState(false);
  const [payInfo, setPayInfo] = useState<PaymentInfo>({
    name:"",
    address:"",
    city:"",
   
  });
  const dayOptions = [
    { index: 0, value: "30", label: "30 Days" },
    { index: 1, value: "60", label: "60 Days" },
    { index: 2, value: "90", label: "90 Days" },   
  ]

  const [stripeSecret, setStripeSecret] = useState<StripeSecret>(
    {
      secret: '',
      amount: 0,
      days:0,
      discount:0.0,
    }
  );
  
  const [loading, setLoading] = useState(false);
  const [featuredListing, setFeaturedListing] = useState(
    { endDate:''}
  );

  useEffect(() => {
    async function setUserInfo() {
      setLoading(true);      
      const userinfo = await getUserInfoService();
      setUser(userinfo);
      setLoading(false);
    
    }

    setUserInfo();
  }, [user.userName]);

  useEffect(() => {
    async function setBillingInfo() {
      setPayInfo({
        name:user?.firstName +" "+user?.lastName,
        address:user?.streetAddress,
        city:user?.city,
      })  
    
    }

    setBillingInfo();
  }, [user?.userName]);
  
  
  const handleExpirationDayChange = async (val: any) => {
    setLoading(true);  
    let days = val.value;
    setSelectedDays(days);
  
   const striperesponse = await getStripeClientSecretFeaturedervice(days,PaymentType.FeaturedListingPayment,user.userName);
   setStripeSecret(striperesponse);
   setButtonText('Pay Now');
   setLoading(false);
  }

  const saveFeaturedListing = async (e: any) => {
    e.preventDefault()

    if(paymentloading)
      return;
      
    setPaymentError('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    
     const client_secret = stripeSecret?.secret;
    
     const card = elements.getElement(CardElement);
     if(!card){
       return;
     }
     var isValidated = true;
     if(payInfo.name=="" || payInfo.name==undefined)
     {
      setPayInfo({ ...payInfo, name: '' });
      isValidated = false;
      return;
     }
     if(payInfo.address=="" || payInfo.address==undefined)
     {
      setPayInfo({ ...payInfo, address: '' });
      isValidated = false;
      return;
     }
     if(payInfo.city=="" || payInfo.city==undefined)
     {
      setPayInfo({ ...payInfo, city: '' });
      isValidated = false;
      return;
     }

    setPaymentLoading(true);
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: card,        
        billing_details: {
          name: payInfo?.name,
          address:{
            line1:payInfo?.address,
            city: payInfo?.city,
            country: 'US'
          },         

        },
      }
    });
    if(result)
    {
      setPaymentLoading(false);
    }
    if (result.error) {
      if(result.error.message!=undefined)
      {
        setPaymentError(result.error.message);
      }  
       
      //console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result?.paymentIntent?.status !== undefined) {
        if (result.paymentIntent.status === 'succeeded') {
          setLoading(true);
          
          const invoicesaveresponse = createInvoiceService(PaymentType.FeaturedListingPayment,stripeSecret.days,property?.id,result.paymentIntent.id,result.paymentIntent.amount,'');
          
          toast.success("Payment Processed Successfully", {
            position: toast.POSITION.TOP_RIGHT
          })
          
          setLoading(false);
          toggle();
          setReadyState(true);  
        }
      }

    }    
   
  }
        return (     
         
            <>         

          
                <PropertyHeadInfo property={property}    />

            
 
              <FormGroup>
                <Label for="address">Days</Label>
                
                    <Select
                      className="React text-center"
                      classNamePrefix="select"                      
                      name="title"
                      onChange={handleExpirationDayChange}
                      options={dayOptions}
                    />
                    <ValidationMessage isInvalid={(selectedDays == undefined) || (selectedDays != '') ? false : true} errorMessage="Please select days for featured listing" />


              </FormGroup>
              {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
          <>
              {buttonText=="Save" ? (
                <>
        
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveFeaturedListing}                        
                    >
                      {buttonText}
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
              </>
              ):(
                <>
        
                  <FormGroup>
          <Label for="nameVertical">Amount : </Label>  ${(stripeSecret?.amount/100).toFixed(2)} {stripeSecret?.discount>0 &&( <>( ${(stripeSecret?.discount/100).toFixed(2)} discount )</> ) }


        </FormGroup>
        <BillingInfo payInfo={payInfo} setPayInfo={setPayInfo} />
       
        <StripePayment error={paymentError} />
             
                      <FormGroup>
        
                        <Row className="mb-2 mt-0">
                          <Col md="4" sm="6" className="text-right">
        
                          </Col>
                          <Col md="4" sm="6" className="text-right">
                          { paymentloading ?   (        
      
      <Button
      color="primary"
      type="submit"
      className="mr-1 mb-1"                              
    >
      Processing...
</Button>
     ):( 
                            <Button
                              color="primary"
                              type="submit"
                              className="mr-1 mb-1"
                              onClick={saveFeaturedListing}                        
                            >
                              {buttonText}
                        </Button>)}
                          </Col>
                          <Col md="4" sm="6" className="text-right">
                          </Col>
                        </Row>
        
                      </FormGroup>
                      </>
              )
} </>
        )}
                     
         </>
    )
}
    