import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import CurrencyInput from 'react-currency-input-field';

import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../../../contracts/enums'
import { displayCurrency } from "../../../../utils/display-currency";
interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyListPrice = ({ property, setProperty}:PricingStepProps) => {   
    
    const handleListPriceChange = (e:any) =>{
      if(isNaN(e.target.value)!==false)
        {
          setProperty({...property,listPrice:undefined});         
        }else{          
          setProperty({...property,listPrice:e.target.value});         
        }
       
      }
    

        return (     
            <>    
                <div className="form-label-group mt-2">

                {property.saleType==SaleType.ForSale && 
                  ( <> 
                     <div className="d-inline mr-1">Asking Price</div>
                <Input           
                   placeholder="Asking Price"        
                  value={(property.listPrice == undefined ? "" : displayCurrency(property.listPrice))}
                  className={classnames("form-control",{
                    invalid: (property.listPrice == undefined || property.listPrice.toString()=="")
                  })}
                                   
                  onChange={
                     (e) => {         
                      var price = Number(e.target.value?.replace(/,/g, ''));                      
                      if(isNaN(price)!==false)
                      {
                       // setProperty({...property,listPrice:undefined});      
                      }else
                      {
                        setProperty({...property,listPrice:price});  
                      }                   
                           
                  }
                }
                />
               
             
                </>
                  )
                }
                {property.saleType==SaleType.ForRent && 
                  (
                    <>
                      
                      <div className="d-inline mr-1">Rent</div>
                      <Input           
                   placeholder="Rent"        
                  value={(property.listPrice == undefined ? "" : displayCurrency(property.listPrice))}
                  className={classnames("form-control",{
                    invalid: (property.listPrice == undefined || property.listPrice.toString()=="")
                  })}
                                   
                  onChange={
                     (e) => {                       
                      var price = Number(e.target.value?.replace(/,/g, ''));                      
                      if(isNaN(price)!==false)
                      {
                        //setProperty({...property,listPrice:undefined});      
                      }else
                      {
                        setProperty({...property,listPrice:price});  
                      }                   
                           
                  }
                }
                />
                   
                    </>
                  )
                  }

              </div>

                 
         </>
    )
}
    