import React, { useState, useRef, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Label,
    Form,
    Nav,    
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
  } from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import { history } from "../history"

import loginImg from "../assets/img/pages/login.png"
import "../assets/scss/pages/authentication.scss" 
import {editUserService, getUserInfoService, listStatesService, registerUserService} from '../machines/listing-machine/services'
import { ValidationMessage } from "../components/@vuexy/validation-message"; 
import PhoneInput from "react-phone-input-2";
import "../components/Register/css/custom-register.css"
import Select from "react-select"
import { User } from "../contracts/response-models/user";
import { toast, ToastContainer } from "react-toastify";
import { ActionResponse } from "../contracts/response-models/action-response";
import { frontUrl } from "../utils/front-url";

  interface CellInfo{
    cell1:any,
    cell2:any,
    cell3:any,
  }
export const CompleteProfilePage = () => {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>({userId:0});
  const [cellInfo,setCellInfo]=useState<CellInfo>({ cell1:'',cell2:'',cell3:''});
  const [telInfo,setTelInfo]=useState<CellInfo>({ cell1:'',cell2:'',cell3:''});
  const [validationMessage, setValidationMessage] = useState<string>();
  const [responseMessage, setResponseMessage] = useState<ActionResponse>();
  const defaultOption = { label: "Select", value: "" }
  const [selectedState, setSelectedState] = React.useState(defaultOption);
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);

  useEffect(() => {    
    async function loadStats() {
        const userinfo = await getUserInfoService(); 
        setUser(userinfo);
        if(userinfo.cellno!=undefined)
        {
          var cellArray = userinfo.cellno.split("-");  
          setCellInfo({...cellInfo,
            cell1:cellArray[0],
            cell2:cellArray[1],
            cell3:cellArray[2],
          })
        }else
        {
          setCellInfo({...cellInfo,
            cell1:'',
            cell2:'',
            cell3:'',
          })
        }
        if(userinfo.tel!=undefined)
        {
          var cellArray = userinfo.tel.split("-"); 
          setTelInfo({...telInfo,
            cell1:cellArray[0],
            cell2:cellArray[1],
            cell3:cellArray[2],
          })
        }
        
        
        const states = await listStatesService();    
        const stateOptions = states.map((state) => ({ label:state.stateTitle , value: state.stateCD }))
        setStateItems(stateOptions);

        
       
    }
    loadStats();
  },[]);

  let setCellNo = async (cell1:string,cell2:string,cell3:string) => {
    var cell1Value,cell2Value,cell3Value;
      if(cell1!=undefined)
      {
        cell1Value = cell1.toString();
      }
      if(cell2!=undefined)
      {
        cell2Value = cell2.toString();
      }
      if(cell3!=undefined)
      {
        cell3Value = cell3.toString();
      }
       
    var CellNo=cell1Value+"-"+cell2Value+"-"+cell3Value; 
    setUser({ ...user, 
        title: '', 
        website:'',
        userPhotoFileName:'',
        cellno: CellNo
        
     });
  }

  let setTelNo = async (tel1:string,tel2:string,tel3:string) => {
    var tel1Value,tel2Value,tel3Value;
      if(tel1!=undefined)
      {
        tel1Value = tel1.toString();
      }
      if(tel2!=undefined)
      {
        tel2Value = tel2.toString();
      }
      if(tel3!=undefined)
      {
        tel3Value = tel3.toString();
      }
    var telNo=tel1Value+"-"+tel2Value+"-"+tel3Value; 
    setUser({ ...user,  
        tel: telNo 
     });
  }
  
  const handleZipChange = (e: any) => {

    if (isNaN(e.target.value) !== true) {
      setUser({ ...user, zip: e.target.value }) 
    } 
  }
    let handleSaveInforamtion = async (e:any) => {
        
        e.preventDefault()   
        var isValidated = true; 
         
        if (user?.tel == undefined || user.tel == "") {
          setUser({ ...user, tel: '' });
          isValidated = false
        }
        if (user?.zip == undefined || user.zip == "") {
          setUser({ ...user, zip: '' });
          isValidated = false
        }
        if (user?.stateCD == undefined || user.stateCD == "") {
          setUser({ ...user, stateCD: '' });
          isValidated = false
        }
        if (user?.city == undefined || user.city == "") {
          setUser({ ...user, city: '' });
          isValidated = false
        }
        if (user?.streetAddress == undefined || user.streetAddress == "") {
          setUser({ ...user, streetAddress: '' });
          isValidated = false
        }
        if (user?.companyName == undefined || user.companyName == "") {
          setUser({ ...user, companyName: '' });
          isValidated = false
        } 
         
          
        if (isValidated == true && user!=undefined) {
        setLoading(true); 
        const response = await editUserService(user,0); 
        setResponseMessage(response);
        if(response.success==true)
        {
          toast.success("Profile Updated Successfully", {              
            position: toast.POSITION.TOP_RIGHT
          })   
          window.location.href="/";
        } 
       

        setLoading(false);
        }
    }

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

        <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
               
                <a href={frontUrl} ><img className="loginImage" src={loginImg} alt="Zaglist.com" /></a>
                       
              </Col>
              <Col lg="6" md="12" className="p-0">
                <Card className="rounded-0 mb-0 p-2">
                  <CardHeader className="pb-1 pt-50">
                    <CardTitle>
                      <h4 className="mb-0">COMPLETE YOUR PROFILE</h4>
                    </CardTitle>
                  </CardHeader> 
                  <CardBody className="pt-1 pb-50">
                  <ToastContainer />
      
                  {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
                  <Form action="/" onSubmit={handleSaveInforamtion}>  
                  {responseMessage?.success==false && (<>
                    <div className="auth-title mb-2 alert alert-danger">
                                  <strong>Error: </strong> {responseMessage.error}</div>
                  </>
                    )}
                                         <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Company Name"
                            value={user?.companyName}    
                            onChange={(e)=> { setUser({ ...user, companyName: e.target.value })}}                        
                        />
                        <Label>Company Name</Label>
                        <ValidationMessage isInvalid={(user?.companyName == undefined) || (user.companyName != '') ? false : true} errorMessage="Please enter company name" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Street Address"
                            value={user?.streetAddress}    
                            onChange={(e)=> { setUser({ ...user, streetAddress: e.target.value })}}                        
                        />
                        <Label>Street Address</Label>
                        <ValidationMessage isInvalid={(user?.streetAddress == undefined) || (user.streetAddress != '') ? false : true} errorMessage="Please enter street address" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="City"
                            value={user?.city}    
                            onChange={(e)=> { setUser({ ...user, city: e.target.value })}}                        
                        />
                        <Label>City</Label>
                        <ValidationMessage isInvalid={(user?.city == undefined) || (user.city != '') ? false : true} errorMessage="Please enter city" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Select    
                id="ddlState"        
                className="React"
                classNamePrefix="select" 
                defaultValue={selectedState}   
                options={stateItems}    
                value={selectedState} 
                onChange={(val:any)=> { setSelectedState(stateItems.filter(option => option.value==val.value)[0]);
                  setUser({...user,stateCD:val.value});}}
                />
                         
                        <Label>State</Label>
                        <ValidationMessage isInvalid={(user?.stateCD == undefined) || (user.stateCD != '') ? false : true} errorMessage="Please select state" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            name="zip"
                            id="zip"
    
                            placeholder="Zip"
                            value={user?.zip}
                            onChange={handleZipChange}
                            maxLength={5}                      
                        />
                        <Label>Zip</Label>
                        <ValidationMessage isInvalid={(user?.zip == undefined) || (user.zip != '') ? false : true} errorMessage="Please enter zipcode" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group1 show_label">
                        <Label>Office Tel</Label> 
                        <div className="cellContainer">
                     <Input
                            type="text"
                            placeholder=""
                            maxLength={3}
                            id="tel1"
                            className="cellWidth1"
                            value={telInfo.cell1}  
                            onChange={(e: any) => {

                              if (isNaN(e.target.value) !== true) {
                                setTelInfo({ ...telInfo, cell1: e.target.value })
                                  setTelNo(e.target.value.toString(), telInfo.cell2, telInfo.cell3);
      
                              }
                              var length = e.target.value.toString().length;
                              if (length == 3 || length > 3) {
                                  document.getElementById('tel2')?.focus();
                              }
      
      
                          }}
                                
                                        
                        /> <span>-</span> 
                        <Input
                            type="text"
                            placeholder=""
                            maxLength={3}
                            id="tel2"
                            className="cellWidth1"
                            value={telInfo.cell2}  
                           
                            onChange={(e: any) => {
                              if (isNaN(e.target.value) !== true) {
                                setTelInfo({ ...telInfo, cell2: e.target.value })
                                setTelNo(telInfo.cell1, e.target.value.toString(), telInfo.cell3);
                              }
                              var length = e.target.value.toString().length;
                              if (length == 3 || length > 3) {
                                  document.getElementById('tel3')?.focus();
                              }
      
                          }}
                             
                                       
                        /> <span>-</span> 
                        <Input
                            type="text"
                            placeholder=""
                            maxLength={4}
                            id="tel3"
                            className="cellWidth2"
                            value={telInfo.cell3}  
                            
                            onChange={(e: any) => {
                              if (isNaN(e.target.value) !== true) {
                                setTelInfo({ ...telInfo, cell3: e.target.value })
                                setTelNo(telInfo.cell1, telInfo.cell2, e.target.value.toString());
                              }
                          }}

                           
                                       
                        />
                        </div>
                       
                        
                        <ValidationMessage isInvalid={(user?.tel == undefined) || (user.tel != '') ? false : true} errorMessage="Please enter office tel no" />
                     
                        </FormGroup>
                  
                        <div className="d-flex justify-content-between">
                         
                        <Button Ripple color="primary" type="submit">
                            Save information
                        </Button>
                        </div>
                    </Form>
                  )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </main>
      </div>
      </div>
      </div>
      </div>
      </div>
    )
}

export default CompleteProfilePage;