import React, { useState } from "react"
import Wizard from "./WizardListingComponent"
import { AvInput, AvGroup, AvFeedback, AvField } from "availity-reactstrap-validation"
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  Input

} from "reactstrap"

import { LocationStep } from '../wizard/location-step'
import { PropertyDetailsStep } from '../wizard/property-details-step'
import { PricingStep } from '../wizard/pricing-step'
import { PhotoStep } from '../wizard/photo-step'
import { PublishStep } from '../wizard/publish-step'

import { Property } from '../../../contracts/response-models/property'
import { SaleType, PropertyType } from "../../../contracts/enums"

import { createPropertyService } from '../../../machines/listing-machine/services'
import { editPropertyService } from '../../../machines/listing-machine/services'
import { Redirect } from "react-router-dom"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";


interface ListingAddProps {
  property: Property;
  setProperty: any;
}

export const WizardListingAdd = ({ property, setProperty }: ListingAddProps) => {

  const [isStepReady, setIsStepReady] = useState(true);

  const [latLng, setLatlng] = React.useState({ Latitude: "", Longitude: "" });
  const [loading, setLoading] = useState(false);
  const [IsPropertySaved, setPropertySaved] = useState(false);
  const [NewSavedPropertyId, setNewSavedPropertyId] = useState(0);
  const [buttonstatus, setButtonStatus] = useState(false);

  //Validation States - Location step

  const locationState = {
    stateError: false,
    countyError: false,
    areaError: false,
    zipError: false,
    streetError: false
  }
  const [location, setLocation] = React.useState(locationState)

  const validateAddress = () => {
    var isValidated = true;
    if (property.street == undefined || property.street == "") {
      setProperty({ ...property, street: '' });
      isValidated = false
    }

    if (property.zipCode == undefined || property.zipCode == "") {
      setProperty({ ...property, zipCode: '' });
      isValidated = false
    }

    if (property.areaId == undefined || property.areaId == 0) {
      setProperty({ ...property, areaId: 0 });
      isValidated = false
    }

    if (property.countyId == undefined || property.countyId == 0) {
      setProperty({ ...property, countyId: 0 });
      isValidated = false
    }

    if (property.stateCD == undefined || property.stateCD == "" || property.stateCD == "0") {
      setProperty({ ...property, stateCD: "0" });
      isValidated = false
    }
    if (property.propertyType == PropertyType.MFH) {
      if (property.units == undefined || property.units == 0) {
        setProperty({ ...property, units: "" });
        isValidated = false
      }
    }


    return isValidated;
  }

  const validatePropertyDetails = () => {

    if (property.saleType == SaleType.ForSale && (property.propertyType == PropertyType.SFH || property.propertyType == PropertyType.TWH)) {
      return ValidateSFHForSale();
    }
    if (property.saleType == SaleType.ForRent && (property.propertyType == PropertyType.SFH || property.propertyType == PropertyType.TWH)) {
      return ValidateSFHForRent();
    }
    if (property.propertyType == PropertyType.MFH) {
      return ValidateMFH();
    }
    if (property.propertyType == PropertyType.APT) {
      return ValidateAPT();
    }
    if (property.propertyType == PropertyType.LND) {
      return ValidateLND();
    }
    if (property.propertyType == PropertyType.COM) {
      return ValidateCOM();
    }

  }
  const validatePricingInfo = () => {
    if (property.saleType == SaleType.ForSale) {
      return validatePricingSale();
    }
    if (property.saleType == SaleType.ForRent) {
      return validatePricingRent();
    }
  }

  const validatePricingSale = () => {
    var isValidated = true;

    if (property.listPrice == undefined || property.listPrice.toString() == "") {
      isValidated = false
    }
    if (property.ownershipType !== 'p') {
      if (property.annualTax == undefined || property.annualTax.toString() == "") {
        isValidated = false
      }
    }

    if (property.propertyType == PropertyType.APT) {

      if (property.ownershipType == 'c') {
        if (property.commonCharges == undefined || property.commonCharges.toString() == "") {
          isValidated = false
        }
      }
      if (property.ownershipType == 'p') {
        if (property.maintenance == undefined || property.maintenance.toString() == "") {
          isValidated = false
        }
      }

    }

    if (property.endDate == undefined || property.endDate == "") {
      isValidated = false
    }
    if (property.endDate != undefined) {
      var today = new Date();
      var enddate = new Date(property.endDate.toString());
      if (property.id == undefined) {
        if (today.getTime() > enddate.getTime()) {
          isValidated = false
          setProperty({ ...property, endDate: '' });
        }
      }


    }


    return isValidated;
  }

  const validatePricingRent = () => {
    var isValidated = true;
    if (property.listPrice == undefined || property.listPrice.toString() == "") {
      isValidated = false
    }
    if (property.isBrokerFee == undefined || property.isBrokerFee.toString() == "") {
      isValidated = false
    }
    if (property.isSecurityDeposit == undefined || property.isSecurityDeposit.toString() == "") {
      isValidated = false
    }
    if (property.leaseTermId == undefined || property.leaseTermId.toString() == "") {
      isValidated = false
    }
    if (property.availableDate == undefined || property.availableDate.toString() == "") {
      isValidated = false
    }

    if (property.availableDate != undefined) {
      if (property.startDate != undefined && property.startDate != '') {
        var startDate = new Date(property.startDate.toString());
        var availableDate = new Date(property.availableDate.toString());

        if (startDate.getTime() > availableDate.getTime()) {
          isValidated = false
          setProperty({ ...property, availableDate: '' });
        }

      } else {
        var today = new Date();
        var availableDate = new Date(property.availableDate.toString());
        if (today.getTime() > availableDate.getTime()) {
          isValidated = false
          setProperty({ ...property, endDate: '' });
        }
      }



    }

    if (property.endDate == undefined || property.endDate.toString() == "") {
      isValidated = false
    }
    if (property.endDate != undefined) {
      var today = new Date();
      var enddate = new Date(property.endDate.toString());
      if (property.id == undefined) {
        if (today.getTime() > enddate.getTime()) {
          isValidated = false
          setProperty({ ...property, endDate: '' });
        }
      }


    }
    return isValidated;
  }

  const ValidateSFHForSale = () => {
    var isValidated = true;
    if (property.noOfRooms == undefined) {
      isValidated = false
    }
    if (property.noOfBedrooms == undefined) {
      isValidated = false
    }
    if (property.noOfFullBaths == undefined) {
      isValidated = false
    }
    if (property.noOfHalfBaths == undefined) {
      isValidated = false
    }
    if (property.garageId == undefined) {
      isValidated = false
    }
    if (property.yearBuiltId == undefined) {
      isValidated = false
    }
    if (property.heatingSourceId == undefined) {
      isValidated = false
    }
    if (property.basementId == undefined) {
      isValidated = false
    }
    if (property.conditionId == undefined) {
      isValidated = false
    }
    if (property.coolingTypeId == undefined) {
      isValidated = false
    }
    if (property.exteriorFinishId == undefined) {
      isValidated = false
    }
    if (property.propertyType == PropertyType.SFH && property.styleId == undefined) {
      isValidated = false
    }

    return isValidated;
  }
  const ValidateSFHForRent = () => {
    var isValidated = true;
    if (property.noOfBedrooms == undefined) {
      isValidated = false
    }
    if (property.noOfFullBaths == undefined) {
      isValidated = false
    }
    if (property.noOfHalfBaths == undefined) {
      isValidated = false
    }
    if (property.isPetsAllowed == undefined) {
      isValidated = false
    }
    if (property.garageId == undefined) {
      isValidated = false
    }
    if (property.yearBuiltId == undefined) {
      isValidated = false
    }
    if (property.heatingSourceId == undefined) {
      isValidated = false
    }
    if (property.conditionId == undefined) {
      isValidated = false
    }
    if (property.basementId == undefined) {
      isValidated = false
    }
    if (property.coolingTypeId == undefined) {
      isValidated = false
    }
    return isValidated;
  }
  const ValidateMFH = () => {
    var isValidated = true;
    if (property.parkingSpaces == undefined) {
      isValidated = false
    }
    if (property.yearBuiltId == undefined) {
      isValidated = false
    }
    if (property.heatingSourceId == undefined) {
      isValidated = false
    }
    if (property.coolingTypeId == undefined) {
      isValidated = false
    }

    if (property.units != undefined && property.units > 0) {
      property.unitsDetail?.map((object, i) => {
        if (object.rent == undefined) {
          isValidated = false;
        }
      })
    }

    return isValidated;
  }
  const ValidateAPT = () => {
    var isValidated = true;
    if (property.noOfBedrooms == undefined) {
      isValidated = false
    }
    if (property.noOfFullBaths == undefined) {
      isValidated = false
    }
    if (property.noOfHalfBaths == undefined) {
      isValidated = false
    }
    if (property.isPetsAllowed == undefined) {
      isValidated = false
    }
    if (property.conditionId == undefined) {
      isValidated = false
    }
    if (property.buildingTypeId == undefined) {
      isValidated = false
    }
    if (property.securityId == undefined) {
      isValidated = false
    }
    if (property.yearBuiltId == undefined) {
      isValidated = false
    }
    if (property.hasParking == undefined) {
      isValidated = false
    }
    if (property.saleType == SaleType.ForSale) {
      if (property.ownershipType == undefined) {
        isValidated = false
      }
    }

    return isValidated;
  }
  const ValidateLND = () => {
    var isValidated = true;
    if (property.availavleAcres == undefined) {
      isValidated = false
    }
    if (property.topographyId == undefined) {
      isValidated = false
    }
    if (property.wetLandId == undefined) {
      isValidated = false
    }
    return isValidated;
  }
  const ValidateCOM = () => {
    var isValidated = true;
    if (property.styleId == undefined) {
      isValidated = false
    }
    if (property.availableSqft == undefined) {
      isValidated = false
    }
    if (property.parkingSpaces == undefined || property.parkingSpaces.toString() == '') {
      isValidated = false
    }
    return isValidated;
  }

  const validatePhotos = () => {
    var isValidated = true;
    if (property.imageNames == undefined || property.imageNames.length == 0) {
      isValidated = false;
    }
    return isValidated;
  }

  const saveProperty = async () => {
    //Check Property mode = Edit/Addd
    setLoading(true);

    if (property.id != undefined && property.id > 0) {
      //Edit Mode
      const response = await editPropertyService(property, property.id);
      setPropertySaved(true);
      toast.success("Property Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else {
      //Add Mode
      const response = await createPropertyService(property);
      console.log("Response" + response);
      if (response.propertyId != undefined) {
        setNewSavedPropertyId(response?.propertyId);
      }

      setPropertySaved(true);
      toast.success("Property Added Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setLoading(false);
  }


  const state = {
    steps: [
      {
        title: 1,
        alterTitle: 'Location',
        content: <LocationStep
          property={property}
          setProperty={setProperty}
          validateAddress={validateAddress}
          setIsStepReady={setIsStepReady}
        />
      },
      {
        title: 2,
        alterTitle: 'Property Details',
        content: <PropertyDetailsStep property={property} setProperty={setProperty} />
      },
      {
        title: 3,
        alterTitle: 'Pricing',
        content: <PricingStep property={property} setProperty={setProperty} />
      },
      {
        title: 4,
        alterTitle: 'Photos',
        content: <PhotoStep IsPropertySaved={IsPropertySaved} NewSavedPropertyId={NewSavedPropertyId} loading={loading} property={property} setProperty={setProperty} />
      }
      /*,
      {
        title: 5,
        alterTitle:'Publish',
        content: <PublishStep IsPropertySaved={IsPropertySaved} property={property} loading={loading} setLoading={setLoading} />
      } */
    ]
  }



  const formRef = React.createRef();

  const ValidatStep = (stepIndex: number) => {
    console.log("stepIndex:" + stepIndex);

    if (!isStepReady)
      return false;

    switch (stepIndex) {
      case 0:
        return validateAddress();
      case 1:
        return validatePropertyDetails();
      case 2:
        return validatePricingInfo();
      case 3:

        if (validatePhotos() == true && !loading) {
          return saveProperty();
        } else
          return false;
      case 4:
        return false;

    }
    return true;
  }

  const { steps } = state

  return (
    <Card>
      <CardBody>
        <Wizard
          validate
          steps={steps}
          formRef={formRef}
          ValidatStep={ValidatStep}
        />
      </CardBody>
    </Card>
  )
}

export default WizardListingAdd;

