import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,   
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import Radio from "../../../../components/@vuexy/radio/RadioVuexy"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyPets = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handlePets = (e:any) =>{        
        setProperty({...property,isPetsAllowed:e.target.value});
      }
            return (     
            <> 

            <Col md="3" sm="12">              
                <Row>
                  <Col md="6" sm="6">
                  Pets allowed? 
                  </Col>
                  <Col md="6" sm="6" className={classnames(" ",{
                    invalid: (property.isPetsAllowed == undefined)
                  })}>      
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="Yes"
                      color="primary"
                      defaultChecked={property.isPetsAllowed!=undefined && property.isPetsAllowed==true}
                      name="isPetsAllowed"
                      value={true}
                      onChange={handlePets}
                    />
                    </div>                    
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="No"
                      color="primary"                     
                      defaultChecked={property.isPetsAllowed!=undefined && property.isPetsAllowed==false}
                      name="isPetsAllowed"
                      value={false}
                      onChange={handlePets}
                    />
                    </div>                    
                  </Col>                  
                </Row>
               </Col>       
              
         </>
    )
}
    