import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Spinner
} from "reactstrap"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { ValidationMessage } from "../@vuexy/validation-message";
import { Property } from "../../contracts/response-models/property";
import { User } from '../../contracts/response-models/user'
import { getUserInfoService } from "../../machines/listing-machine/services"
import { setDatePickerFormat, setDatePickerFormat2 } from "../../utils/date-helper";

import { StripeSecret } from '../../contracts/response-models/stripe-client-secret'
import {PaymentType} from '../../contracts/enums/payment-type'

import { getStripeClientSecretListingservice } from "../../machines/listing-machine/services"

import {createInvoiceService} from "../../machines/listing-machine/services"
import "../../assets/scss/pages/stripe-form.scss"; 
import {StripePayment} from './StripePayment'

import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import { displayTitle } from "../../utils/display-property-title";
import { PaymentInfo } from "../../contracts/request-models/payment-info";
import { BillingInfo } from "./billing-info";
interface paymentProps {
  property: Property;
}


export const StripePaymentForm = ({ property }: paymentProps) => {
  
  const [loading, setLoading] = useState(false);
  const [isSavedSuccess, setSavedSuccess] = useState(false); 
  const [paymentloading, setPaymentLoading] = useState(false);
  const [payInfo, setPayInfo] = useState<PaymentInfo>({
    name:"",
    address:"",
    city:"",
   
  });
  const[paymentError,setPaymentError]=useState('');
  const stripe = useStripe();
  const elements = useElements();  
  const [user, setUser] = useState<User>({ userId: 0 });  
  const [stripeSecret, setStripeSecret] = useState<StripeSecret>(
    {
      secret: '',
      amount: 0,
      days:0,
      discount:0,
    }
  );
 
  useEffect(() => {
    async function setBillingInfo() {
      setPayInfo({
        name:user?.firstName +" "+user?.lastName,
        address:user?.streetAddress,
        city:user?.city,
      })  
    
    }

    setBillingInfo();
  }, [user?.userName]);


  useEffect(() => {
    async function setUserInfo() {
      setLoading(true);      
      const userinfo = await getUserInfoService();
      setUser(userinfo);

     //  console.log('Email 2 :'+user.userName);
      if(user.userName!=undefined)
      {
 
      const striperesponse = await getStripeClientSecretListingservice(property.startDate,property.endDate,user.userName,PaymentType.ListingPayment);
      setStripeSecret(striperesponse);
      }
     // console.log('Stripe Response :'+striperesponse);
      setLoading(false);
    
    }

    setUserInfo();
  }, [user.userName]);

  const handleStripePaymentSubmit = async (e: any) => {
    
    e.preventDefault();
    if(paymentloading)
      return;
    setPaymentError('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    
     const client_secret = stripeSecret?.secret;
    
     const card = elements.getElement(CardElement);
     if(!card){
       return;
     }

     var isValidated = true;
     if(payInfo.name=="" || payInfo.name==undefined)
     {
      setPayInfo({ ...payInfo, name: '' });
      isValidated = false;
      return;
     }
     if(payInfo.address=="" || payInfo.address==undefined)
     {
      setPayInfo({ ...payInfo, address: '' });
      isValidated = false;
      return;
     }
     if(payInfo.city=="" || payInfo.city==undefined)
     {
      setPayInfo({ ...payInfo, city: '' });
      isValidated = false;
      return;
     }

     setPaymentLoading(true);
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: card,        
        billing_details: {
          name: payInfo?.name,
          address:{
            line1:payInfo?.address,
            city: payInfo?.city,
            country: 'US'
          },         

        },
      }
    });
    if(result)
    {
      setPaymentLoading(false);
    }
    if (result.error!=undefined) {
      if(result.error.message!=undefined)
      {
        setPaymentError(result.error.message);
      }    
       
      //console.log(result.error.message);
    } else {
      setLoading(true);   
      // The payment has been processed!
      if (result?.paymentIntent?.status !== undefined && property.endDate!==undefined) {
        if (result.paymentIntent.status === 'succeeded') {

          const invoicesaveresponse = createInvoiceService(PaymentType.ListingPayment,stripeSecret.days,property.id,result.paymentIntent.id,result.paymentIntent.amount,property.endDate);
          
          toast.success("Payment Processed Successfully", {
            position: toast.POSITION.TOP_RIGHT
          })
          setLoading(false);   
          setSavedSuccess(true);  
        }
      }

    }  

  }

  if(isSavedSuccess)
  {
    return (<Redirect to="/listings" />)
  }

  return (
    <>
   {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
           
      <form onSubmit={handleStripePaymentSubmit} className='form-container'  >
        <ToastContainer />
        <FormGroup>
          <Label for="name"><strong>Name : </strong> </Label>
        &nbsp;   {user?.firstName} {user?.lastName} 
        </FormGroup>
        <FormGroup>
          <Label for="email"><strong>E-mail : </strong> </Label>   {user?.userName} 

        </FormGroup>
        <FormGroup>
          <Label for="address"><strong> Property Address : </strong> </Label>   {property?.street}, {property.areaTitle} {property.stateCD} {property.zipCode}  

        </FormGroup>
        <FormGroup>
          <Label for="address"><strong> Listing Type : </strong>  </Label>   
            &nbsp;
          {property?.propertyType!=undefined && property?.saleType!=undefined && (
             <>
              {displayTitle(property.propertyType,property.saleType,false,'')} 
              </>
            ) }

{property?.propertyType!=undefined && property?.saleType!=undefined && property?.ownershipType!=undefined && (
            <>
          {displayTitle(property.propertyType,property.saleType,false,property.ownershipType)} 
          </>
            ) }
  

        </FormGroup>
       
        <FormGroup>
          <Label for="startDate"><strong>Listing Start Date : </strong></Label>    {(setDatePickerFormat2(property.startDate))} 


        </FormGroup>
        <FormGroup>
          <Label for="endDate"><strong> Listing End Date : </strong> </Label>   {(setDatePickerFormat2(property.endDate))} 


        </FormGroup>
        <FormGroup>
          <Label for="days"><strong> Number of Days : </strong> </Label>  {(stripeSecret.days)} 


        </FormGroup>
        <FormGroup>
          <Label for="nameVertical"><strong> Amount : </strong></Label>   ${(stripeSecret?.amount/100).toFixed(2)}  


        </FormGroup>
        <BillingInfo payInfo={payInfo} setPayInfo={setPayInfo} />
        
            <StripePayment error={paymentError} />
         

            { paymentloading ?   (        

      
      <button type="button" className="btn btn-primary" >
      Processing...
</button>
     ):( 
        <button type="submit" className="btn btn-primary" disabled={!stripe}>
          Pay & Publish
      </button>)}
      </form>
     
          )}

    </>
  )
}
