 
import { listProperty } from "../../../api/admin/properties/list-properties"
import { listPosts } from "../../../api/admin/posts/list-posts"
import { listInvoices } from "../../../api/admin/payment/list-invoices";
import { listInquiries } from "../../../api/admin/properties/list-inquiries";
import { listUsers } from "../../../api/admin/users/list-users";
import { getUserProfileInfo } from "../../../api/admin/users/get-user";
import { AgentSearchRequest } from "../../../contracts/response-models/agent-search-request";



export const listPropertiesService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const properties = await listProperty(paged, perpagearecord, sortedColumn, sortDirection);
  return properties;
};

export const listPostsService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const posts = await listPosts(paged, perpagearecord, sortedColumn, sortDirection);
  return posts;
};

export const listInvoiceService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const invoices = await listInvoices(paged, perpagearecord, sortedColumn, sortDirection);
  return invoices;
};

export const listInquiriesService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const enquiries = await listInquiries(paged, perpagearecord, sortedColumn, sortDirection);
  return enquiries;
};

export const listUsersService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string,query:string) => {
  let request: AgentSearchRequest = {
    query:query 
  };
  const users = await listUsers(paged, perpagearecord, sortedColumn, sortDirection,request);
  return users;
};

export const getUserProfileInfoService = async (userId:number) => {
  const userinfo = await getUserProfileInfo(userId);
  return userinfo;
};


