import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,   
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import Radio from "../../../../components/@vuexy/radio/RadioVuexy"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyOwnership = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleOwnership = (e:any) =>{       
        setProperty({...property,ownershipType:e.target.value});
      }
            return (     
            <> 

            <Col md="3" sm="12">              
                <Row >
                  <Col md="6" sm="6">
                  Ownership 
                  </Col>
                  <Col md="6" sm="6"  className={classnames(" ",{
                    invalid: (property.ownershipType == undefined)
                  })}>      
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="Condo"
                      color="primary"
                      defaultChecked={property.ownershipType!=undefined && property.ownershipType=='c'}
                      name="ownershipType"
                      value="c"
                      onChange={handleOwnership}
                    />
                    </div>                    
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="Coop"
                      color="primary"                     
                      defaultChecked={property.ownershipType!=undefined && property.ownershipType=='p'}
                      name="ownershipType"
                      value="p"
                      onChange={handleOwnership}
                    />
                    </div>                    
                  </Col>                  
                </Row>
               </Col>       
              
         </>
    )
}
    