import React from "react"
import * as Icon from "react-feather"

 
if (localStorage.getItem("__zaglist_admin__") === 'yes') {
  var navigationConfig:any = [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      icon: <Icon.Home size={20} />,  
      navLink: "/"
    },  
    
    {
      type: "groupHeader",
      groupTitle: "Listings"
    }
    ,
    {
      id: "adminListings",
      title: "Listings",
      type: "item",
      icon: <Icon.List size={20} />,   
      navLink: "/listings" ,
      filterBase: "/listings"  
    }, {
      id: "inquiries",
      title: "Inquiries",
      type: "item",
      icon: <Icon.List size={20} />,   
      navLink: "/listings/inquiries",
      filterBase: "/listings/inquiries"  
    }, 
    {
      type: "groupHeader",
      groupTitle: "Users"
    },
     {
      id: "agents",
      title: "Agents",
      type: "item",
      icon: <Icon.List size={20} />,   
      navLink: "/agents",
      filterBase: "/agents"  
    },
    {
      type: "groupHeader",
      groupTitle: "Profile"
    }, 
    {
      id: "changePassword",
      title: "Change Password",
      type: "item",
      icon: <Icon.Lock size={20} />,    
      navLink: "/change-password",    
      filterBase: "/change-password"  
    },          
    {
      type: "groupHeader",
      groupTitle: "Blog"
    },
    {
      id: "Post",
      title: "Posts",
      type: "item",
      icon: <Icon.List size={20} />,
      navLink: "/posts" ,
      filterBase: "/posts"  
    },      
    {
      type: "groupHeader",
      groupTitle: "Payments"
    },
    {
      id: "Invoices",
      title: "Invoices",
      type: "item",
      icon: <Icon.DollarSign size={20} />,
      navLink: "/invoices" ,
      filterBase: "/invoices"  
    } 
    ,
    {
      id: "paymentSetting",
      title: "Payment Setting",
      type: "item",
      icon: <Icon.DollarSign size={20} />,
      navLink: "/payment-setting" ,
      filterBase: "/payment-setting"  
    } 
  ]
}else
{
  var navigationConfig:any = [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      icon: <Icon.Home size={20} />,  
      navLink: "/"
    },  
    
    {
      type: "groupHeader",
      groupTitle: "Listings"
    }
    ,
    {
      id: "myListings",
      title: "My Listings",
      type: "item",
      icon: <Icon.List size={20} />,   
      navLink: "/listings" ,
      filterBase: "/listings"  
    },
    
    {
      id: "addListing",
      title: "Add New Listing",
      type: "item",
      icon: <Icon.PlusSquare size={20} />,   
      navLink: "/listings/add",
      filterBase: "/listings/add"  
    },
    
    {
      id: "inquiries",
      title: "Inquiries",
      type: "item",
      icon: <Icon.List size={20} />,   
      navLink: "/listings/inquiries",
      filterBase: "/listings/inquiries"  
    },
    {
      type: "groupHeader",
      groupTitle: "Profile"
    },
    {
      id: "previewProfile",
      title: "My Profile",
      type: "item",
      icon: <Icon.User size={20} />,    
      navLink: "/preview-profile",
      filterBase: "/preview-profile"  
    },  
    {
      id: "editProfile",
      title: "Edit Profile",
      type: "item",
      icon: <Icon.Edit size={20} />,    
      navLink: "/edit-profile",    
      filterBase: "/edit-profile"  
    },   
    {
      id: "changePassword",
      title: "Change Password",
      type: "item",
      icon: <Icon.Lock size={20} />,    
      navLink: "/change-password",    
      filterBase: "/change-password"  
    },          
    {
      type: "groupHeader",
      groupTitle: "Blog"
    },
    {
      id: "myPost",
      title: "My Posts",
      type: "item",
      icon: <Icon.List size={20} />,
      navLink: "/posts" ,
      filterBase: "/posts"  
    },
    {
      id: "addPost",
      title: "Add New Post",
      type: "item",
      icon: <Icon.FileText size={20} />,   
      navLink: "/posts/add" ,
      filterBase: "/posts/add"  
    },     
    {
      type: "groupHeader",
      groupTitle: "Payments"
    },
    {
      id: "myInvoices",
      title: "Invoices",
      type: "item",
      icon: <Icon.DollarSign size={20} />,
      navLink: "/invoices" ,
      filterBase: "/invoices"  
    },      
     
    {
      type: "groupHeader",
      groupTitle: "SUPPORT"
    },  
    {
      id: "raiseSupport",
      title: "Contact Support",
      icon: <Icon.LifeBuoy size={20} />, 
      type: "item", 
      navLink: "/support" ,
    }
     ,
    {
      type: "groupHeader",
      groupTitle: "Account"
    },  
    {
      id: "raiseSupport",
      title: "Delete My Account",
      icon: <Icon.LifeBuoy size={20} />, 
     type: "item",
      
      navLink: "/delete-account" ,
    }
  ]
}

export default navigationConfig
