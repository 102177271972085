import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {PropertyType,SaleType} from '../../../../contracts/enums'
import {Property} from '../../../../contracts/response-models/property'

import {SelectYearBuiltInput} from '../../SelectInput/SelectYearBuiltInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyYearBuilt = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleYearBuiltChange = (option:any) =>{    
        setProperty({...property,yearBuiltId:option.value});
      }
    

        return (     
            <>  
           
          <Col md="3" sm="12">
          {property.propertyType==PropertyType.APT ?
              (
           
            <div className="d-inline mr-1">Buidling Age</div>
              ):(
                <div className="d-inline mr-1">Year Built</div> 
              )
              }
            <SelectYearBuiltInput                               
                defaultValue={property.yearBuiltId}
                name="yearBuilt"  
                className={classnames("React",{
                  invalid: (property.yearBuiltId == undefined)
                })}
                onChange={handleYearBuiltChange}               
              />
          </Col>
         </>
    )
}
    