import React, { useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import { Property } from '../../../../contracts/response-models/property'
import { setDatePickerFormat } from "../../../../utils/date-helper";
import { ValidationMessage } from "../../validation-message";
import { useEffect } from "react";

interface PricingStepProps {
  property: Property,
  setProperty: any;
}
export const PropertyExpiryDate = ({ property, setProperty }: PricingStepProps) => {
  
  const [endDate, setEndDate] = useState<any>('');

  useEffect(() => {
    async function setDate() {
       if(property.endDate!='' || property.endDate!=undefined) 
       { 
        setEndDate(setDatePickerFormat(property.endDate));
       } 
    } 
    setDate();
  }, [property.endDate]);

  const handleEndDateChange = (e: any) => {

    if (e.target.value === false) {
      setProperty({ ...property, endDate: undefined });
      setEndDate(undefined)
    } else {
      //  const dt = new Date(e.target.value);
      // const dtDate = new Date(dt.toDateString())
      // console.log(dtDate);
      setProperty({ ...property, endDate: e.target.value });
      setEndDate(e.target.value)
    }

  }

  return (
    <>
      <div className="form-label-group mt-2">
        <div className="d-inline mr-1">Expiration</div>  
        {(property.id != undefined && property.id > 0 ? (

          <Input
            placeholder="Expiry Date"
            readOnly
            type="date"
            value={(property.endDate == undefined ? "" : endDate)}
            className={classnames("", {
              invalid: (property.endDate == undefined || property.endDate.toString() == "")
            })}
            mobile

          />

        ) : (
            <Input
              placeholder="Expiry Date"

              type="date"
              value={(property.endDate == undefined ? "" : endDate)}
              className={classnames("mt-2", {
                invalid: (property.endDate == undefined || property.endDate.toString() == "")
              })}
              mobile
              onChange={handleEndDateChange}
            />

          ))}

        <ValidationMessage isInvalid={(property.endDate == undefined) || (property.endDate.toString() != '') ? false : true} errorMessage="Expiration date must be after the listing date" />

      </div>


    </>
  )
}
