import React,{useEffect,useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import {setDatePickerFormat} from '../../../../utils/date-helper'
interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyStartDate = ({ property, setProperty}:PricingStepProps) => {   
    
  useEffect(() => {
    async function setStartDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); 
      var yyyy = today.getFullYear();
      var currentdate =  yyyy + '-' + mm + '-' + dd ;
      
      if(property.startDate=='' || property.startDate==undefined)
      {
        setProperty({...property,startDate:currentdate}); 
        
      }
     
       
  }
  setStartDate();

  }, []);


        return (     
            <>    
                <div className="form-label-group mt-2">
       
        <div className="d-inline mr-1">Listing Date</div> 
                <Input            
                   placeholder="Listing Date"  
                     readOnly
                   type="date"
                   value={setDatePickerFormat(property.startDate) }
                 
                  className={classnames("")}
                  mobile                  
                 
                />
               
              </div>

                 
         </>
    )
}
    