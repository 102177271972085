import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectLeaseTermInput} from '../../SelectInput/SelectLeaseTermInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyLeaseTerm = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleLeaseTermChange = (option:any) =>{    
        setProperty({...property,leaseTermId:option.value});
      }   

        return (     
            <>  
           
         

           <div className="form-label-group mt-2"> 
           <div className="d-inline mr-1">Lease Term</div>
            <SelectLeaseTermInput                               
              defaultValue={property.leaseTermId}
              name="heatingSource"  
              property={property}              
              className={classnames("React",{
                invalid: (property.leaseTermId == undefined || property.leaseTermId.toString()=="")
              })}
              onChange={handleLeaseTermChange}
            /> 
          </div>
         </>
    )
}
    