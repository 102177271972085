import React from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup
} from "reactstrap"
import classnames from "classnames";
import Checkbox from "../checkbox/CheckboxesVuexy"
import Radio from "../../../components/@vuexy/radio/RadioVuexy"
import { Check } from "react-feather"

import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"

import { PropertyType, SaleType } from '../../../contracts/enums'
import { Property } from '../../../contracts/response-models/property'

import { SelectYearBuiltInput } from '../SelectInput/SelectYearBuiltInput'
import { SelectConditionInput } from '../SelectInput/SelectConditionInput'
import { SelectStyleInput } from '../SelectInput/SelectStyleInput'
import { SelectBasementInput } from '../SelectInput/SelectBasementInput'
import { SelectGarageInput } from '../SelectInput/SelectGarageInput'
import { SelectExtFinishInput } from '../SelectInput/SelectExtFinishInput'
import { SelectHSourceInput } from '../SelectInput/SelectHSourceInput'
import { SelectCoolingTypeInput } from '../SelectInput/SelectCoolingTypeInput'

import { PropertyDetailsAPT } from "./property-details-APT"
import { PropertyDetailsSFHS } from "./property-details-SFHS"
import { PropertyDetailsSFHR } from "./property-details-SFHR"
import { PropertyDetailsMFHS } from "./property-details-MFHS"
import { PropertyDetailsCOM } from "./property-details-COM"
import { PropertyDetailsLND } from "./property-details-LND"


import { PropertyImprovements } from "./property-improvements"
import { PropertyZones } from "./property-zones"
import { PropertyWaterFront } from "./property-waterfront"
import { PropertyAmenities } from "./property-amenities"


interface PropertyDetailsStepProps {
  property: Property,
  setProperty: any;
}

export const PropertyDetailsStep = (
  {
    property,
    setProperty
  }: PropertyDetailsStepProps) => {
  //console.log(property.saleType) 


  const maxDescLimit = 800;
  const handleDescriptionChange = (e: any) => {
    var charLeft = maxDescLimit - e.target.value.length;
    if (charLeft >= 0) {
      setProperty({ ...property, propertyDescription: e.target.value });
    } else {
      var str = e.target.value.substring(0, maxDescLimit);
      setProperty({ ...property, propertyDescription: str });
    }

  }

  return (
    <Row>
      <Col className="mx-auto" md="10" sm="12">
        <h3 className="mb-3 mt-2 text-center">PROPERTY DETAILS</h3>

        {property.propertyType == PropertyType.APT &&
          (
            <PropertyDetailsAPT property={property} setProperty={setProperty} />
          )}

        {(property.propertyType == PropertyType.SFH || property.propertyType == PropertyType.TWH) &&
          (
            <>
              {property.saleType == SaleType.ForSale &&
                (
                  <PropertyDetailsSFHS property={property} setProperty={setProperty} />
                )
              }
              {property.saleType == SaleType.ForRent &&
                (
                  <PropertyDetailsSFHR property={property} setProperty={setProperty} />
                )
              }
            </>
          )}

        {property.propertyType == PropertyType.MFH &&
          (
            <PropertyDetailsMFHS property={property} setProperty={setProperty} />

          )}
        {property.propertyType == PropertyType.COM &&
          (
            <PropertyDetailsCOM property={property} setProperty={setProperty} />
          )}
        {property.propertyType == PropertyType.LND &&
          (
            <>
              <PropertyDetailsLND property={property} setProperty={setProperty} />
              <PropertyZones property={property} setProperty={setProperty} />
              <PropertyImprovements property={property} setProperty={setProperty} />
              <PropertyWaterFront property={property} setProperty={setProperty} />
            </>
          )}
        {property.propertyType != PropertyType.LND &&
          (
            <>
              <PropertyAmenities property={property} setProperty={setProperty} />
            </>
          )}
        <Row className="mt-2">
          <Col md="12" sm="12">
            <div className="form-label-group mt-2">
              <div className="d-inline mr-1">Description</div>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                rows="3"
                value={property.propertyDescription}
                placeholder="Description"
                onChange={handleDescriptionChange}
              >{property.propertyDescription}</Input> 
              <sub>
                  Char Left : &nbsp;
                  {property.propertyDescription?.length != undefined ? (
                <>           {maxDescLimit - property.propertyDescription?.length}</>
              ) : (maxDescLimit)} </sub>

            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default PropertyDetailsStep;