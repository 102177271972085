export const getInvoiceNumber = (invoiceId: any) => {
    var InvoiceNumber;
    if (invoiceId < 10) {
        InvoiceNumber = "000" + invoiceId
    } else if (invoiceId >= 10 && invoiceId < 100) {
        InvoiceNumber = "00" + invoiceId
    } else if (invoiceId >= 100 && invoiceId < 1000) {
        InvoiceNumber = "0" + invoiceId
    } else {
        InvoiceNumber = invoiceId;
    }
    return InvoiceNumber;
};