import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap"
import Select from "react-select"
import PhoneInput from 'react-phone-input-2'

import { ValidationMessage } from "../components/@vuexy/validation-message"

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { SelectStateInput } from '../components/@vuexy/SelectInput/SelectStateInput'
import { listStatesService, listCountyService, listAreaService, getLatlngByAddress, getUserProfileInfoService, editUserProfileService, changePasswordService } from "../machines/listing-machine/services"
import { User } from '../contracts/response-models/user'
import { getUserInfoService } from "../machines/listing-machine/services"
import { editUserService } from '../machines/listing-machine/services'

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
import { AgentProfile } from "../contracts/response-models/agent-profile";
import { ProfileSection } from "../contracts/response-models/profile-section";
import "../components/Profile/css/edit-profile.css"

export interface PasswordInfo {
  currentPassword?:string;
  password?: string;
  confirmPassword?: string;
  isvalidate?:boolean;
}


export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>();
  const [validationMessage, setValidationMessage] = useState<string>();

  useEffect(() => {
    async function loadStats() {
      setLoading(true); 
      const userProfileinfo = await getUserProfileInfoService();
     
      const userinfo = userProfileinfo.user;
      setUser(userinfo);
       
      setLoading(false);
    }
    loadStats();
  }, []);
  
  const [user, setUser] = useState<User>({ userId: 0 });
  
    
  const passwordValidate = (passwordValue:string) => {

    var isValidated = true;

    if (passwordValue != undefined && passwordValue != "") {
      if (passwordValue.length < 8) {
        setValidationMessage("Password must have minimum 8 characters");
        isValidated = false;
      }else if (!passwordValue.match(/[A-Z]+/)) { 
          setValidationMessage("Password must have a capital (uppercase) letter");
           isValidated = false;
        }else if (!passwordValue.match(/[a-z]+/)) { 
          setValidationMessage("Password must have a lowercase letter");
           isValidated = false;
        }else if (!passwordValue.match(/[0-9]+/)) { 
          setValidationMessage("Password must have a number");
           isValidated = false;
        }else if (!passwordValue.match(/[!@#$%\^&*(){}[\]<>?/|\-_]+/)) { 
          setValidationMessage("Password must have a special character");
           isValidated = false;
        }else{
          setValidationMessage("");
          isValidated = true;
        }

    }
    return isValidated;
  }

  const savePassword = async (e: any) => {
    e.preventDefault() 
    var isValidated = true;
    
    

    if (passwordInfo?.confirmPassword == undefined || passwordInfo.confirmPassword == "") {
      setPasswordInfo({ ...passwordInfo, confirmPassword: '' });
      isValidated = false
    }
    if (passwordInfo?.password == undefined || passwordInfo.password == "") {
      setPasswordInfo({ ...passwordInfo, password: '' });
      setValidationMessage("Please type password");
      isValidated = false
    }
    if (passwordInfo?.currentPassword == undefined || passwordInfo.currentPassword == "") {
      setPasswordInfo({ ...passwordInfo, currentPassword: '' });
      isValidated = false
    }
    if (passwordInfo?.password!=undefined && !passwordValidate(passwordInfo?.password)) {
      isValidated = false;
    }
    if (passwordInfo?.password!=undefined &&  passwordInfo.confirmPassword!=undefined && passwordInfo.password!=passwordInfo.confirmPassword) {
      setValidationMessage("Please type same password again");
      isValidated = false;
    }
    if (isValidated == true && passwordInfo?.password!=undefined && passwordInfo?.currentPassword!=undefined) {
      setLoading(true);

      const response = await changePasswordService(passwordInfo?.currentPassword,passwordInfo?.password);
      if(response.success==true)
      {
        setPasswordInfo({...passwordInfo,password:'',confirmPassword:'',currentPassword:''});
      toast.success("Password Changed Successfully", {
        position: toast.POSITION.TOP_RIGHT
      }) 
      }else
      {
        setPasswordInfo({...passwordInfo,password:'',confirmPassword:''});
      toast.error(response.error, {
        position: toast.POSITION.TOP_RIGHT
      }) 
      }
      
      setLoading(false);
    }

  }

  const resetPassword = async (e: any) => {
    setPasswordInfo({...passwordInfo,password:'',confirmPassword:'',currentPassword:''});
  }


  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Change Password" breadCrumbParentLink="/preview-profile" breadCrumbParent="My Profile" breadCrumbActive="Change Password" />
   <Card>
        <CardBody>
          <ToastContainer />

          {!loading ? (
            <Row className="mt-1">
              <Col className="mx-auto" md="7" sm="12">
          <h3 className="mb-2 text-left">Change Password</h3>
                <Form>
                <FormGroup row>
                    <Col md="4">
                      <span>Current Password</span>
                    </Col>
                    <Col md="8">
                    <Input
                            type="password"
                            placeholder="Current Password"
                            value={passwordInfo?.currentPassword} 
                            onChange={(e)=> { setPasswordInfo({ ...passwordInfo, currentPassword: e.target.value })}}                           
                        />
                   <ValidationMessage isInvalid={(passwordInfo?.currentPassword == undefined) || (passwordInfo.currentPassword != '') ? false : true} errorMessage="Please enter current password" />
                       </Col>
                  </FormGroup> 
                  <FormGroup row>
                    <Col md="4">
                      <span>New Password</span>
                    </Col>
                    <Col md="8">
                    <Input
                            type="password"
                            placeholder="Password"
                            value={passwordInfo?.password}    
                            onChange={(e)=> { setPasswordInfo({ ...passwordInfo, password: e.target.value })}}                              
                        />
                 {(validationMessage != undefined && validationMessage != '') && (
              <>
                <div className="invalid-feedback d-block">{validationMessage}</div>
              </>
            )}  </Col>

                  </FormGroup>
                 
                  <FormGroup row>
                    <Col md="4">
                      <span>Confirm New Password</span>
                    </Col>
                    <Col md="8">
                    <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={passwordInfo?.confirmPassword} 
                            onChange={(e)=> { setPasswordInfo({ ...passwordInfo, confirmPassword: e.target.value })}}                           
                        />
                   <ValidationMessage isInvalid={(passwordInfo?.confirmPassword == undefined) || (passwordInfo.confirmPassword != '') ? false : true} errorMessage="Please enter confirm password" />
                       </Col>
                  </FormGroup> 

                  <FormGroup row><Col md="12" className="mb-2 ml-5"></Col>
                    <Col md={{ size: 8, offset: 4 }}>

                      <Button
                        color="primary"
                        type="submit"
                        className="mr-1 mb-1"
                        onClick={savePassword}
                      >
                        Submit
                </Button>
                      <Button
                        outline
                        color="warning"
                        type="reset"
                        className="mb-1"
                        onClick={resetPassword}
                      >
                        Reset
                </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          ) : (
              <div className="d-flex justify-content-between mt-1">
                <Spinner size="lg" className="primary float-right" />
              </div>
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default ChangePassword;