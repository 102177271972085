import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom"


import {
  Row, Col, Button, Card, CardBody, ListGroup, ListGroupItem,
  FormGroup,
  Progress,
  Input,
  Spinner,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  TabPane,
} from "reactstrap"
//import SortIcon from "@material-ui/icons/ArrowDownward";
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
import "../assets/scss/custom.scss";


import { PropertyStatus } from '../contracts/response-models/propertystatus'

import { propertyStatusService } from '../machines/listing-machine/services'


import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"


import { listPropertiesService } from "../machines/listing-machine/services"
import { Property } from "../contracts/response-models/property";
import { DeletePropertyService } from "../machines/listing-machine/services"
import { PropertyOHModal } from '../components/MyListings/property-oh-modal';
import { PropertyELModal } from '../components/MyListings/property-el-modal';
import { PropertyRLModal } from '../components/MyListings/property-rl-modal';
import { PropertyFLModal } from '../components/MyListings/property-fl-modal';
import { PropertyCPModal } from '../components/MyListings/property-cp-modal';
import { PropertyVLModal } from '../components/MyListings/property-vl-modal';
import { PropertyEVModal } from '../components/MyListings/property-ev-modal';

import { useModal } from '../components/MyListings/useModal';
import { displayTitle } from "../utils/display-property-title";
import { getZagNumber } from "../utils/zag-number";
import { setDatePickerFormat2 } from "../utils/date-helper";
import { PagedResult } from "../contracts/response-models/paged-result";
import { displayCurrency } from "../utils/display-currency";

export enum ActionModalTypes {
  OpenHouse = "1",
  ExtendListing = "2",
  FeaturedListing = "3",
  ChangePrice = "4",
  VirtualLink = "5",
  EmbedVideo = "6",
  RenewListing = "7",
}
export const MyListings = () => {
  const [loading, setLoading] = useState(false);
  const [propertyResult, setPropertyResult] = React.useState<PagedResult<Property>>();
  const [properties, setProperties] = React.useState<Property[]>([]);
  const [readyState, setReadyState] = useState(true);
  const [paged, setPropertiesInfo] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('PropertyId');
  const [sortDirection, setSortDirection] = useState('Dsc');

  const [propertyStatus, setpropertyStatus] = useState<PropertyStatus>(
    {
      status: "0"
    }
  );

  useEffect(() => {
    async function loadProperties() {
      if (readyState == true) {
        setLoading(true);
        const propertyResult = await listPropertiesService(paged, perpagearecord, sortedColumn, sortDirection);
        setPropertyResult(propertyResult)
        setProperties(propertyResult.data);
        setPropertiesInfo(propertyResult.pageIndex);
        settotalrows(propertyResult.totalRecords);
        setLoading(false);
        setReadyState(false);
      }
    }

    loadProperties();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection]);

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteMessage, setshowDeleteMessage] = useState(false);
  const [totalrows, settotalrows] = useState(0);

  const [idToDelete, setIdToDelete] = useState(0);

  const handleRemove = (id: number) => {
    setIdToDelete(id);
    setshowDeleteConfirm(true);
  }

  const [showSuspendConfirm, setshowSuspendConfirm] = useState(false);
  const [showSuspendMessage, setshowSuspendMessage] = useState(false);
  const [idToSuspend, setIdToSuspend] = useState(0);

  const handleSuspendListing = (id: number) => {
    setIdToSuspend(id);
    setpropertyStatus({ ...propertyStatus, status: "2" });
    setshowSuspendConfirm(true);
  }

  const [showActivateConfirm, setshowActivateConfirm] = useState(false);
  const [showActivateMessage, setshowActivateMessage] = useState(false);
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  
  const [idToActivate, setIdToActivate] = useState(0);

  const handleActivateListing = (id: number) => {
    setIdToActivate(id);
    setpropertyStatus({ ...propertyStatus, status: "1" });
    setshowActivateConfirm(true);
  }



  const ChangePagination = (page: any, totalRows: any) => {
    setPropertiesInfo(page);
    setReadyState(true);
  }
  const ChangeRowsPerPage = (rowPerPage: number) => { setPagePerRecord(rowPerPage);  setReadyState(true); }
 
  const sortProperties = (rows: any[], field: string, direction: any) => {
    return rows.reverse();

  }

  const sortResult = (column: any, sortDirection: any) => {     
    setSortedColumn(column.selector);
    if(sortDirection=='desc')
    {
      setSortDirection('dsc');
      setDefaultSortAsc(false);
    }else
    {
      setSortDirection('asc');
      setDefaultSortAsc(true);
    }
   
    setReadyState(true);

  };

  const handleConfirmDelete = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowDeleteConfirm(confirmRespons);
      return;
    }
    if (idToDelete > 0) {
      //console.log(idToDelete);
      setshowDeleteConfirm(false);
      setLoading(true);
      const rsp = await DeletePropertyService(idToDelete)
      toast.success("Property Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleDeleteMessage = (deleteMessage: boolean) => {
    setshowDeleteMessage(deleteMessage);
  }

  const handleConfirmSuspend = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowSuspendConfirm(confirmRespons);
      return;
    }
    if (idToSuspend > 0) {
      setshowSuspendConfirm(false);
      setLoading(true);
      setpropertyStatus({ ...propertyStatus, status: "2" });

      const rsp = await propertyStatusService(propertyStatus, idToSuspend);
      toast.success("Property Suspended Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleSuspendMessage = (suspendMessage: boolean) => {
    setshowSuspendMessage(suspendMessage);
  }

  const handleConfirmActivate = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowActivateConfirm(confirmRespons);
      return;
    }
    if (idToActivate > 0) {
      setshowActivateConfirm(false);
      setLoading(true);
      const rsp = await propertyStatusService(propertyStatus, idToActivate);
      toast.success("Property Activated Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleActivateMessage = (activateMessage: boolean) => {
    setshowActivateMessage(activateMessage);
  }



  const { isModalOpened, toggleModal } = useModal();
  const [openModal, setOpenModal] = useState<ActionModalTypes>();

  const [selectedProperty, setSelectedProperty] = useState<Property>();

  const openActionModal = (property: Property, modalType: ActionModalTypes) => {
    setOpenModal(modalType);
    setSelectedProperty(property);
    toggleModal();
  }

  const columns = [
    {
      name: "ZAG#",
      selector: "propertyId",
      sortable: true,
      center:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">

          <Link to={`/listings/preview/${row.id}`} className="cursor-pointer text-center">
            {getZagNumber(row.id)}
          </Link>
        </p>
      )
    },
    {
      name: "TYPE",
      selector: "propertyType",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0">
          {displayTitle(row.propertyType, row.saleType, false, row.ownershipType)}
        </p>
      )
    },
    {
      name: "ADDRESS",
      selector: "street",
      sortable: true
    },
    {
      name: "PRICE",
      selector: "Price",
      sortable: true,
      right:true,
      cell: (row: any) => (
        <p className="mb-0"> 
          ${displayCurrency(row?.listPrice)}
        </p>
      )
    },

    {
      name: "LISTING DATE",
      selector: "startDate",
      sortable: true,
      center:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.startDate}
        </p>
      )
    },
    {
      name: "FEATURED",
      selector: "featured",
      sortable: true,
      center:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.status == 1 && (
            <>
              {row.isFeatured ? (row.propertyFeatured ? setDatePickerFormat2(row.propertyFeatured.endDate) : "") : (<>
                <a className="cursor-pointer primary" onClick={() => openActionModal(row, ActionModalTypes.FeaturedListing)}>Buy Feature?</a>
              </>)}
            </>
          )}

        </p>
      )
    },
    {
      name: "EXPIRATION",
      selector: "endDate",
      sortable: true,
      center:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.endDate}
        </p>
      )
    }
    ,
    {
      name: "VIEWS",
      selector: "views",
      sortable: true,
      right:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {displayCurrency(row?.views)}
        </p>
      )
    },
    {
      name: "STATUS",
      selector: "status",
      sortable: true,
      center:true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.status == 3 && ("Expired")}
          {row.status == 2 && ("Suspended")}
          {row.status == 1 && ("Active")}
          {row.status == 0 && (<>
            <Link to={`/listings/publish/${row.id}`} className="cursor-pointer"  >
          Publish Now</Link></>)}

        </p>
      )
    },
    {
      name: "ACTIONS",
      selector: "",
      sortable: false,
      center:true,
      cell: (row: any) => (<>
        <UncontrolledButtonDropdown>
          <DropdownToggle color="primary" size="sm" caret>
            Action
                          <ChevronDown size={15} />
          </DropdownToggle>
          <DropdownMenu >
            {row.status == 0 ? (<>
              <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
                <DropdownItem tag="li" >Edit
                          </DropdownItem></Link>
                          <Link to={`/listings/images/${row.id}`} className="cursor-pointer">
                           <DropdownItem tag="li" >Edit Photos
                                 </DropdownItem></Link>
              <Link to={`/listings/publish/${row.id}`} className="cursor-pointer"  >
                <DropdownItem tag="li"  >Publish Now
             </DropdownItem>
              </Link>
              <Link to={`/listings/`} className="cursor-pointer"  >
                <DropdownItem onClick={() => handleRemove(row.id)} tag="li"  >Delete
             </DropdownItem>
              </Link></>) : (<>
                {row.status != 3 && ( <>
                  <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
                    <DropdownItem tag="li" >Edit
                          </DropdownItem></Link>
                           <Link to={`/listings/images/${row.id}`} className="cursor-pointer">
                           <DropdownItem tag="li" >Edit Photos
                                 </DropdownItem></Link></>
                          )}
                          

                {row.status == 3 && (
                  <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row, ActionModalTypes.RenewListing)}  >
                    Renew Listing</DropdownItem>
                )}

                {row.status == 0 || row.status == 2 ? (
                  <Link to={`/listings/`} className="cursor-pointer"  >
                    <DropdownItem tag="li" className="cursor-pointer" onClick={() => handleActivateListing(row.id)}  >
                      Activate Listing</DropdownItem></Link>
                ) : (<></>)}

                {row.status == 1 && (<>
                  <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row, ActionModalTypes.OpenHouse)}  >
                    Open House</DropdownItem>
                  <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row, ActionModalTypes.ExtendListing)}  >
                    Extend Listing</DropdownItem>
                  <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row, ActionModalTypes.FeaturedListing)}  >
                    Featured Listing</DropdownItem>
                  <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row, ActionModalTypes.ChangePrice)}  >
                    Change Price</DropdownItem>

                  <DropdownItem tag="li" className="cursor-pointer" onClick={() => openActionModal(row, ActionModalTypes.VirtualLink)}  >
                    Virtual Tour Link</DropdownItem>
                  <DropdownItem tag="li" className="cursor-pointer d-none " onClick={() => openActionModal(row, ActionModalTypes.EmbedVideo)}  >
                    Embed Video </DropdownItem>
                  {row.status == 1 && (
                    <Link to={`/listings/`} className="cursor-pointer"  >
                      <DropdownItem tag="li" className="cursor-pointer" onClick={() => handleSuspendListing(row.id)}  >
                        Suspend Listing</DropdownItem></Link>

                  )}
                </>)}
                <Link to={`/listings/`} className="cursor-pointer"  >
                  <DropdownItem onClick={() => handleRemove(row.id)} tag="li"  >Delete
             </DropdownItem>

                </Link>
              </>)}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
        <div className="data-list-action d-none">
          <Link to={`/listings/preview/${row.id}`} className="cursor-pointer">
            <Eye className="cursor-pointer mr-1" size={20} />
          </Link>
          <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
            <Edit className="cursor-pointer mr-1" size={20} />
          </Link>
          <Link to={`/listings/`} className="cursor-pointer"  >
            <Trash
              className="cursor-pointer"
              size={20}
              onClick={() => handleRemove(row.id)}
            />
          </Link>
        </div>
      </>
      )
    },

  ];


  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="My Listings" breadCrumbActive="My Listings" />
      <ToastContainer />

      <Card className="mt-2">
        <CardBody>
          {loading ? (
            <div className="d-flex justify-content-between mt-1">
              <Spinner size="lg" className="primary float-right" />
            </div>
          ) : (
              <DataTable
                noHeader={true}
                className="ListingTable"
                columns={columns}
                data={properties}
                defaultSortField={sortedColumn}
                defaultSortAsc={defaultSortAsc}
                pagination={true}
                onChangePage={ChangePagination}
                paginationDefaultPage={paged}
                onChangeRowsPerPage={ChangeRowsPerPage}
                paginationTotalRows={totalrows}
                paginationPerPage={perpagearecord}
                paginationServer={true}
                selectableRows={false}
                responsive={true}
                sortServer={true}
                onSort={sortResult}
                striped={true} 
              />
            )}
        </CardBody>

      </Card>

      <SweetAlert title="Are you sure?"
        warning
        show={showDeleteConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmDelete(true)}
        onCancel={() => handleConfirmDelete(false)}
      >
        You won't be able to revert this!
        </SweetAlert>
      <SweetAlert success title="Deleted!"
        confirmBtnBsStyle="success"
        show={showDeleteMessage}
        onConfirm={() => handleDeleteMessage(false)}
      >
        <p className="sweet-alert-text">Your property deleted.</p>
      </SweetAlert>

      <SweetAlert title="Are you sure?"
        warning
        show={showActivateConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, Activate it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmActivate(true)}
        onCancel={() => handleConfirmActivate(false)}
      >

      </SweetAlert>
      <SweetAlert success title="Activated!"
        confirmBtnBsStyle="success"
        show={showActivateMessage}
        onConfirm={() => handleActivateMessage(false)}
      >
        <p className="sweet-alert-text">Your property activated successfully.</p>
      </SweetAlert>

      <SweetAlert title="Are you sure?"
        warning
        show={showSuspendConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, Suspend it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmSuspend(true)}
        onCancel={() => handleConfirmSuspend(false)}
      >
      </SweetAlert>
      <SweetAlert success title="Suspended!"
        confirmBtnBsStyle="success"
        show={showSuspendMessage}
        onConfirm={() => handleSuspendMessage(false)}
      >
        <p className="sweet-alert-text">Your property suspended successfully.</p>
      </SweetAlert>

      <Modal
        id="actionModal"
        isOpen={isModalOpened}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        {openModal == ActionModalTypes.OpenHouse && (
          <PropertyOHModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}
        {openModal == ActionModalTypes.ExtendListing && (
          <PropertyELModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}
        {openModal == ActionModalTypes.FeaturedListing && (
          <PropertyFLModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}
        {openModal == ActionModalTypes.ChangePrice && (
          <PropertyCPModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}

        {openModal == ActionModalTypes.VirtualLink && (
          <PropertyVLModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}
        {openModal == ActionModalTypes.EmbedVideo && (
          <PropertyEVModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}
        {openModal == ActionModalTypes.RenewListing && (
          <PropertyRLModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
        )}

      </Modal>
    </React.Fragment>
  )
}