import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import CurrencyInput from "react-currency-input-field";
import { displayCurrency } from "../../../../utils/display-currency";

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAnnualTax = ({ property, setProperty}:PricingStepProps) => {   
    
    const handleAnnualTaxChange = (e:any) =>{
      var price = Number(e.target.value?.replace(/,/g, ''));     
      if(isNaN(e.target.value)!==false)
        {
          setProperty({...property,annualTax:undefined});         
        }else{          
          setProperty({...property,annualTax:e.target.value});         
        }
       
      }
    

        return (     
            <>    
                <div className="form-label-group mt-2">
                <div className="d-inline mr-1">Current Annual Property Tax</div>
                <Input            
                   placeholder="Current Annual Property Tax"        
                  value={(property.annualTax == undefined ? "" : displayCurrency(property.annualTax))}
                  className={classnames("form-control",{
                    invalid: (property.annualTax == undefined || property.annualTax.toString()=="")
                  })} 
                  onChange={
                    (e) => {                       
                     var price = Number(e.target.value?.replace(/,/g, ''));                      
                     if(isNaN(price)!==false)
                     {
                       //setProperty({...property,annualTax:undefined});      
                     }else
                     {
                       setProperty({...property,annualTax:price});  
                     }  
                 }
                }
                />
                 
              </div>

                 
         </>
    )
}
    