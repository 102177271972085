import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap"

import Select from "react-select"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import { AvInput, AvGroup, AvFeedback, AvField } from "availity-reactstrap-validation"


import { SelectStateInput } from '../SelectInput/SelectStateInput'
import { ValidationMessage } from "../validation-message"
//import { ConfirmAddressModal2 } from "../ConfirmAddressModal2"
//import { ConfirmAddressModal } from "../confirm-address-modal"

import { listStatesService, listCountyService, listAreaService, getLatlngByAddress } from "../../../machines/listing-machine/services"
import { State } from "../../../contracts/response-models/state"
import { Property } from "../../../contracts/response-models/property"
import { SaleType, PropertyType } from "../../../contracts/enums"
import NumberFormat from "react-number-format";

interface locationProps {
  property: Property,
  setProperty: any,
  validateAddress: any,
  setIsStepReady: any
}

export const LocationStep = ({
  property,
  setProperty,
  validateAddress,
  setIsStepReady
}: locationProps) => {

  //Validation States         
  const defaultOption = { label: "Select", value: "" }
  const loadingOption = { label: "Loading", value: "" }

  const [selectedState, setSelectedState] = React.useState(defaultOption);
  const [selectedCounty, setSelectedCounty] = React.useState(defaultOption);
  const [selectedArea, setSelectedArea] = React.useState(defaultOption);

  //For State Dropdown  
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);

  const handleStateChange = (val: any) => {
    setSelectedState(stateItems.filter(option => option.value == val.value)[0]);
    console.log("State :" + val.value);
    setProperty({ ...property, stateCD: val.value })
  }

  useEffect(() => {
    async function loadStats() {
      setIsStepReady(false);

      setSelectedState(loadingOption);
      const states = await listStatesService();
      const stateOptions = states.map((state) => ({ label: state.stateTitle, value: state.stateCD }))
      setStateItems(stateOptions);
      setSelectedState(stateOptions.filter(option => option.value == property.stateCD)[0]);


    }
    loadStats();
  }, []);

  useEffect(() => {
    const setStateSelected = () => {
      setSelectedState(stateItems.filter(option => option.value == property.stateCD)[0]);
    }
    setStateSelected();
  }, [property.stateCD]);



  //For County Dropdown   
  const [countyItems, setCountyItems] = React.useState([
    defaultOption
  ]);

  const handleCountyChange = (val: any) => {
    setSelectedCounty(countyItems.filter(option => option.value == val.value)[0]);
    setProperty({ ...property, countyId: val.value })
  }

  useEffect(() => {
    async function loadCounties(val: any) {
      console.log("Load Counties:" + val);
      if (val == undefined || val == "") {
        setSelectedCounty(defaultOption);
        setProperty({ ...property, countyId: undefined })

        setIsStepReady(true);
        return;
      }

      setSelectedCounty(loadingOption);
      const counties = await listCountyService(val);
      const countyOptions = counties.map((county) => ({ label: county.countyTitle, value: county.id.toString() }))
      setCountyItems(countyOptions);

      const founditems = countyOptions.filter(option => option.value == property.countyId?.toString())
      const selectedOption = founditems.length > 0 ? founditems[0] : defaultOption;
      setSelectedCounty(selectedOption);
      setProperty({ ...property, countyId: selectedOption.value })

      setIsStepReady(true);
    }
    loadCounties(property.stateCD);
  }, [property.stateCD]);

  //For Town Dropdown  
  const [areaItems, setAreaItems] = React.useState([
    defaultOption
  ]);

  const handleAreaChange = (val: any) => {
    setSelectedArea(areaItems.filter(option => option.value == val.value)[0]);
    setProperty({ ...property, areaId: val.value })
  }

  useEffect(() => {
    async function loadAreas(val: any) {
      console.log("Load Areas:" + val);
      if (val == undefined || val == "") {
        // setSelectedArea(defaultOption);
        //setProperty({...property,areaId:defaultOption.value})
        setIsStepReady(true);
        return;
      }


      setSelectedArea(loadingOption);
      const areas = await listAreaService(val);
      const areaOptions = areas.map((area) => ({ label: area.areaTitle, value: area.id.toString() }))
      setAreaItems(areaOptions);

      const founditems = areaOptions.filter(option => option.value == property.areaId?.toString())
      const selectedOption = founditems.length > 0 ? founditems[0] : defaultOption;
      setSelectedArea(selectedOption);
      setProperty({ ...property, areaId: selectedOption.value })

      setIsStepReady(true);
    }
    loadAreas(selectedCounty.value);
  }, [selectedCounty]);


  //Zip Code  
  const handleZipChange = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setProperty({ ...property, zipCode: e.target.value });
    } else {
      if (property.zipCode == null || property.zipCode == undefined) {
        setProperty({ ...property, zipCode: "" });
      }

    }
  }

  //Street  
  const handleStreetChange = (e: any) => {
    setProperty({ ...property, street: e.target.value });
  }
  const handleUnitChange = (e: any) => {
    var value = e.target.value;
    if (value.length>2) {
      value = property.units;
    } else if (e.target.value == '') {
      value = undefined;
    }
    setProperty({ ...property, units: value });

  }


  //Geo Location
  const [latLng, setLatlng] = React.useState({ Latitude: "", Longitude: "" });

  //Confirm Address
  const [address, setAddress] = useState('')
  const [confirmModal, setConfirmModal] = React.useState(false);
  const handleConfirmAddress = (e: any) => {
    e.preventDefault();
    //Validate All Fields    
    if (validateAddress()) {
      const fullAddress = property.street + "," + selectedArea.label + "," + selectedState.value + " " + property.zipCode + ", USA";

      //const fullAddress = "18 E Allendale Rd,Saddle River,NJ 07458, USA";
      setAddress(fullAddress)
      toogleConfirmModal();
    }
  }
  const handleisAddressPublic = (e: any) => {

    setProperty({ ...property, isAddressPublic: e.target.checked });

  }

  const toogleConfirmModal = () => {
    setConfirmModal(!confirmModal);
    updateLatLng();
  }

  const updateLatLng = () => {
    setProperty((prevState: Property) => {
      return { ...prevState, longitude: latLng.Longitude };
    });
    setProperty((prevState: Property) => {
      return { ...prevState, latitude: latLng.Latitude };
    });
  }

  return (
    <>
      <Row>
        <Col className="mx-auto" md="4" sm="12">
          <h3 className="mb-2 mt-2 text-center">LOCATION</h3>
          <FormGroup>
            <div className="d-inline mr-1">State</div>
            <Select
              id="ddlState"
              className="React"
              classNamePrefix="select"
              options={stateItems}
              value={selectedState}
              onChange={handleStateChange}
            />
            <ValidationMessage isInvalid={property.stateCD == undefined || (property.stateCD != '' && property.stateCD != '0') ? false : true} errorMessage="Please select state" />
          </FormGroup>
          <FormGroup>
            <div className="d-inline mr-1">County</div>
            <Select
              className="React"
              classNamePrefix="select"
              options={countyItems}
              value={selectedCounty}
              onChange={handleCountyChange}
            />
            <ValidationMessage isInvalid={(property.countyId == undefined || property.countyId != 0) ? false : true} errorMessage="Please select county" />
          </FormGroup>
          <FormGroup>
            <div className="d-inline mr-1">Town</div>
            <Select
              className="React"
              classNamePrefix="select"
              options={areaItems}
              value={selectedArea}
              onChange={handleAreaChange}
            />
            <ValidationMessage isInvalid={property.areaId == undefined || (property.areaId != 0) ? false : true} errorMessage="Please select town" />
          </FormGroup>
          <FormGroup>
            <div className="d-inline mr-1">Zip Code</div>
            <Input
              type="text"
              maxLength={5}
              value={property.zipCode}
              onChange={handleZipChange}
            />
            <ValidationMessage isInvalid={(property.zipCode == undefined) || (property.zipCode != '' && property.zipCode?.length == 5) ? false : true} errorMessage="Please enter zip code" />
          </FormGroup>

          <FormGroup>
            <div className="d-inline mr-1">Street</div>
            <Input
              type="text"
              value={property.street}
              onChange={handleStreetChange}
            />
            <ValidationMessage isInvalid={property.street == undefined || property.street != '' ? false : true} errorMessage="Please enter street address" />
          </FormGroup>

          {property.propertyType == PropertyType.MFH &&
            (
              <FormGroup>
                <div className="d-inline mr-1"># of Units &nbsp;</div>

                <NumberFormat value={property.units}
                  onChange={handleUnitChange}
                  allowNegative={false}
                  thousandSeparator={false}
                  className={classnames("mr-1 form-control", {
                    invalid: (property.units == undefined)
                  })}
                />
             
                <ValidationMessage isInvalid={(property.units == undefined)   || (property.units.toString() !='' )  ? false : true} errorMessage="Please enter number of units" />
              </FormGroup>

            )}
            {/* 
          <button className="btn btn-secondary mb-1" onClick={handleConfirmAddress}>Confirm Address</button>
          <ConfirmAddressModal confirmModal={confirmModal} toogleConfirmModal={toogleConfirmModal} address={address} setLatlng={setLatlng} />*/}
          <FormGroup>
            <CustomInput type="switch" onChange={handleisAddressPublic} checked={property.isAddressPublic == undefined ? true : property.isAddressPublic} id="exampleCustomSwitch" name="customSwitch" inline >
              <span className="switch-label">Make address visible to public</span>
            </CustomInput>
          </FormGroup>

        </Col>
      </Row>
    </>
  )
}
export default LocationStep;