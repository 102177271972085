import React from "react";
import { Switch } from "react-router-dom";

import { useMachine } from "@xstate/react";
import {EventData,State} from 'xstate';

import { loginMachine } from "../machines/login/machine";
import {Events as LoginEvents} from "../machines/login/events"
import {Context as LoginContext} from "../machines/login/state-context";

import PublicRoute from  "./public-route";
import PrivateRoute from  "./private-route";

import {LoginPage} from '../pages/LoginPage'
import {RegisterPage} from '../pages/RegisterPage'
import {DashboardPage} from '../pages/DashboardPage'
import {ProfilePreview} from '../pages/ProfilePreview'
import {ProfileEdit} from '../pages/ProfileEdit'
import {AddNewList} from '../pages/AddNewList'
import {MyListings} from '../pages/MyListings'
import {Inquiries} from '../pages/Inquiries'
import {AddNewPost} from '../pages/AddNewPost'
import {PropertyPreview} from '../pages/PropertyPreview'
import {PostPreview} from '../pages/PostPreview'
import {MyPosts} from '../pages/MyPosts'
import {MyInvoices} from '../pages/Invoices'
import {InvoiceDetail} from '../pages/InvoiceDetail'
import {PropertyPublish} from '../pages/PropertyPublish'
import {Support} from '../pages/Support'

import VerticalLayout from '../components/layouts/VerticalLayout'
import CompleteProfilePage from "../pages/CompleteProfilePage";
import VerifyEmail from "../pages/VerifyEmail";
import ChangePassword from "../pages/ChangePassword";
import ResetPassword from "../pages/ResetPassword";

import { AdminListings } from "../pages/admin/Listings";
import { Posts } from "../pages/admin/Posts";
import {Invoices} from '../pages/admin/Invoices'
import { AllInquiries } from "../pages/admin/Inquiries";
import AdminDashboardPage from "../pages/admin/DashboardPage";
import { Agents } from "../pages/admin/Agents";
import AgentDetail from "../pages/admin/AgentDetail";
import AgentEdit from "../pages/admin/AgentEdit";
import DeleteAccount from "../pages/DeleteAccount";
import { AdminPropertyPublish } from "../pages/admin/PropertyPublish";
import PaymentSetting from "../pages/admin/PaymentSetting";
import PropertyImages from "../pages/PropertyImages";
 
interface IProps { 
}

export default function Routes({}:IProps) {  

  const [stateLogin, sendLogin] = useMachine<LoginContext, LoginEvents>(loginMachine, {
      devTools: process.env.NODE_ENV === "development",
  });

  return (
    <Switch>      
        <PublicRoute path="/register" exact component={RegisterPage} />
        <PublicRoute path="/login" exact component={LoginPage} state={stateLogin} send={sendLogin} />
        <PublicRoute path="/verify-email/:id" exact component={VerifyEmail} />
        <PublicRoute path="/reset-password" exact component={ResetPassword} />
        <PrivateRoute  path="/complete-profile" exact component={CompleteProfilePage} />
        <VerticalLayout>
         {localStorage.getItem("__zaglist_admin__") === 'yes' ?(<>
          <PrivateRoute exact path="/" component={AdminDashboardPage}/> 
          <PrivateRoute exact path="/listings" component={AdminListings}/> 
          <PrivateRoute exact path="/listings/add" component={AddNewList}/>  
          <PrivateRoute exact path="/listings/add/:id" component={AddNewList}/>  
          <PrivateRoute exact path="/listings/preview/:id" component={PropertyPreview}/>
          <PrivateRoute exact path="/listings/images/:id" component={PropertyImages}/>   
          <PrivateRoute exact path="/listings/inquiries" component={AllInquiries}/>
          <PrivateRoute exact path="/posts" component={Posts}/>  
           <PrivateRoute exact path="/posts/preview/:id" component={PostPreview}/> 
          <PrivateRoute exact path="/posts/add" component={AddNewPost}/>
            <PrivateRoute exact path="/posts/add/:id" component={AddNewPost}/>  
          <PrivateRoute exact path="/invoices" component={Invoices}/>
          <PrivateRoute exact path="/invoice/:id" component={InvoiceDetail}/> 
          <PrivateRoute exact path="/agents" component={Agents}/>
          <PrivateRoute exact path="/agents/edit/:id" component={AgentEdit}/>
          <PrivateRoute exact path="/agents/:id" component={AgentDetail}/>
          <PrivateRoute exact path="/change-password" component={ChangePassword}/>
          <PrivateRoute exact path="/listings/publish/:id" component={AdminPropertyPublish}/> 
          <PrivateRoute exact path="/payment-setting" component={PaymentSetting}/>
         </>):(
           <>
            <PrivateRoute exact path="/" component={DashboardPage}/> 
            <PrivateRoute exact path="/preview-profile" component={ProfilePreview}/> 
            <PrivateRoute exact path="/edit-profile" component={ProfileEdit}/> 
            <PrivateRoute exact path="/change-password" component={ChangePassword}/>
            <PrivateRoute exact path="/listings/add" component={AddNewList}/>  
            <PrivateRoute exact path="/listings/add/:id" component={AddNewList}/>  
            <PrivateRoute exact path="/listings/preview/:id" component={PropertyPreview}/>  
            <PrivateRoute exact path="/listings/images/:id" component={PropertyImages}/>                                                      
            <PrivateRoute exact path="/listings" component={MyListings}/> 
            <PrivateRoute exact path="/listings/publish/:id" component={PropertyPublish}/> 
            <PrivateRoute exact path="/listings/inquiries" component={Inquiries}/>                                        
            <PrivateRoute exact path="/posts/add" component={AddNewPost}/>
            <PrivateRoute exact path="/posts/add/:id" component={AddNewPost}/>  
            <PrivateRoute exact path="/posts/preview/:id" component={PostPreview}/>                              
            <PrivateRoute exact path="/posts" component={MyPosts}/>       
            <PrivateRoute exact path="/invoices" component={MyInvoices}/>  
            <PrivateRoute exact path="/invoice/:id" component={InvoiceDetail}/> 
            <PrivateRoute exact path="/support" component={Support}/>  
            <PrivateRoute exact path="/delete-account" component={DeleteAccount}/>
              
          </>)}

       </VerticalLayout>

    </Switch>
  );
}
