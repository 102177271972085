import React,{useEffect, useState} from "react";

import Breadcrumbs from "../../components/@vuexy/breadCrumbs/BreadCrumb"
import { Link,Redirect } from "react-router-dom"

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal} from "reactstrap"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"
import { ToastContainer,toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/plugins/extensions/toastr.scss";
 
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"

import { listInquiriesService } from "../../machines/listing-machine/admin/services"
import { PagedResult } from "../../contracts/response-models/paged-result";
import { Property } from "../../contracts/response-models/property";
import { Inquiry } from "../../contracts/response-models/inquiry";
import { setDatePickerFormat2 } from "../../utils/date-helper";
import { PropertyRLModal } from "../../components/MyListings/property-rl-modal";
import { ActionModalTypes } from "./../MyListings";
import { useModal } from "../../components/MyListings/useModal";
import { InquiryModal } from "../../components/layouts/Inquiry/inquiry-modal";
import { getAgentNumber } from "../../utils/agent-number";
 
export const AllInquiries = () => {
  const [inquiriesResult, setInquiriesResult] = React.useState<PagedResult<Inquiry>>();
  const [inquiries, setInquiries]  = React.useState<Inquiry[]>([]);
    const [loading,setLoading] = useState(false);
  
    const [readyState, setReadyState] = useState(true); 
    const [paged,setPaged] = useState(1); 
    const [perpagearecord,setPagePerRecord] = useState(10); 
    const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('Dsc');
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  

  const { isModalOpened, toggleModal } = useModal();
  const [openModal, setOpenModal] = useState<ActionModalTypes>();

  const [selectedInquiry, setSelectedInquiry] = useState<Inquiry>();

  const viewInquiry = (e:any,inquiry: Inquiry) => {
    e.preventDefault();
    setSelectedInquiry(inquiry);
    toggleModal();
  }

 

    useEffect(() => {    
        async function loadInquiry() {

          if (readyState == true) {
          setLoading(true);
         
            const inquiryResult = await listInquiriesService(paged,perpagearecord,sortedColumn,sortDirection);    
            setInquiriesResult(inquiryResult)
            setInquiries(inquiryResult.data);
            setPaged(inquiryResult.pageIndex);
            settotalrows(inquiryResult.totalRecords);
            setLoading(false);
            setReadyState(false); 
            setLoading(false); 
          } 
            }
        
        loadInquiry();

    },[readyState,paged,perpagearecord,sortedColumn,sortDirection]);

 
    const [totalrows,settotalrows]=useState(50); 
    const ChangePagination = (page:any,totalRows:any) => { setPaged(page);  setReadyState(true);}
    const ChangeRowsPerPage = (rowPerPage:number) => { setPagePerRecord(rowPerPage); } 

    const sortResult = (column: any, sortDirection: any) => {     
      setSortedColumn(column.selector); 
      if(sortDirection=='desc')
      {
        setSortDirection('dsc');
        setDefaultSortAsc(false);
      }else
      {
        setSortDirection('asc');
        setDefaultSortAsc(true);
      }
     
      setReadyState(true);
  
    };

  
   
    const columns = [
         
          {
            name: "First Name",
            selector: "firstName",
            sortable: true,
            center:true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0"> 
                               {row.firstName} 
             </p>
            )
          },
          {
            name: "Last Name",
            selector: "lastName",
            sortable: true,
            center:true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0"> 
                               {row.lastName} 
             </p>
            )
          } 
          ,
          {
            name: "Email",
            selector: "email",
            sortable: true,
            cell: (row:any) => (
              <p className=" mb-0"> 
                               {row.email} 
             </p>
            )
          },
          {
            name: "Cell",
            selector: "cell",
            sortable: true,
            center:true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0"> 
                               {row.cell} 
             </p>
            )
          },
          
          {
            name: "Property Address",
            selector: "id", 
            sortable: false,
            cell: (row:any) => (
              <p className="mb-0"> 
             {row.propertyAddress!=null && (
               <>
                  <Link to={`/listings/preview/${row.propertyAddress.id}`} className="cursor-pointer text-center"> 
            {row.propertyAddress.street}, {row.propertyAddress.areaTitle}, {row.propertyAddress.stateCD} &nbsp; 
            {row.propertyAddress.zipCode} 
              </Link>
            </>
            )}  
              
             </p>
            )
          },
          
        {
          name: "Date",
          selector: "dateAdded",
          sortable: true,
          center:true,
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
            {setDatePickerFormat2(row.dateAdded)} 
                       
               
            </p>
          )
        } 
        ,{
          name: "AGENT#",
          selector: "userId",
          sortable: true,
          
          center:true,
          cell: (row: any) => (
            <p className="text-truncate  mb-0">
    
              <Link to={`/agents/${row.userId}`} className="cursor-pointer text-center">
                {getAgentNumber(row.userId)}
              </Link>
            </p>
          )
        }
        ,
        
          
        {
          name: "View",
          selector: "view",
          sortable: false,
          center:true,
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
           <a href="#" onClick={(e)=> { e.preventDefault(); viewInquiry(e,row)}}>view</a>
                        
            </p>
          )
        } 

        
      ];
    return (
        <React.Fragment>
        <Breadcrumbs breadCrumbTitle="Inquiries"   breadCrumbActive="Inquiries" />
        <ToastContainer />
      
        <Card className="mt-2">
                    <CardBody> 
                    {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(

                    <DataTable
                title="Inquiries"
                className="ListingTable"
                columns={columns}
                noHeader={true}
                data={inquiries}
                defaultSortField={sortedColumn}
                defaultSortAsc={defaultSortAsc}
                pagination={true}
                onChangePage={ChangePagination}
                paginationDefaultPage={paged}
                onChangeRowsPerPage={ChangeRowsPerPage}
                paginationTotalRows={totalrows}
                paginationPerPage={perpagearecord}
                paginationServer={true}
                selectableRows={false}
                responsive={true}
                sortServer={true}
                onSort={sortResult}
                striped={true}              
                    />    )} 
                    </CardBody>

        </Card>  
           <Modal
        id="actionModal"
        isOpen={isModalOpened}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
       
        
          <InquiryModal inquiry={selectedInquiry} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
         
      </Modal>
       
        </React.Fragment>
    )
}