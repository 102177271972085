import React, { useState,useEffect, useRef } from "react";

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner, Label} from "reactstrap"
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../../components/@vuexy/breadCrumbs/BreadCrumb"

import {getPropertyInfoService, propertyStatusService} from "../../machines/listing-machine/services"
 
import {Property} from '../../contracts/response-models/property'

 
import { toast, ToastContainer } from "react-toastify";
import { setDatePickerFormat2 } from "../../utils/date-helper";
import { displayTitle } from "../../utils/display-property-title";
import { PropertyStatus } from "../../contracts/response-models/propertystatus";
 
export const AdminPropertyPublish = () => {
  const [loading,setLoading] = useState(false);
  const [propertyStatus, setpropertyStatus] = useState<PropertyStatus>(
    {
      status: "1"
    }
  );
    const [property, setProperty] = useState<Property>();
    let { id } = useParams(); 
    useEffect(() => {    
        async function getProperty() {
          setLoading(true);
            if(id){
                const propertyinfo = await getPropertyInfoService(id);  
                setProperty(propertyinfo);  
                
            }
          setLoading(false); 
        }
        getProperty();
    },[id]);

    const PublishProperty = async (e: any) => {
    
        e.preventDefault();
        setLoading(true);
        if(id){
          
          const rsp = await propertyStatusService(propertyStatus, id);
          toast.success("Property Activated Successfully", {
            position: toast.POSITION.TOP_RIGHT
          })
        
            
        }
      setLoading(false); 
  
    };
    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Publish" breadCrumbParent="Property" breadCrumbActive="Publish" />
           
            <Card className="overflow-hidden app-ecommerce-details">
          <CardBody className="pb-0">
          <Row>
        <Col className="mx-auto mb-2 mt-2" md="4" sm="12">
          <h3 className="mb-2 mt-2  text-center">Publish</h3> 
          <>
   {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
           
      <form onSubmit={PublishProperty} className='form-container'  >
        <ToastContainer />
         
       
        <FormGroup>
          <Label for="address"> <strong>Property Address :</strong> </Label>  {property?.street}, {property?.areaTitle} {property?.stateCD} {property?.zipCode}  

        </FormGroup>
        <FormGroup>
          <Label for="address"> <strong> Listing Type : </strong> </Label> &nbsp;
            
          {property?.propertyType!=undefined && property?.saleType!=undefined && (
             <>
              {displayTitle(property.propertyType,property.saleType,false,'')} 
              </>
            ) }

{property?.propertyType!=undefined && property?.saleType!=undefined && property?.ownershipType!=undefined && (
            <>
          {displayTitle(property.propertyType,property.saleType,false,property.ownershipType)} 
          </>
            ) }
            

        </FormGroup>
       
        <FormGroup>
          <Label for="startDate"> <strong>Listing Start Date : </strong> </Label>   {property?.startDate!=undefined && ((setDatePickerFormat2(property?.startDate)) )} 


        </FormGroup>
        <FormGroup>
          <Label for="endDate"> <strong>Listing End Date : </strong> </Label> {property?.endDate!=undefined && ((setDatePickerFormat2(property?.endDate)) )} 


        </FormGroup>
    
      
           {property?.status!='1'?(
        <button type="submit" className="btn btn-primary" >
          Publish
      </button>
):(<><strong>Status :</strong>  Published  </>)}
      </form>
     
          )}

    </>
  </Col>
      </Row> 
          </CardBody>
      
        </Card>
        </React.Fragment>
    )
}
export default AdminPropertyPublish;