import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import { Link, Redirect } from "react-router-dom"

import { Row, Col, Button, Card, CardBody, ListGroup, ListGroupItem, FormGroup, Progress, Input, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";

import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"

import { listInvoiceService } from "../machines/listing-machine/services"


import { Invoice } from "../contracts/response-models/invoice";
import "../assets/scss/pages/invoice.scss"
import { setDatePickerFormat, setDatePickerFormat2 } from "../utils/date-helper";
import { getInvoiceNumber } from "../utils/invoice-number";
import { PagedResult } from "../contracts/response-models/paged-result";

export const MyInvoices = () => {
  const [invoiceResult, setInvoiceResult] = React.useState<PagedResult<Invoice>>();
  const [invoice, setInvoice] = React.useState<Invoice[]>([]);
  const [loading, setLoading] = useState(false);

  const [readyState, setReadyState] = useState(true);
  const [paged, setPaged] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('Dsc');
  const [dataMessage, setDataMessage] = useState('Waiting...');
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);

  const paymentOptions = [
    { index: 1, value: "Listing" },
    { index: 2, value: "Featured Listing" },
    { index: 3, value: "Featured Agent" },
    { index: 4, value: "Renewal Listing" },
    { index: 5, value: "Extend Listing" },
  ]
  useEffect(() => {
    async function loadInvoices() {
      if (readyState == true) {
        setLoading(true);

      const invoiceResult = await listInvoiceService(paged, perpagearecord, sortedColumn, sortDirection);
      setInvoiceResult(invoiceResult)
      setInvoice(invoiceResult.data);
      setPaged(invoiceResult.pageIndex);
      settotalrows(invoiceResult.totalRecords);
      setLoading(false);
      setReadyState(false);
      }
    }

    loadInvoices();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection]);

 
  const [totalrows, settotalrows] = useState(invoice.length);
 

  const ChangePagination = (page: any, totalRows: any) => { setPaged(page);  setReadyState(true); }
  const ChangeRowsPerPage = (rowPerPage: number) => { setPagePerRecord(rowPerPage);  setReadyState(true); }
  

  const sortResult = (column: any, sortDirection: any) => {     
    setSortedColumn(column.selector);
    if(sortDirection=='desc')
    {
      setSortDirection('dsc');
      setDefaultSortAsc(false);
    }else
    {
      setSortDirection('asc');
      setDefaultSortAsc(true);
    }
   
    setReadyState(true);

  };


  const columns = [
    {
      name: "Invoice#",
      selector: "id",
      center: true,
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0 text-center">

          <Link to={`/invoice/${row.id}`} className="cursor-pointer text-center">
            {getInvoiceNumber(row.id)}
          </Link>
        </p>
      )
    } ,
    {
      name: "Property Address",
      selector: "PropertyAddress",
      sortable: false,
      cell: (row: any) => (
        <p className="mb-0">
          {row.propertyAddress != null && (
            <>
              {row.propertyAddress.street}, {row.propertyAddress.areaTitle}, {row.propertyAddress.stateCD} &nbsp;
              {row.propertyAddress.zipCode} </>
          )}

        </p>
      )
    },

    {
      name: "Payment Type",
      selector: "paymentType",
      sortable: true,
      cell: (row: any,) => (
        <p className="text-truncate  mb-0">
          {
            paymentOptions.map(function (option, i) {
              return (
                <>
                  {option.index == parseInt(row.paymentType) && (option.value)}
                </>
              )
            })

          }

        </p>
      )
    },
    {
      name: "Days",
      selector: "days",
      sortable: false,
      center: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.days}

        </p>
      )
    },
    {
      name: "Amount",
      selector: "amount",
      right: true,
      sortable: true,
      cell: (row: any) => (

        <p className="text-truncate  mb-0">
          ${row.amount.toFixed(2)}

        </p>
      )
    },
    {
      name: "Payment DATE",
      selector: "DateAdded",
      sortable: true,
      center: true,
      cell: (row: any) => (

        <p className="text-truncate  mb-0">
          {setDatePickerFormat2(row.invoiceDate)}

        </p>
      )
    }
  ];
  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Payments" breadCrumbActive="Invoices" />
      <ToastContainer />

      <Card className="mt-2">
        <CardBody>
          {loading ? (
            <div className="d-flex justify-content-between mt-1">
              <Spinner size="lg" className="primary float-right" />
            </div>
          ) : (
              <DataTable
                title="Invoices"
                columns={columns}
                noHeader={true}
                className="ListingTable"
                data={invoice}
                defaultSortField={sortedColumn}
                defaultSortAsc={defaultSortAsc}
                pagination={true}
                onChangePage={ChangePagination}
                paginationDefaultPage={paged}
                onChangeRowsPerPage={ChangeRowsPerPage}
                paginationTotalRows={totalrows}
                paginationPerPage={perpagearecord}
                paginationServer={true}
                selectableRows={false}
                responsive={true}
                sortServer={true}
                onSort={sortResult}
                striped={true}   
              />)}
        </CardBody>

      </Card>

    </React.Fragment>
  )
}