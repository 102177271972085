import { client } from "../client";
import { User } from "../../contracts/response-models/user";
import { PatchUserRequest } from "../../contracts/request-models/patch-user";
import { ActionResponse } from "../../contracts/response-models/action-response";

export function patchUser(userId: number, request: PatchUserRequest) {
  return client<ActionResponse>(`/users/${userId}`, request, {
    method: "PATCH",
});
  
}