import React, { useState, useRef } from "react";
import { Link, Redirect } from "react-router-dom"
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,   
    Button, 
    Label,
    Input,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Alert,
    Spinner
} from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import loginImg from "../assets/img/pages/login.png"
import { history } from "../history"
import "../assets/scss/pages/authentication.scss"

import { EventData, State } from "xstate";
import { EventTypes, Events } from "../machines/login/events";
import { Context } from "../machines/login/state-context";
import { resetPasswordService, sendResetPasswordCodeService } from "../machines/listing-machine/services";

import { ValidationMessage } from "../components/@vuexy/validation-message";
import { ActionResponse } from "../contracts/response-models/action-response";
import { frontUrl } from "../utils/front-url";
 

export interface ResetPasswordInfo {
    code?:string;
    password?: string;
    confirmPassword?: string;
    isvalidate?:boolean;
  }
export const ResetPassword = () => {  
    const [loading, setLoading] = useState(false);

    const [usernameField, setUserNameField] = useState("");
    const [codeResponse, setCodeResponse] = useState<ActionResponse>();
    const [passwordSaveResposne, setPasswordSaveResposne] = useState<ActionResponse>();
    const [resetPasswordInfo, setResetPasswordInfo] = useState<ResetPasswordInfo>();
    const [validationMessage, setValidationMessage] = useState<string>();

    const sendCode = async () => {
        setLoading(true);
       var response = await sendResetPasswordCodeService(usernameField);
       setCodeResponse(response);
       setLoading(false);
    };

    const passwordValidate = (passwordValue:string) => {

        var isValidated = true;
    
        if (passwordValue != undefined && passwordValue != "") {
          if (passwordValue.length < 8) {
            setValidationMessage("Password must have minimum 8 characters");
            isValidated = false;
          }else if (!passwordValue.match(/[A-Z]+/)) { 
              setValidationMessage("Password must have a capital (uppercase) letter");
               isValidated = false;
            }else if (!passwordValue.match(/[a-z]+/)) { 
              setValidationMessage("Password must have a lowercase letter");
               isValidated = false;
            }else if (!passwordValue.match(/[0-9]+/)) { 
              setValidationMessage("Password must have a number");
               isValidated = false;
            }else if (!passwordValue.match(/[!@#$%\^&*(){}[\]<>?/|\-_]+/)) { 
              setValidationMessage("Password must have a special character");
               isValidated = false;
            }else{
              setValidationMessage("");
              isValidated = true;
            }
    
        }
        return isValidated;
      }
    
      const saveResetPassword = async (e: any) => {
        e.preventDefault() 
        var isValidated = true;
        
        if (resetPasswordInfo?.confirmPassword == undefined || resetPasswordInfo.confirmPassword == "") {
          setResetPasswordInfo({ ...resetPasswordInfo, confirmPassword: '' });
          isValidated = false
        }
        if (resetPasswordInfo?.password == undefined || resetPasswordInfo.password == "") {
            setResetPasswordInfo({ ...resetPasswordInfo, password: '' });
          setValidationMessage("Please type password");
          isValidated = false
        }
        if (resetPasswordInfo?.password!=undefined && !passwordValidate(resetPasswordInfo?.password)) {
          isValidated = false;
        }
        if (resetPasswordInfo?.password!=undefined &&  resetPasswordInfo.confirmPassword!=undefined && resetPasswordInfo.password!=resetPasswordInfo.confirmPassword) {
          setValidationMessage("Please type same password again");
          isValidated = false;
        }
        if (isValidated == true && resetPasswordInfo?.code!=undefined && resetPasswordInfo?.password!=undefined) {
          setLoading(true);
    
          const response = await resetPasswordService(resetPasswordInfo?.code,resetPasswordInfo?.password);
          setPasswordSaveResposne(response);
          setResetPasswordInfo({...resetPasswordInfo,password:'',confirmPassword:''});
          
          setLoading(false);
        }
    
      }

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

                    <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication login-card rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
                <a href={frontUrl} ><img src={loginImg} alt="Zaglist.com" className="loginImage" /></a>
              </Col>
              <Col lg="6" md="12" className="p-0">
                <Card className="rounded-0 mb-0 px-2 login-tabs-container1">
                  <CardHeader className="pb-1">
                    <CardTitle>
                      <h4 className="mb-0">Reset Password</h4>
                    </CardTitle>
                  </CardHeader>
                  <p className="px-2 auth-title">
                   Enter your email to reset password
                  </p>
                  <CardBody className="pt-1">
                    <Form action="/" >
                    {loading ?(            
                      <div className="d-flex justify-content-between mt-1">                               
                      <Spinner size="lg"  className="primary float-right"/>
                      </div>
                    ):(
                        <>
                    {codeResponse!=undefined && codeResponse.success==true ?(
                        <>
                         {passwordSaveResposne!=undefined && passwordSaveResposne.success==true ?(<>
                            <p className="auth-title success mb-2">
                 Password changed successfully, please <Link to="/login">Login</Link> your account.
                  </p>
                         </>):(
                             <>
                         {passwordSaveResposne!=undefined && passwordSaveResposne.success==false ?(<>
                            <p className="auth-title error mb-2">
                 {passwordSaveResposne.error}
                  </p> 
                         </>):(   
                        <p className="auth-title success mb-2">
                  Code to reset password has been sent to your email.
                  </p>)}
                            <FormGroup className="form-label-group">
                        <Input
                            type="text" 
                            autoComplete="off"                           
                            placeholder="Code"
                            value={resetPasswordInfo?.code}    
                            onChange={(e)=> { setResetPasswordInfo({ ...resetPasswordInfo, code: e.target.value })}}                        
                        />
                        <Label>Code</Label>
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="password"
                            placeholder="Password"
                            value={resetPasswordInfo?.password}    
                            onChange={(e)=> { setResetPasswordInfo({ ...resetPasswordInfo, password: e.target.value })}}                              
                        />
                        <Label>Password</Label>
                        {(validationMessage != undefined && validationMessage != '') && (
              <>
                <div className="invalid-feedback d-block">{validationMessage}</div>
              </>
            )}
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={resetPasswordInfo?.confirmPassword} 
                            onChange={(e)=> { setResetPasswordInfo({ ...resetPasswordInfo, confirmPassword: e.target.value })}}                           
                        />
                        <Label>Confirm Password</Label>
                        <ValidationMessage isInvalid={(resetPasswordInfo?.confirmPassword == undefined) || (resetPasswordInfo.confirmPassword != '') ? false : true} errorMessage="Please enter confirm password" />
                     
                        </FormGroup>
                   
                        <div className="d-flex justify-content-between">                               
                    <Button 
                     
                    color="primary" 
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault(); 
                      saveResetPassword(e);
                    }}
                    >                  
                        Submit
                    </Button>
                    </div></>
                    )}
                        </>
                    ):(
                    <>
                    {codeResponse!=undefined && codeResponse.success==false &&(
                          <p className="auth-title error mb-2">
                          {codeResponse.error}
                          </p>
                        
                    )}
                    <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                        type="email"
                        placeholder="Email"   
                        value={usernameField}
                        onChange={({ target }) => setUserNameField(target.value)}
                          
                        onKeyPress={(event) => {
                          if (
                            event.key === "Enter" &&
                            usernameField 
                          ) {
                            sendCode();
                          }
                        }}                                     
                    />
                    <div className="form-control-position">
                        <Mail size={15} />
                    </div>
                    <Label>Email</Label>
                    </FormGroup>
 
                    <div className="d-flex justify-content-between">                               
                    <Button 
                    disabled={!usernameField}
                    color="primary" 
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault(); 
                      if (usernameField) {
                        sendCode();
                      }
                    }}
                    >                     
                        Submit
                    </Button>
                    </div>

                    </>)}
                    
                   </> 
                    )}

          <div className="mt-2 float-right"  >
                        <Link to="/login">Login Now</Link>
                        
                    </div>
                 
                </Form>
                <div className="mt-2 mob-display"  >
                       Continue on <a href={frontUrl}>Zaglist.com</a>
                    </div>  
                </CardBody>          
                 
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

                    </main>
                    </div>
                </div>
                </div>
            </div>
        </div>    
    );
}

export default ResetPassword;