import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../contracts/enums'
import { displayCurrency } from "../../utils/display-currency";

interface PropertyDetailsStepProps {
    property?:Property,
    setProperty:any;    
}
export const PropertyPriceInfo = ({ property, setProperty}:PropertyDetailsStepProps) => {   

        return (     
            <>   
                          {property?.saleType==SaleType.ForSale && 
                        (
                       
 <Row className=" mt-0 mobile_pad_5">
              <Col lg="6" md="6" sm="6" className="w-50 mob_even text-right even pitem-rspace">
              Price  :
                  </Col>
                  <Col lg="6" md="6" sm="6"  className="w-50 mob_even font-weight-bold even pitem-lrspace" >
                  ${displayCurrency(property?.listPrice)}
                  </Col>                 
                            {property?.ownershipType!='p' && (
                                <>
              <Col lg="6" md="6" sm="6" className="w-50 mob_odd text-right odd pitem-rspace">
              Annual Taxes  : 	
                  </Col>
                  <Col lg="6" md="6" sm="6"  className="w-50 mob_odd font-weight-bold odd pitem-lrspace " >
                  ${displayCurrency(property?.annualTax)} 
                  </Col></>
)}
                  { property.propertyType==PropertyType.APT  &&
              ( 
                  <>
                  {property?.ownershipType=='c' && (
                      <>
                <Col md="6" sm="6" className="w-50 mob_even text-right even pitem-rspace">
                Common Charges : 	
                    </Col>
                    <Col md="6" sm="6"  className="w-50 mob_even font-weight-bold even pitem-lrspace" >
                    ${displayCurrency(property?.commonCharges)}
                    </Col>
                    </>
                  )}
                   {property?.ownershipType=='p' && (
                      <>
                <Col md="6" sm="6" className="w-50 mob_odd text-right odd pitem-rspace">
                Maintenance : 	
                    </Col>
                    <Col md="6" sm="6"  className="w-50 mob_odd font-weight-bold odd pitem-lrspace" >
                    ${displayCurrency(property?.maintenance)}
                    </Col>
                    </>
                  )}
                    </>
              )}
                  </Row>
                  
                   )
               }

{property?.saleType==SaleType.ForRent && 
                        (
                       
                <Row className="mt-0 mobile_pad_5">
                            <Col lg="6" md="6" sm="6" className="w-50 mob_even even text-right pitem-rspace">
                                Rent  : 
                            </Col>
                                <Col lg="6" md="6" sm="6"  className="w-50 mob_even even font-weight-bold pitem-lrspace" >
                                ${displayCurrency(property?.listPrice)}
                                </Col>                 
                            
                            <Col lg="6" md="6" sm="6" className="w-50 odd  text-right pitem-rspace ">
                            Security Deposit  : 	
                                </Col>
                                <Col lg="6" md="6" sm="6"  className="w-50 odd font-weight-bold pitem-lrspace" >
                                {(property.isSecurityDeposit!=undefined && property.isSecurityDeposit==true) ? "Yes":"No" }
                                </Col>
                                <Col lg="6" md="6" sm="6" className="w-50 mob_even even text-right pitem-rspace">
                                Broker Fee  : 	
                                </Col>
                                <Col lg="6" md="6" sm="6"  className=" w-50 mob_even even font-weight-bold pitem-lrspace" >
                                {(property.isBrokerFee!=undefined && property.isBrokerFee==true) ? "Yes":"No" }
                                </Col> 
                                <Col lg="6" md="6" sm="6" className="w-50 odd text-right pitem-rspace">
                                Lease Term :	
                                </Col>
                                <Col lg="6" md="6" sm="6"  className="w-50 odd font-weight-bold pitem-lrspace" >
                                 {property?.leaseTermId}
                                </Col> 
                                <Col lg="6" md="6" sm="6" className="w-50 mob_even even text-right pitem-rspace">
                                Availablity  :	
                                </Col>
                                <Col lg="6" md="6" sm="6"  className="w-50  mob_even even font-weight-bold pitem-lrspace" >
                                   {property?.availableDate}
                                </Col> 
                                </Row>
                  
                   )
               }

         </>
    )
}
    