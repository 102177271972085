import React from "react";
import Select from "react-select"
import { PropertyType } from "../../../contracts/enums";



export const SelectStyleInput = (props:any) => {  


    var residentialsOptions = [
        { value: undefined, label: "Select One"},
        { value: 1, label: "Colonial"},
        { value: 2, label: "Cape Cod"},
        { value: 3, label: "Ranch"},
        { value: 4, label: "Split"},
        { value: 5, label: "Contemporary"},
        { value: 6, label: "Log House"},
        { value: 7, label: "Mobile Home"},   
        { value: 8, label: "Split Level"},
        { value: 9, label: "Other"}
    ]
    var commercialOptions = [
        { value: undefined, label: "Select One"},
        { value: 10, label: "Office"},
        { value: 11, label: "Retail"},  
        { value: 12, label: "Industrial"},
    ]
    if(props.property.propertyType==PropertyType.COM)
    {
        var dataOptions = commercialOptions;
    }else
    {
        var dataOptions = residentialsOptions;
    }
   
    
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    
    return ( 
            
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectStyleInput;